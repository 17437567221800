import { AbiItem } from 'web3-utils'
import { useEffect, useMemo, useState } from "react"
import { Contract as Web3EthContract } from 'web3-eth-contract'
import Web3 from 'web3'
import { Zero } from "@ethersproject/constants"


import { useActiveWeb3React } from "."
import { NETWORK_RPC_URL_FULL } from "../connectors"
import { isMetaPool, POOLS_MAP } from "../constants/pools/PoolsMap"
import { PoolName, WxUSD_METASWAP_POOL_NAME } from "../constants/pools/types/PoolName"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import META_SWAP_DEPOSIT_ABI from "../constants/abis/metaSwapDeposit.json"
import SWAP_FLASH_LOAN_ABI from "../constants/abis/swapFlashLoan.json"
import SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI from "../constants/abis/swapFlashLoanNoWithdrawFee.json"
import { ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES } from '../constants/pools/WxUSDMetaswapPool'
import { isLegacySwapABIPool, TRANSACTION_TYPES } from '../constants'
import { BigNumber } from '@ethersproject/bignumber'
import { useSelector } from 'react-redux'
import { useMulticall, useRewardContract } from './useEthMultiCallContracts'
import { AppState } from '../state'

export function useWeb3EthMetaSwapContractsList(poolNames: PoolName[]): (Web3EthContract | null)[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const contractsList = poolNames.map((poolName) => {
      const POOL = POOLS_MAP[poolName]
      if (!chainId || !POOL) {
        return null
      }
      const provider = NETWORK_RPC_URL_FULL[chainId]
      if (!provider) {
        return null
      }
      const web3 = new Web3(provider)
      let metaSwapAddress: string | null = null
      if (POOL.metaSwapAddresses && POOL.metaSwapAddresses[chainId]) {
        metaSwapAddress = POOL.metaSwapAddresses[chainId]
      }
      const isMetaSwap = metaSwapAddress ? true : false
      const metaSwapContract = isMetaSwap && metaSwapAddress ? new web3.eth.Contract(SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI as AbiItem[], metaSwapAddress) : null
      return metaSwapContract
    })
    return contractsList
  }, [chainId, poolNames])
}

export function useWeb3EthSwapContractsList(
  poolNames?: PoolName[],
  usingAltWxUSDMetaswapDepositAddress = false
): (Web3EthContract
  | null)[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!poolNames || poolNames.length === 0) {
      return []
    }
    const contracts = poolNames.map((poolName) => {
      const pool = POOLS_MAP[poolName]

      if (!chainId || !pool) {
        return null
      }
      const provider = NETWORK_RPC_URL_FULL[chainId]
      if (!provider) {

        return null
      }
      const web3 = new Web3(provider)

      try {
        let poolAddress = pool.addresses[chainId]
        if (poolName === WxUSD_METASWAP_POOL_NAME && usingAltWxUSDMetaswapDepositAddress) { //Alt addresses for WxUSD pool
          poolAddress = ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES()[chainId]
        }

        if (!poolAddress) {
          return null
        }

        let contract: Web3EthContract | null = null
        if (isLegacySwapABIPool()) {
          contract = new web3.eth.Contract(SWAP_FLASH_LOAN_ABI as AbiItem[], poolAddress)
        } else if (isMetaPool(poolName)) {
          contract = new web3.eth.Contract(META_SWAP_DEPOSIT_ABI as AbiItem[], poolAddress)
        } else if (pool) {
          contract = new web3.eth.Contract(SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI as AbiItem[], poolAddress)
        }
        return contract

      } catch (error) {
        console.error("Failed to get contract", error)
        return null
      }
    })
    return contracts
  }, [chainId, poolNames, usingAltWxUSDMetaswapDepositAddress])
}

export function useWeb3EthLPTokenContractsList(
  poolNames: PoolName[]
): (Web3EthContract | null)[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!chainId || poolNames.length === 0) {
      return []
    }
    const contractsList = poolNames.map((poolName) => {
      const provider = NETWORK_RPC_URL_FULL[chainId]
      const pool = POOLS_MAP[poolName]
      if (!pool || !pool.lpToken) {
        return null
      }
      const lpTokenAddress = pool.lpToken.addresses[chainId]
      if (!provider || !lpTokenAddress) {
        return null
      }
      const web3 = new Web3(provider)
      const contract = new web3.eth.Contract(LPTOKEN_UNGUARDED_ABI as AbiItem[], lpTokenAddress)
      return contract
    })
    return contractsList
  }, [chainId, poolNames])
}

export function useAmountsStakedForPoolsList(poolNames: PoolName[]): Record<string, BigNumber> {
  const multiCall = useMulticall()
  const { account, chainId } = useActiveWeb3React()
  const lastStakeOrClaim = useSelector(
    (state: AppState) => state.application.lastTransactionTimes[TRANSACTION_TYPES.STAKE_OR_CLAIM]
  )
  const rewardsContract = useRewardContract()
  const [amountsStaked, setAmountStaked] = useState<Record<string, BigNumber>>({})
  useEffect(() => {
    async function fetchAmount() {
      if (!rewardsContract || !multiCall || !chainId) {
        const amounts: Record<string, BigNumber> = {}
        poolNames.map((poolName) => {
          amounts[poolName] = Zero
        } )
        setAmountStaked(amounts)
        return
      }

      const userInfoReqs = poolNames.map((poolName) => {
        const pool = POOLS_MAP[poolName]
        const poolPid = pool?.rewardPids[chainId]
        if (poolPid === null || poolPid === undefined || !account) {
          return null
        }
        return { [poolName]: rewardsContract.methods.userInfo(poolPid, account) }
      })

      const userInfoWithoutNulls: Record<string, any>[] = []
      userInfoReqs.map((req) => {
        if (!req) {
          return
        }
        userInfoWithoutNulls.push(req)
      })
      const userInfos = (await multiCall.all([userInfoWithoutNulls]))[0]

      let indexWithoutNulls = 0
      const amounts: Record<string, BigNumber> = {}
      poolNames.map((poolName, index) => {
        if (userInfoReqs[index] === null) {
          amounts[poolName] = Zero
        } else {
          amounts[poolName] = BigNumber.from(userInfos[indexWithoutNulls][poolName][0])
          indexWithoutNulls++
        }
      })
      setAmountStaked(amounts)
    }
    void fetchAmount()
  }, [rewardsContract, lastStakeOrClaim, multiCall, account, chainId, poolNames])
  return amountsStaked
}