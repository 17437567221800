import { ChainId } from "./types/ChainId"

export const buildPids = (
    pids: Partial<Record<ChainId, number>>,
  ): Record<ChainId, number | null> => {
    // @dev be careful to include pid 0 in this boolean logic
    return Object.keys(ChainId).reduce((acc, id) => {
      const numId = Number(id) as ChainId
      const pid = pids[numId]
      return { ...acc, [numId]: pid == null ? null : pid }
    }, {}) as Record<ChainId, number | null>
  }