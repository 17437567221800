import { AddressZero, Zero } from "@ethersproject/constants"
import { useEffect, useState } from "react"
import { Contract as Web3EthContract } from 'web3-eth-contract'

import { TRANSACTION_TYPES } from "../constants"
import { PoolName } from "../constants/pools/types/PoolName"
import { POOLS_MAP } from "../constants/pools/PoolsMap"
import {
  formatBNToPercentString,
  getTokenSymbolForPoolType,
} from "../utils"
import { useMulticall } from "./useEthMultiCallContracts"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { parseUnits } from "@ethersproject/units"
import { useActiveWeb3React } from "."
import { shallowEqual, useSelector } from "react-redux"
import { loadingIndicator } from "../utils/loadingIndicator"
import { useAmountsStakedForPoolsList, useWeb3EthLPTokenContractsList, useWeb3EthMetaSwapContractsList, useWeb3EthSwapContractsList } from "./useEthMulticallContractsList"

interface TokenShareType {
  percent: string
  symbol: string
  value: BigNumber
}

export type Partners = "cross"
export interface PoolDataType {
  adminFee: BigNumber
  aParameter: BigNumber
  apy: BigNumber | null
  name: PoolName | ""
  reserve: BigNumber | null
  swapFee: BigNumber
  tokens: TokenShareType[]
  totalLocked: BigNumber
  utilization: BigNumber | null
  virtualPrice: BigNumber
  volume: BigNumber | null
  isPaused: boolean
  lpTokenPriceUSD: BigNumber
  lpToken: string,
  partnership?: {
    logo: string,
    url: string,
    i18n: string
  },
}

export interface UserShareType {
  lpTokenBalance: BigNumber
  name: PoolName // TODO: does this need to be on user share?
  share: BigNumber
  tokens: TokenShareType[]
  usdBalance: BigNumber
  underlyingTokensAmount: BigNumber
}

interface ReqsStructure {
  swapStoragePlus: Record<string, number>
  effectivePoolTokens: Record<string, number>
  lpTokenBalances: Record<string, number>
}

export type PoolDataHookReturnType = Record<string, [PoolDataType, UserShareType | null]>

export const emptyPoolData = {
  adminFee: Zero,
  aParameter: Zero,
  apy: null,
  name: "",
  reserve: null,
  swapFee: Zero,
  tokens: [],
  totalLocked: Zero,
  utilization: null,
  virtualPrice: Zero,
  volume: null,
  lpTokenPriceUSD: Zero,
  lpToken: "",
  isPaused: false,
} as PoolDataType

export function usePoolsData(
  poolNames: PoolName[]
): PoolDataHookReturnType /*PoolDataHookReturnType*/ {
  const { account, library, chainId } = useActiveWeb3React()
  const swapContractsList = useWeb3EthSwapContractsList(poolNames)
  const metaSwapContractsList = useWeb3EthMetaSwapContractsList(poolNames)
  const multiCall = useMulticall()
  const tokenPricesUSD = useSelector((state: AppState) => state.application.tokenPricesUSD, shallowEqual)
  const lastTransactionTimes = useSelector((state: AppState) => state.application.lastTransactionTimes, shallowEqual)
  const swapStats = useSelector((state: AppState) => state.application.swapStats, shallowEqual)

  const lpTokenContractsList = useWeb3EthLPTokenContractsList(poolNames)
  const amountsStakedInRewards = useAmountsStakedForPoolsList(poolNames)
  const lastDepositTime = lastTransactionTimes[TRANSACTION_TYPES.DEPOSIT]
  const lastWithdrawTime = lastTransactionTimes[TRANSACTION_TYPES.WITHDRAW]
  const lastSwapTime = lastTransactionTimes[TRANSACTION_TYPES.SWAP]
  const lastMigrateTime = lastTransactionTimes[TRANSACTION_TYPES.MIGRATE]
  const lastStakeOrClaimTime =
    lastTransactionTimes[TRANSACTION_TYPES.STAKE_OR_CLAIM]

  let initialPoolData: PoolDataHookReturnType =  {}
  if ( poolNames.length === 1) {
    initialPoolData = {[poolNames[0]]: [{ ...emptyPoolData, name: poolNames[0] }, null]}
  }
  const [poolData, setPoolData] = useState<PoolDataHookReturnType>(initialPoolData)

  useEffect(() => {
    async function getSwapData(): Promise<void> {
      if (!poolNames || poolNames.length === 0) {
        return
      }
      if (
        !tokenPricesUSD || Object.keys(tokenPricesUSD).length === 0 ||
        !multiCall ||
        !library ||
        !chainId
      ) {

        const emptyPoolDataList: PoolDataHookReturnType = {}
        poolNames.map((poolName) => {
          emptyPoolDataList[poolName] = [{ ...emptyPoolData, name: poolName }, null]
        })
        setPoolData(emptyPoolDataList)
        return
      }

      // ADD LOADING INDICATOR
      loadingIndicator(true)



      const multicallReqs: Record<string, any>[] = []
      const reqsStructure: ReqsStructure = {
        swapStoragePlus: {},
        effectivePoolTokens: {},
        lpTokenBalances: {}
      }
      let reqsStructureIndex = 0

      poolNames.map((poolName, index) => {
        const POOL = POOLS_MAP[poolName]
        if (!POOL || !POOL.addresses[chainId]) {
          return
        }
        const effectivePoolTokens = POOL.underlyingPoolTokens || POOL.poolTokens
        const swapContract = swapContractsList[index]
        const metaSwapContract = metaSwapContractsList[index]
        const effectiveSwapContract: Web3EthContract | null = metaSwapContract || swapContract
        const lpTokenContract = lpTokenContractsList[index]
        if (effectiveSwapContract) {
          multicallReqs.push({
            forAParameter: effectiveSwapContract?.methods.getA(),
            isPaused: effectiveSwapContract?.methods.paused(),
            swapStorage: effectiveSwapContract?.methods.swapStorage(),
            forVirtualPrice: effectiveSwapContract?.methods.getVirtualPrice()
          })
          reqsStructure.swapStoragePlus[poolName] = reqsStructureIndex++

          const effectivePoolTokensReqs: Record<string, any> = {}
          effectivePoolTokens.map((_token, index) => {
            effectivePoolTokensReqs[index.toString()] = effectiveSwapContract?.methods.getTokenBalance(index)
          })
          multicallReqs.push(effectivePoolTokensReqs)
          reqsStructure.effectivePoolTokens[poolName] = reqsStructureIndex++
        }
        if (lpTokenContract) {

          multicallReqs.push({
            userLpTokenBalance: lpTokenContract?.methods.balanceOf(account || AddressZero),
            totalLpTokenBalance: lpTokenContract?.methods.totalSupply()
          })
          reqsStructure.lpTokenBalances[poolName] = reqsStructureIndex++
        }
      })

      const multiCallResponse = await multiCall.all([multicallReqs])
      if (!multiCallResponse) {
        return
      }
      const multiCallResult = multiCallResponse[0]

      const newPoolsData: PoolDataHookReturnType = {}

      poolNames.map((poolName) => {
        const { forAParameter, isPaused, swapStorage, forVirtualPrice } = (reqsStructure.swapStoragePlus[poolName] !== undefined) ? multiCallResult[reqsStructure.swapStoragePlus[poolName]] : [0, false, 0, 0]
        const aParameter = forAParameter ? BigNumber.from(forAParameter) : Zero
        const swapFee = swapStorage ? BigNumber.from(swapStorage[4]) : Zero
        const adminFee = swapStorage ? BigNumber.from(swapStorage[5]) : Zero

        const lpTokenBalances = reqsStructure.lpTokenBalances[poolName] ? multiCallResult[reqsStructure.lpTokenBalances[poolName]] : null
        const userLpTokenBalance = lpTokenBalances ? BigNumber.from(lpTokenBalances.userLpTokenBalance) : Zero
        const totalLpTokenBalance = lpTokenBalances ? BigNumber.from(lpTokenBalances.totalLpTokenBalance) : Zero

        const virtualPrice = totalLpTokenBalance.isZero() || !forVirtualPrice ? BigNumber.from(10).pow(18) : BigNumber.from(forVirtualPrice)

        const forTokenBalances = reqsStructure.effectivePoolTokens[poolName] ? multiCallResult[reqsStructure.effectivePoolTokens[poolName]] : null

        const POOL = POOLS_MAP[poolName]
        if (!POOL) {
          newPoolsData[poolName] = [{...emptyPoolData, name: poolName}, null]
          return
        }
        const effectivePoolTokens = POOL.underlyingPoolTokens || POOL.poolTokens

        const tokenBalances = effectivePoolTokens.map((token, index) => {
          return BigNumber.from(10)
            .pow(18 - token.decimals) // cast all to 18 decimals
            .mul(forTokenBalances ? forTokenBalances[index] : Zero)
        })
        const tokenBalancesSum: BigNumber = tokenBalances.reduce((sum, b) =>
          sum.add(b),
        )
        const isMetaSwap = POOL.metaSwapAddresses != null
        const tokenBalancesUSD = effectivePoolTokens.map((token, i, arr) => {
          // use another token to estimate USD price of meta LP tokens
          const symbol =
            isMetaSwap && i === arr.length - 1
              ? getTokenSymbolForPoolType(POOL.type)
              : token.crossId
          const balance = tokenBalances[i]

          const tokenPriceUSD = tokenPricesUSD[symbol]

          return balance
            .mul(parseUnits(String(tokenPriceUSD || 0), 18))
            .div(BigNumber.from(10).pow(18))
        })
        const tokenBalancesUSDSum: BigNumber = tokenBalancesUSD.reduce((sum, b) =>
          sum.add(b),
        )
        const lpTokenPriceUSD = tokenBalancesSum.isZero()
          ? Zero
          : tokenBalancesUSDSum
            .mul(BigNumber.from(10).pow(18))
            .div(tokenBalancesSum)

        function calculatePctOfTotalShare(lpTokenAmount: BigNumber): BigNumber {
          // returns the % of total lpTokens
          return lpTokenAmount
            .mul(BigNumber.from(10).pow(18))
            .div(
              totalLpTokenBalance.isZero()
                ? BigNumber.from("1")
                : totalLpTokenBalance,
            )
        }

        const amountStakedInRewardsForPool = amountsStakedInRewards[poolName] ?? Zero
        // lpToken balance in wallet as a % of total lpTokens, plus lpTokens staked elsewhere
        const userShare = calculatePctOfTotalShare(userLpTokenBalance)
          .add(calculatePctOfTotalShare(amountStakedInRewardsForPool))
        const userPoolTokenBalances = tokenBalances.map((balance) => {
          return userShare.mul(balance).div(BigNumber.from(10).pow(18))
        })
        const userPoolTokenBalancesSum: BigNumber = userPoolTokenBalances.reduce(
          (sum, b) => sum.add(b),
        )
        const userPoolTokenBalancesUSD = tokenBalancesUSD.map((balance) => {
          return userShare.mul(balance).div(BigNumber.from(10).pow(18))
        })
        const userPoolTokenBalancesUSDSum: BigNumber = userPoolTokenBalancesUSD.reduce(
          (sum, b) => sum.add(b),
        )

        const poolTokens = effectivePoolTokens.map((token, i) => ({
          symbol: token.symbol,
          percent: formatBNToPercentString(
            tokenBalances[i]
              .mul(10 ** 5)
              .div(
                totalLpTokenBalance.isZero()
                  ? BigNumber.from("1")
                  : tokenBalancesSum,
              ),
            5,
          ),
          value: tokenBalances[i],
        }))
        const userPoolTokens = effectivePoolTokens.map((token, i) => ({
          symbol: token.symbol,
          percent: formatBNToPercentString(
            tokenBalances[i]
              .mul(10 ** 5)
              .div(
                totalLpTokenBalance.isZero()
                  ? BigNumber.from("1")
                  : tokenBalancesSum,
              ),
            5,
          ),
          value: userPoolTokenBalances[i],
        }))
        const poolAddress = POOL.addresses[chainId].toLowerCase()
        const { oneDayVolume, apy, utilization } =
          swapStats && poolAddress in swapStats
            ? swapStats[poolAddress]
            : { oneDayVolume: null, apy: null, utilization: null }

        const poolData = {
          name: poolName,
          tokens: poolTokens,
          reserve: tokenBalancesUSDSum,
          totalLocked: totalLpTokenBalance,
          virtualPrice: virtualPrice,
          adminFee: adminFee,
          swapFee: swapFee,
          aParameter: aParameter,
          volume: oneDayVolume ? parseUnits(oneDayVolume, 18) : null,
          utilization: utilization ? parseUnits(utilization, 18) : null,
          apy: apy ? parseUnits(apy, 18) : null,
          lpTokenPriceUSD,
          lpToken: POOL.lpToken.symbol,
          isPaused,
          partnership: POOL.partnership,
        }
        const userShareData = account
          ? {
            name: poolName,
            share: userShare,
            underlyingTokensAmount: userPoolTokenBalancesSum,
            usdBalance: userPoolTokenBalancesUSDSum,
            tokens: userPoolTokens,
            lpTokenBalance: userLpTokenBalance,
            // amountsStaked: {}, // this is # of underlying tokens (eg btc), not lpTokens
          }
          : null
        // setPoolData([poolData, userShareData])
        newPoolsData[poolName] = [poolData, userShareData]
      })
      setPoolData(newPoolsData)
      //  setPoolData([poolData, userShareData]) 
      // REMOVE LOADING INDICATOR
      loadingIndicator(false)
    }
    void getSwapData().catch((error) => {
      console.error(error)
    })
  }, [lastDepositTime, lastWithdrawTime, lastSwapTime, lastMigrateTime, lastStakeOrClaimTime, poolNames, swapContractsList, tokenPricesUSD, account, library, chainId, swapStats, amountsStakedInRewards, multiCall, metaSwapContractsList, lpTokenContractsList])

  return poolData

}
