import "./UnsupportedNetwork.scss"

import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next";
import { ChainId } from "../constants/pools/types/ChainId";
import { isNetworkSupported } from "../utils/isNetworkSupported";

interface Props {
  chainId: ChainId | undefined, 
}

export default function UnsupportedNetwork({chainId }: Props): ReactElement | null {
  const { t } = useTranslation()
  if (chainId && (!isNetworkSupported(chainId))) { 
    return (
      <>
        <div className="unsupportedNetwork">{t("unsupportedNetwork")}</div>
        <div className="switchToCronosNetwork">{t("switchToCronosNetwork")}</div>
      </>
    )
  }
  return null
}