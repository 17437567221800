export const loadingIndicator = (loadingStart: boolean, once = false): void => {
    const classList = window.document.body.classList

    if (!loadingStart) {
        // REMOVE INDICATOR
        classList.remove('active-loader')
        return
    }

    if (process.env.REACT_APP_WEB3_CALL_LOADER !== 'true') return

    // ADD INDICATOR
    setTimeout(() => {
        if (once && !classList.contains('already-updated')) {
            // @ts-ignore
            classList.add('active-loader')
            classList.add('already-updated')
        } else if (!once) {
            classList.add('active-loader')
        }
    }, 600)
}
