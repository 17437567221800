import { buildAddresses } from "./buildAddresses";
import { ENV } from "./ENV";
import { ChainId } from "./types/ChainId";
import { Pool } from "./types/Pool";
import { MAI_METASWAP_POOL_NAME, STABLECOIN_CROSS_POOL_NAME } from "./types/PoolName";
import { Token } from "./types/Token";
import { PoolTypes } from "./types/PoolTypes";
import { buildPids } from "./buildPids";
import { STABLECOIN_POOL_TOKENS, STABLECOIN_SWAP_V3_TOKEN } from "./StableCoinCrossPool";
import xMaiLPTokenLogo from "../../assets/icons/xmai-logo-ellipse-transparent-260x260.svg"
import maiLogo from "../../assets/icons/mai.png"
import qiDaoLogo from "../../assets/img/partnership/QiDao-red.svg"

const MAI_META_SWAP_DEPOSIT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0xe3F50086CCfc311b51F02c5608E005dEE07910C5",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x1B1Fc00F9C8155eBDC4687ED48f0afDea48F05A1",
        })
    }
  }
}

const MAI_SWAP_TOKEN_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0xDE3EB534408F84b81D6f6c5F1be64f448B175d3d",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x58C20EAee25C23Edf4cA6c55e83b206b5074c305",
        })
    }
  }
}

export const MAI_SWAP_TOKEN = new Token(
  MAI_SWAP_TOKEN_CONTRACT_ADDRESSES(),
  18,
  "xMAI",
  "cross-mimatic",
  "Cross MAI-DAI/USDC/USDT",
  xMaiLPTokenLogo,
  false,
  true,
  false,
  false
)

const MAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.CRONOS]: "0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb",
})

export const MAI = new Token(
  MAI_CONTRACT_ADDRESSES,
  18,
  "MAI",
  "mimatic",
  "miMatic",
  maiLogo, // TODO: change format to SVG
  true,
)

export const MAI_POOL_TOKENS = [MAI, ...STABLECOIN_POOL_TOKENS]

const MAI_META_SWAP_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x25DFB69A0152096c824427ed643942aab971A11a",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x5e909A72150F74272326E2160B3f94DB3b11442e",
        })
    }
  }
}

const MAI_UNDERLYING_POOL_TOKENS = [MAI, STABLECOIN_SWAP_V3_TOKEN]

export const MAI_METASWAP_POOL: Pool = {
  name: MAI_METASWAP_POOL_NAME,
  addresses: MAI_META_SWAP_DEPOSIT_ADDRESSES(),
  lpToken: MAI_SWAP_TOKEN,
  poolTokens: MAI_POOL_TOKENS,
  isSynthetic: true,
  type: PoolTypes.USD,
  metaSwapAddresses: MAI_META_SWAP_ADDRESSES(),
  underlyingPoolTokens: MAI_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_CROSS_POOL_NAME,
  route: "mai",
  rewardPids: buildPids({
    [ChainId.CRONOS]: 4,
  }),
  partnership: {
    logo: qiDaoLogo,
    url: "https://www.mai.finance/",
    i18n: "partnership"
  },
}
