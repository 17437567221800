import "./Web3Status.scss"

import React, { ReactElement, useEffect, useState } from "react"
import AccountDetails from "./AccountDetails"
import ConnectWallet from "./ConnectWallet"
import Identicon from "./Identicon"
import Modal from "./Modal"
import { useTranslation } from "react-i18next"
import { useWeb3React } from "@web3-react/core"

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
}

const Web3Status = (): ReactElement => {
  const { account } = useWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  const { t } = useTranslation()

  // always reset to account view
  useEffect(() => {
    if (modalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalOpen])

  useEffect(() => {
    const topMenu = window.document.querySelector("header.top")
    if (!account) {
      topMenu?.classList.add("no-account")
    } else {
      topMenu?.classList.remove("no-account")
    }
  }, [account])

  return (

    <div className="walletStatus">
      {account ? (
        <div className="hasAccount" onClick={(): void => setModalOpen(true)}>
          <span className="address">
            {account.substring(0, 6)}•••••
            {account.substring(account.length - 4, account.length)}
          </span>

          <Identicon />
        </div>
      ) : (
        <button className="btn noAccount" onClick={(): void => setModalOpen(true)}>{t("connectWallet")}</button>
      )}
      <Modal isOpen={modalOpen} onClose={(): void => setModalOpen(false)}>
        {account && walletView === WALLET_VIEWS.ACCOUNT ? (
          <AccountDetails
            openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
          />
        ) : (
          <ConnectWallet onClose={(): void => setModalOpen(false)} />
        )}
      </Modal>
    </div>
  )
}

export default Web3Status
