import { buildAddresses } from "./pools/buildAddresses"
import { ChainId } from "./pools/types/ChainId"
import { ENV } from "./pools/ENV"

import { injected, walletconnect, walletlink } from "../connectors"

import { AbstractConnector } from "@web3-react/abstract-connector"
import { BigNumber } from "@ethersproject/bignumber"
import coinbasewalletIcon from "../assets/icons/coinbasewallet.svg"
import metamaskIcon from "../assets/icons/metamask.svg"
import walletconnectIcon from "../assets/icons/walletconnect.svg"

export const NetworkContextName = "NETWORK"

export const NATIVE_COIN_NAME = "CROSS"

export const BLOCK_TIME = 13000 // ms

export const SYNTHETIX_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
})

export const SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xd69b189020EF614796578AfE4d10378c5e7e1138",
})

export const BRIDGE_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xa5bD85ed9fA27ba23BfB702989e7218E44fd4706",
})

export const MINICHEF_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.HARDHAT]: "0x927b167526bAbB9be047421db732C663a0b77B11",
          [ChainId.ARBITRUM]: "0x2069043d7556B1207a505eb459D18d908DF29b55",
          [ChainId.MAINNET]: "0x691ef79e40d909C715BE5e9e93738B3fF7D58534",
          [ChainId.POLYGON]: "0x1930c5592505cBB0d86A09c0d067F31C8F235fc6",
          [ChainId.CRONOS]: "0x99dc526D0686Ed67cc6D5973A602e933ec74a4F8",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0xC3842B2d35dd249755f170dD8F0f83b8BF967E21",
        })
    }
  }
}

export const LOCKED_STAKE_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.POLYGON]: "0x481397BDE6399735677F4b60Bd0DddE521bAa3Ee",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x61b5fe7e5206061d8a3404c2b6d59492b5316f6f",
        })
    }
  }
}

export const buyTokenUrls = {
  "default": "https://app.cronaswap.org/swap",
  "cronos-stable-swap": "https://app.cronaswap.org/swap?outputCurrency=0x6ef20ca7e493c52095e892dab78a7fd0e7e2a279",
}

export function isLegacySwapABIPool(): boolean {
  return false
}

export const TRANSACTION_TYPES = {
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  SWAP: "SWAP",
  MIGRATE: "MIGRATE",
  STAKE_OR_CLAIM: "STAKE_OR_CLAIM",
}

export const POOL_FEE_PRECISION = 10

export enum SWAP_TYPES {
  DIRECT = "swapDirect", // route length 2
  SYNTH_TO_SYNTH = "swapSynthToSynth", // route length 2
  SYNTH_TO_TOKEN = "swapSynthToToken", // route length 3
  TOKEN_TO_SYNTH = "swapTokenToSynth", // route length 3
  TOKEN_TO_TOKEN = "swapTokenToToken", // route length 4
  INVALID = "invalid",
}

export function getIsVirtualSwap(swapType: SWAP_TYPES): boolean {
  return (
    swapType === SWAP_TYPES.SYNTH_TO_SYNTH ||
    swapType === SWAP_TYPES.SYNTH_TO_TOKEN ||
    swapType === SWAP_TYPES.TOKEN_TO_SYNTH ||
    swapType === SWAP_TYPES.TOKEN_TO_TOKEN
  )
}

export const SWAP_CONTRACT_GAS_ESTIMATES_MAP = {
  [SWAP_TYPES.INVALID]: BigNumber.from("999999999"), // 999,999,999
  [SWAP_TYPES.DIRECT]: BigNumber.from("200000"), // 157,807
  [SWAP_TYPES.TOKEN_TO_TOKEN]: BigNumber.from("2000000"), // 1,676,837
  [SWAP_TYPES.TOKEN_TO_SYNTH]: BigNumber.from("2000000"), // 1,655,502
  [SWAP_TYPES.SYNTH_TO_TOKEN]: BigNumber.from("1500000"), // 1,153,654
  [SWAP_TYPES.SYNTH_TO_SYNTH]: BigNumber.from("700000"), // 681,128 // TODO: https://github.com/saddle-finance/saddle-frontend/issues/471
  addLiquidity: BigNumber.from("400000"), // 386,555
  removeLiquidityImbalance: BigNumber.from("350000"), // 318,231
  removeLiquidityOneToken: BigNumber.from("250000"), // 232,947
  migrate: BigNumber.from("650000"), // 619,126
  virtualSwapSettleOrWithdraw: BigNumber.from("400000"),
}

export interface WalletInfo {
  name: string
  icon: string
  connector: AbstractConnector
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    name: "MetaMask",
    icon: metamaskIcon,
    connector: injected,
  },
  WALLET_CONNECT: {
    name: "WalletConnect",
    icon: walletconnectIcon,
    connector: walletconnect,
  },
  WALLET_LINK: {
    name: "Coinbase Wallet",
    icon: coinbasewalletIcon,
    connector: walletlink,
  },
}

// "SADDLE" in bytes32 form
export const SYNTH_TRACKING_ID =
  "0x534144444c450000000000000000000000000000000000000000000000000000"

// FLAGS
export const IS_VIRTUAL_SWAP_ACTIVE = true
export const IS_L2_SUPPORTED = true
export const IS_SDL_LIVE = true // TODO change to Cross token
// FLAGS END

export const LIQUIDITY_PROVIDER_FEE = 0.0004; // 0.04%
