import { isProduction } from "../constants/pools/ENV"
import { ChainId } from "../constants/pools/types/ChainId"

export const supportedNetworks = isProduction()
  ? [ChainId.CRONOS]
  : [ChainId.CRONOS, ChainId.POLYGON]

export const isNetworkSupported = (chainId: number): boolean => {
  return supportedNetworks.includes(chainId)
}
