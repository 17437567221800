import "./Version.scss"

import React, { ReactElement, useContext } from "react"

import { Link } from "react-router-dom"
import { ThemeContext } from "../providers/ThemeProvider"

import HeimdallrLogo from "../assets/img/heimdallr.svg"
import HeimdallrLogoWhite from "../assets/img/heimdallr-white.svg"
import GitbookIcon from "../assets/img/gitbook-icon.svg"
import GitbookIconWhite from "../assets/img/gitbook-icon-white.svg"
import TelegramIcon from "../assets/img/telegram-icon.svg"
import TelegramIconWhite from "../assets/img/telegram-icon-white.svg"
import TwitterIcon from "../assets/img/twitter-icon.svg"
import TwitterIconWhite from "../assets/img/twitter-icon-white.svg"
import { useTranslation } from "react-i18next"

function Version(): ReactElement | null {
  const { userDarkMode } = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <div className="container">
        <div className="wrapper">
          <div className="copy">{t("copy_Footer_Version")}</div>
          <nav className="links">
            <Link to="/risk">{t("risk")}</Link>
          </nav>
          <div className="social">
            <a href="https://cronos-stable-swap-1.gitbook.io/cronos-stable-swap/" target="_blank" rel="noreferrer" title="Docs">
              <img src={userDarkMode ? GitbookIconWhite : GitbookIcon} alt="logo"/>
            </a>
            <a href="https://t.me/CronosStableSwap" target="_blank" rel="noreferrer" title="Telegram chaneel">
              <img src={userDarkMode ? TelegramIconWhite : TelegramIcon} alt="logo"/>
            </a>
            <a href="https://twitter.com/avtoCROSS" target="_blank" rel="noreferrer" title="Twitter">
              <img src={userDarkMode ? TwitterIconWhite : TwitterIcon} alt="logo"/>
            </a>
          </div>
          <div className="expander"></div>
          <div className="audited">
            <a href="https://heimdallr.network/reports/avtocross-security-report.html" target="_blank" rel="noreferrer" title={t("securityReport")}>
              <span>{t("auditedBy")}</span>
              <img src={userDarkMode ? HeimdallrLogoWhite : HeimdallrLogo} alt="logo"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Version
