import "./Pools.scss"

import { Farm, FARMS_MAP } from "../constants/farms/FarmsMap"

import React, { ReactElement, useMemo, useState } from "react"

import ConfirmTransaction from "../components/ConfirmTransaction"
import Modal from "../components/Modal"
import TopMenu from "../components/TopMenu"
import classNames from "classnames"
import { useActiveWeb3React } from "../hooks"
import { useTranslation } from "react-i18next"
import MyFarm from "../components/MyFarm"
import useRewardsStats from "../hooks/useRewardsStats"
import { shallowEqual, useSelector } from "react-redux"
import { AppState } from "../state"
import { loadingIndicator } from "../utils/loadingIndicator"
import useLockedRewardsStats from "../hooks/useLockedRewardsStats"
import UnsupportedNetwork from "../components/UnsupportedNetwork"

function Farms(): ReactElement | null {
  // REMOVE LOADING INDICATOR
  loadingIndicator(false)

  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const farmsList: Farm[] = useMemo(() => Object.values(FARMS_MAP)
    .filter(({ rewardPids }) => (chainId ? rewardPids[chainId] != null : false)),
    [chainId])
  const unlockedFarms = useMemo(() => farmsList.filter(( { isLocked}) => isLocked !== true),
    [farmsList])
  const annualRewardAmount = useSelector((state: AppState) => state.application.avtoCrossTokenStats.annualRewardAmount)
  const prices = useSelector((state: AppState) => state.application.tokenPricesUSD, shallowEqual)
  const chefAllocPoint = useSelector((state: AppState) => state.application.avtoCrossTokenStats.chefAllocPoint)
  const lockChefAllocPoint = useSelector((state: AppState) => state.application.avtoCrossTokenStats.lockChefAllocPoint)
  const totalAllocPoint = useSelector((state: AppState) => state.application.avtoCrossTokenStats.totalAllocPoint)
  const emissionAlreadyMinted = useSelector((state: AppState) => state.application.avtoCrossTokenStats.emissionAlreadyMinted)
  const avtoCrossTokenStats = useMemo(() => ({
    annualRewardAmount,
    chefAllocPoint,
    lockChefAllocPoint,
    totalAllocPoint,
    emissionAlreadyMinted
  }), [annualRewardAmount, chefAllocPoint, emissionAlreadyMinted, lockChefAllocPoint, totalAllocPoint])
  const rewardStats = useRewardsStats(unlockedFarms, avtoCrossTokenStats, prices)
  const lockedRewardStats = useLockedRewardsStats(farmsList, avtoCrossTokenStats, prices)
  if (!chainId || !farmsList.length) {
    return (
      <div className={classNames("poolsPage", "unsupportedNetwork")}>
        <TopMenu activeTab="farms" />
        <div className="container">
          <div className="contentInnerWrapper">
            <UnsupportedNetwork chainId={chainId}/>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="poolsPage">
      <TopMenu activeTab="farms" />
      {/* <ul className="filters"></ul> */}
      <div className="container">
        <div className="contentInnerWrapper">
          <UnsupportedNetwork chainId={chainId} />
          <h1 className="title">{t("farms")}</h1>
          <div className="desc-wrapper">
            <h3 className="desc">{t("farmDesc")}</h3>
          </div>
          {farmsList.map((farm: Farm) => (
            <MyFarm
              key={farm.name}
              farm={farm}
              rewardStats={farm.isLocked ? lockedRewardStats[0] : rewardStats.find((item) => item.poolId === farm.rewardPids[chainId])}
              prices={prices}
            />
          ))}
        </div>
      </div>
      <Modal
        isOpen={!!currentModal}
        onClose={(): void => setCurrentModal(null)}
      >
        {currentModal === "confirm" ? <ConfirmTransaction /> : null}
      </Modal>
    </div>
  )
}

export default Farms
