import { POOLS_MAP } from "./PoolsMap"
import { PoolName } from "./types/PoolName"
import { Token } from "./types/Token"

// maps a symbol string to a token object
export type TokensMap = {
  [symbol: string]: Token
}

export const TOKENS_MAP = (Object.keys(POOLS_MAP) as PoolName[]).reduce((acc, poolName) => {
  const pool = POOLS_MAP[poolName]
  const newAcc = { ...acc }
  pool.poolTokens.forEach((token) => {
    newAcc[token.symbol] = token
  })
  newAcc[pool.lpToken.symbol] = pool.lpToken
  return newAcc
}, {} as TokensMap)

export type TokenToPoolsMap = {
  [tokenSymbol: string]: PoolName[]
}
export const TOKEN_TO_POOLS_MAP = (Object.keys(POOLS_MAP) as PoolName[]).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName]
    const newAcc = { ...acc }
    pool.poolTokens.forEach((token) => {
      newAcc[token.symbol] = (newAcc[token.symbol] || []).concat(
        poolName,
      )
    })
    return newAcc
  },
  {} as TokenToPoolsMap,
)

export type LPTokenToPoolsMap = {
  [tokenSymbol: string]: PoolName
}
export const LPTOKEN_TO_POOL_MAP = (Object.keys(POOLS_MAP) as PoolName[]).reduce(
  (acc, poolName) => {
    const pool = POOLS_MAP[poolName]
    const newAcc = { ...acc }
    newAcc[pool.lpToken.symbol] = poolName
    return newAcc
  },
  {} as LPTokenToPoolsMap,
)
