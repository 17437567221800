import { ChainId } from "./ChainId"

export class Token {
    readonly addresses: { [chainId in ChainId]: string }
    readonly decimals: number
    readonly symbol: string
    readonly name: string
    readonly icon: string
    readonly crossId: string
    readonly isSynthetic: boolean
    readonly isLPToken: boolean
    readonly isLiquidityPairToken: boolean
    readonly isOnCoinGecko: boolean
  
    constructor(
      addresses: { [chainId in ChainId]: string },
      decimals: number,
      symbol: string,
      crossId: string,
      name: string,
      icon: string,
      isSynthetic = false,
      isLPToken = false,
      isLiquidityPairToken = false,
      isOnCoinGecko = true,
    ) {
      this.addresses = addresses
      this.decimals = decimals
      this.symbol = symbol
      this.crossId = crossId
      this.name = name
      this.icon = icon
      this.isSynthetic = isSynthetic
      this.isLPToken = isLPToken
      this.isLiquidityPairToken = isLiquidityPairToken
      this.isOnCoinGecko = isOnCoinGecko
    }
}

