import { useActiveWeb3React } from "./index"
import { useLockedStakeRewardContract, useMulticall } from "./useEthMultiCallContracts"
import { useEffect, useRef, useState } from "react"
import { BigNumber as BigNumberJs } from "bignumber.js"
import { BigNumber } from "@ethersproject/bignumber"
import { Farm } from "../constants/farms/FarmsMap"
import { Zero } from "@ethersproject/constants"
import { AvtoCrossTokenStats, TokenPricesUSD } from "../state/application"
import { PoolRewardStats } from "./useRewardsStats"

export default function useLockedRewardsStats(
  farmsList: Farm[],
  avtoCrossTokenStats: AvtoCrossTokenStats,
  prices: TokenPricesUSD
): PoolRewardStats[] {
  const { annualRewardAmount, lockChefAllocPoint, totalAllocPoint } = avtoCrossTokenStats;
  const { chainId } = useActiveWeb3React()
  const currentChainId = chainId || 25
  const lockedRewardsContractAddress = farmsList.find((farm: Farm) => farm.isLocked)?.lpToken.addresses[currentChainId]
  const [rewardsStats, setRewardsStats] = useState<PoolRewardStats[]>([])
  const lockedStakeContract = useLockedStakeRewardContract()
  const multicall = useMulticall()

  const multicallId = useRef<number>(0)
  const multicallLastShownId = useRef<number>(-1)
  useEffect(() => {
    async function getRewardsStats(): Promise<void> {
      if (
        !lockedStakeContract ||
        !lockedRewardsContractAddress ||
        !multicall
      ) {
        return
      }
      const mulCalls = []
      mulCalls.push({
        rewardData: lockedStakeContract.methods.rewardData(lockedRewardsContractAddress),
        rewardContractBalance: lockedStakeContract.methods.totalSupply(),
      })
      const id = multicallId.current
      multicallId.current += 1
      const [{ rewardData, rewardContractBalance }] = (await multicall.all([mulCalls]))[0]
      if (id < multicallLastShownId.current) {
        return
      } else {
        multicallLastShownId.current = id
      }
      const crossPerSecond = rewardData[1]
      const oneDaySecs = BigNumber.from(24 * 60 * 60)
      const crossUSDPrice = prices['cronos-stable-swap'] || 0
      const result: PoolRewardStats = {
        poolId: 0,
        crossPerDay: (BigNumber.from(crossPerSecond))
          .mul(oneDaySecs),
        apr: (BigNumber.from(rewardContractBalance)).eq(Zero) || totalAllocPoint === 0 || crossUSDPrice === 0
          ? "0"
          : new BigNumberJs(annualRewardAmount)
            .multipliedBy(new BigNumberJs(lockChefAllocPoint))
            .dividedBy(new BigNumberJs(totalAllocPoint))
            .multipliedBy(crossUSDPrice)
            .dividedBy(new BigNumberJs(rewardContractBalance)
              .multipliedBy(new BigNumberJs(crossUSDPrice)))
            .multipliedBy(100)
            .toFormat(2)
            .toString(),
        tvl: (BigNumber.from(rewardContractBalance)).eq(Zero)
          ? Zero
          : BigNumber.from(new BigNumberJs(rewardContractBalance)
            .dividedBy(new BigNumberJs(10 ** 18))
            .multipliedBy(new BigNumberJs(crossUSDPrice))
            .integerValue()
            .toString()),
        tvlInFarmToken: (BigNumber.from(rewardContractBalance)).eq(Zero)
          ? Zero
          : BigNumber.from(new BigNumberJs(rewardContractBalance)
            .dividedBy(new BigNumberJs(10 ** 18))
            .integerValue()
            .toString()),
      }
      setRewardsStats([result]);
    }

    void getRewardsStats()
  }, [annualRewardAmount, lockChefAllocPoint, lockedRewardsContractAddress, lockedStakeContract, multicall, prices, totalAllocPoint])
  return rewardsStats
}
