import "./ConnectWallet.scss"

import React, { ReactElement } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { SUPPORTED_WALLETS } from "../constants"
import { logEvent } from "../utils/googleAnalytics"
import { map } from "lodash"
import { useTranslation } from "react-i18next"
import { useActiveWeb3React } from "../hooks"
import {
  ethereumWallets,
  NETWORK_SUPPORTED_WALLETS,
} from "../constants/networks"
import { isMobile } from "react-device-detect"
import { setupNetwork } from "../utils/wallet"
import { supportedNetworks } from "../utils/isNetworkSupported"
import { resetWalletConnector } from "../connectors"
import { removeWalletConnectStorage } from "../utils/removeWalletConnectStorage"

interface Props {
  onClose: () => void
}

function ConnectWallet({ onClose }: Props): ReactElement {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const { activate } = useWeb3React()

  const networSupportedWallets = chainId
    ? NETWORK_SUPPORTED_WALLETS[chainId]
    : ethereumWallets

  if (isMobile && !window.ethereum) {
    delete SUPPORTED_WALLETS.METAMASK
  }

  return (
    <div className="connectWallet">
      <h3>{t("connectWallet")}</h3>
      <div className="walletList">
        {map(SUPPORTED_WALLETS, (wallet, index) => {
          return (
            <button
              key={index}
              onClick={(): void => {
                if (index === "METAMASK" && !window.ethereum) {
                  console.log("Metamask is not installed")
                  return
                }

                // TODO: temporary condition
                if (index === "METAMASK" && supportedNetworks.length > 1) {
                  void wallet.connector.getChainId().then((res): void => {
                    wallet.connector.supportedChainIds?.push(+res)
                  })
                }

                if (index === "WALLET_CONNECT") {
                  // FIX FOR REOPENING WALLETCONNECT MODAL'S ISSUE
                  // eslint-disable-next-line  @typescript-eslint/no-unsafe-call
                  resetWalletConnector(wallet.connector)
                  removeWalletConnectStorage() // fix for AMATIC-418
                }

                // eslint-disable-next-line
                activate(wallet.connector, undefined, true).catch(
                  async (error) => {
                    if (error instanceof UnsupportedChainIdError) {
                      // eslint-disable-next-line
                      const hasSetup = await setupNetwork(index)

                      if (hasSetup) {
                        void activate(wallet.connector) // a little janky...can't use setError because the connector isn't set
                      }
                    } else {
                      console.error(error)
                      // TODO: handle error
                    }
                  },
                )
                logEvent("change_wallet", { name: wallet.name })
                onClose()
                // document.body.classList.remove("modal-is-open")
              }}
            >
              <span>
                {wallet.name}
                <span className="grey">
                  {index === "METAMASK" && !window.ethereum && t("notInstalled")}
                </span>
              </span>
              <img src={wallet.icon} alt="icon" className="icon" />
            </button>
          )
        })}
      </div>
      <p>
        {t("dontHaveWallet") + " "}
        <a href={networSupportedWallets} target="blank">
          {t("getWallet")}
        </a>
      </p>
    </div>
  )
}

export default ConnectWallet
