import { buildPids } from "../pools/buildPids";
import { BUSD, BUSD_SWAP_TOKEN } from "../pools/BUSDMetaswapPool";
import { STABLECOIN_SWAP_V3_TOKEN } from "../pools/StableCoinCrossPool";
import { ChainId } from "../pools/types/ChainId";
import { Farm } from "./FarmsMap";

export const XBUSD_FARM_TOKENS = [BUSD, STABLECOIN_SWAP_V3_TOKEN]

export const xBusdStaking: Farm = {
  name: "BUSD Metapool Staking",
  chainId: ChainId.CRONOS, 
  lpToken: BUSD_SWAP_TOKEN, 
  rewardPids: buildPids({
    [ChainId.CRONOS]: 3,
  }),
  poolTokens: XBUSD_FARM_TOKENS
}