import { AppDispatch } from "../state"
const { REACT_APP_CROSS_API_BASE_URL = '' } = process.env
import retry from "async-retry"
import { TokenPricesUSD, updateTokensPricesUSD } from "../state/application"

export default function fetchTokenPricesUSD(
  dispatch: AppDispatch
): void {
  void retry(
    () =>
      fetch(`${REACT_APP_CROSS_API_BASE_URL}/prices`)
        .then((res) => res.json())
        .then((body: TokenPricesUSD) => {
          dispatch(updateTokensPricesUSD(body))
        }),
    { retries: 3 },
  )
}
