import {
  TRANSACTION_TYPES,
  isLegacySwapABIPool,
} from "../constants"
import { POOLS_MAP } from "../constants/pools/PoolsMap"
import { PoolName } from "../constants/pools/types/PoolName"
import { Token } from "../constants/pools/types/Token"
import { formatDeadlineToNumber, getContract } from "../utils"
import { notifyCustomError, notifyHandler } from "../utils/notifyHandler"
import {
  useAllContracts,
  useLPTokenContract,
  useSwapContract,
} from "./useContract"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { Erc20 } from "../../types/ethers-contracts/Erc20"
import { GasPrices } from "../state/user"
import { IS_PRODUCTION } from "../utils/environment"
import META_SWAP_ABI from "../constants/abis/metaSwap.json"
import { MetaSwap } from "../../types/ethers-contracts/MetaSwap"
import { NumberInputState } from "../utils/numberInputState"
import { SwapFlashLoan } from "../../types/ethers-contracts/SwapFlashLoan"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import checkAndApproveTokenForTrade from "../utils/checkAndApproveTokenForTrade"
import { parseUnits } from "@ethersproject/units"
import { subtractSlippage } from "../utils/slippage"
import { updateLastTransactionTimes } from "../state/application"
import { useActiveWeb3React } from "."
import { useMemo } from "react"

interface ApproveAndDepositStateArgument {
  [tokenSymbol: string]: NumberInputState
}

export function useApproveAndDeposit(
  poolName: PoolName,
): (
  state: ApproveAndDepositStateArgument,
  shouldDepositWrapped?: boolean,
) => Promise<void> {
  const dispatch = useDispatch()
  const POOL = POOLS_MAP[poolName]
  const swapContract = useSwapContract(poolName, true)
  const lpTokenContract = useLPTokenContract(POOL.lpToken)
  const tokenContracts = useAllContracts(true)
  const { account, chainId, library } = useActiveWeb3React()

  const gasStandard = useSelector((state: AppState) => state.application.gasStandard)
  const gasFast = useSelector((state: AppState) => state.application.gasFast)
  const gasInstant = useSelector((state: AppState) => state.application.gasInstant)

  const infiniteApproval = useSelector((state: AppState) => state.user.infiniteApproval)
  const slippageCustom = useSelector((state: AppState) => state.user.slippageCustom, shallowEqual)
  const slippageSelected = useSelector((state: AppState) => state.user.slippageSelected)
  const transactionDeadlineSelected = useSelector((state: AppState) => state.user.transactionDeadlineSelected)
  const transactionDeadlineCustom = useSelector((state: AppState) => state.user.transactionDeadlineCustom)
  const gasCustom = useSelector((state: AppState) => state.user.gasCustom, shallowEqual)
  const gasPriceSelected = useSelector((state: AppState) => state.user.gasPriceSelected)

  const metaSwapContract = useMemo(() => {
    if (POOL.metaSwapAddresses && chainId && library) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        META_SWAP_ABI,
        library,
        account ?? undefined,
      ) as MetaSwap
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  return async function approveAndDeposit(
    state: ApproveAndDepositStateArgument,
    shouldDepositWrapped = false,
  ): Promise<void> {
    try {
      if (!account) throw new Error("Wallet must be connected")
      if (
        !swapContract ||
        !lpTokenContract ||
        (shouldDepositWrapped && !metaSwapContract)
      )
        throw new Error("Swap contract is not loaded")

      const poolTokens = shouldDepositWrapped
        ? (POOL.underlyingPoolTokens as Token[])
        : POOL.poolTokens
      const effectiveSwapContract = shouldDepositWrapped
        ? (metaSwapContract as MetaSwap)
        : swapContract

      let gasPriceUnsafe: string | number | undefined
      if (gasPriceSelected === GasPrices.Custom) {
        gasPriceUnsafe = gasCustom?.valueSafe
      } else if (gasPriceSelected === GasPrices.Fast) {
        gasPriceUnsafe = gasFast
      } else if (gasPriceSelected === GasPrices.Instant) {
        gasPriceUnsafe = gasInstant
      } else {
        gasPriceUnsafe = gasStandard
      }
      const gasPrice = parseUnits(
        gasPriceUnsafe ? String(gasPriceUnsafe) : "45",
        9,
      )
      const approveSingleToken = async (token: Token): Promise<void> => {
        const spendingValue = BigNumber.from(state[token.symbol].valueSafe)
        if (spendingValue.isZero()) return
        const tokenContract = tokenContracts?.[token.symbol] as Erc20
        if (tokenContract == null) return
        await checkAndApproveTokenForTrade(
          tokenContract,
          effectiveSwapContract.address,
          account,
          spendingValue,
          infiniteApproval,
          gasPrice,
          {
            onTransactionError: (error) => {
              console.error(error)
              throw new Error("Your transaction could not be completed")
            },
          },
        )
        return
      }
      // For each token being deposited, check the allowance and approve it if necessary
      if (!IS_PRODUCTION) {
        for (const token of poolTokens) {
          await approveSingleToken(token)
        }
      } else {
        await Promise.all(poolTokens.map((token) => approveSingleToken(token)))
      }

      const isFirstTransaction = (await lpTokenContract.totalSupply()).isZero()
      let minToMint: BigNumber
      if (isFirstTransaction) {
        minToMint = BigNumber.from("0")
      } else {
        if (isLegacySwapABIPool()) {
          minToMint = await (effectiveSwapContract as SwapFlashLoan).calculateTokenAmount(
            account,
            poolTokens.map(({ symbol }) => state[symbol].valueSafe),
            true, // deposit boolean
          )
        } else {
          minToMint = await (effectiveSwapContract as SwapFlashLoanNoWithdrawFee).calculateTokenAmount(
            poolTokens.map(({ symbol }) => state[symbol].valueSafe),
            true, // deposit boolean
          )
        }
      }

      minToMint = subtractSlippage(minToMint, slippageSelected, slippageCustom)
      const deadline = formatDeadlineToNumber(
        transactionDeadlineSelected,
        transactionDeadlineCustom,
      )

      const txnAmounts = poolTokens.map(({ symbol }) => state[symbol].valueSafe)
      const txnDeadline = Math.round(
        new Date().getTime() / 1000 + 60 * deadline,
      )
      const swapFlashLoanContract = effectiveSwapContract as SwapFlashLoan
      console.log("approveAndDeposit", txnAmounts, minToMint, txnDeadline)
      const spendTransaction = await swapFlashLoanContract?.addLiquidity(
        txnAmounts,
        minToMint,
        txnDeadline,
      )

      notifyHandler(spendTransaction.hash, "deposit")

      await spendTransaction.wait()
      dispatch(
        updateLastTransactionTimes({
          [TRANSACTION_TYPES.DEPOSIT]: Date.now(),
        }),
      )
      return Promise.resolve()
    } catch (e) {
      console.error(e)
      notifyCustomError(e as Error)
    }
  }
}
