import { buildAddresses } from "../pools/buildAddresses";
import { buildPids } from "../pools/buildPids";
import { ENV } from "../pools/ENV";
import { ChainId } from "../pools/types/ChainId";
import { Token } from "../pools/types/Token";
import { Farm } from "./FarmsMap";
import { AVTOCROSS_TOKEN } from "./crossStaking";
import { STABLECOIN_SWAP_V3_TOKEN } from "../pools/StableCoinCrossPool";
import crossLPTokenLogo from "../../assets/icons/logo-ellipse-transparent-260x260.svg"


const AVTOCROSS_STABLECOIN_SWAP_V3_TOKEN_PAIR_TOKEN_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x8d0350C5a2A5E570A236E49028F5EF8aaB6699D9",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x26882757Bc8A8DcFace126d78d38a7bBA2a280cd",
        })
    }
  }
}

export const AVTOCROSS_STABLECOIN_SWAP_V3_TOKEN_PAIR_TOKEN = new Token(
  AVTOCROSS_STABLECOIN_SWAP_V3_TOKEN_PAIR_TOKEN_ADDRESSES(),
  18,
  "Crona-LP",
  "cross-xusd",
  "Crona LPs",
  crossLPTokenLogo,
  false,
  false,
  true
)

export const CROSS_XUSD_FARM_TOKENS = [AVTOCROSS_TOKEN, STABLECOIN_SWAP_V3_TOKEN]

export const crossXUSDStaking: Farm = {
  name: "CROSS-xUSD LP Staking",
  chainId: ChainId.CRONOS,
  lpToken: AVTOCROSS_STABLECOIN_SWAP_V3_TOKEN_PAIR_TOKEN,
  rewardPids: buildPids({
    [ChainId.CRONOS]: 2,
  }),
  poolTokens: CROSS_XUSD_FARM_TOKENS,
  addLiquidityUrl: "https://app.cronaswap.org/add/0x6ef20cA7E493c52095e892DAB78a7FD0e7e2a279/0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139",
  addLiquidityLabel: "Add Liquidity",
}
