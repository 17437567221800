import {
  BLOCK_TIME, LOCKED_STAKE_CONTRACT_ADDRESSES,
  MINICHEF_CONTRACT_ADDRESSES,
  TRANSACTION_TYPES,
} from "../constants"

import { FARMS_MAP } from "../constants/farms/FarmsMap"

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { Contract } from "ethcall"
import MINICHEF_CONTRACT_ABI from "../constants/abis/miniChef.json"
import LOCKED_STAKE_CONTRACT_ABI from "../constants/abis/lockedStake.json"
import { MiniChef } from "../../types/ethers-contracts"
import { MulticallContract } from "../types/ethcall"
import { Zero } from "@ethersproject/constants"
import { getMulticallProvider } from "../utils"
import { useActiveWeb3React } from "../hooks"
import usePoller from "../hooks/usePoller"
import { shallowEqual, useSelector } from "react-redux"
import { isNetworkSupported } from "../utils/isNetworkSupported"
import { LockedStake } from "../../types/ethers-contracts"

type PoolsRewards = { [poolName: string]: BigNumber }
type AggRewards = PoolsRewards & { total: BigNumber }
export const RewardsBalancesContext = React.createContext<PoolsRewards>({
  total: Zero,
})

export default function RewardsBalancesProvider({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const [aggbalances, setAggBalances] = useState<AggRewards>({
    total: Zero,
  })
  const poolsRewardsBalances = usePoolsRewardBalances()

  useMemo(() => {
    const total = Object.values({
      ...poolsRewardsBalances,
    }).reduce((acc, bal) => {
      return acc.add(bal || Zero)
    }, Zero)
    setAggBalances({
      ...poolsRewardsBalances,
      total,
    })
  }, [poolsRewardsBalances])

  return (
    <RewardsBalancesContext.Provider value={aggbalances}>
      {children}
    </RewardsBalancesContext.Provider>
  )
}

function usePoolsRewardBalances() {
  const { chainId, account, library } = useActiveWeb3React()
  const [balances, setBalances] = useState<PoolsRewards>({})
  const lastTransactionTimes = useSelector(
    (state: AppState) => state.application.lastTransactionTimes,
    shallowEqual
  )
  const lastStakeOrClaim =
    lastTransactionTimes[TRANSACTION_TYPES.STAKE_OR_CLAIM]
  const fetchBalances = useCallback(async () => {
    if (!library || !chainId || !account || !isNetworkSupported(chainId)) {
      setBalances({})
      return
    }
    const ethcallProvider = await getMulticallProvider(library, chainId)
    const farms = Object.values(FARMS_MAP).filter(
      (farm) =>
        farm.rewardPids[chainId] !== null,
    )
    if (farms.length === 0) return
    const rewardsMulticallContract = new Contract(
      MINICHEF_CONTRACT_ADDRESSES()[chainId],
      MINICHEF_CONTRACT_ABI,
    ) as MulticallContract<MiniChef>
    const lockedStakeContract = new Contract(
      LOCKED_STAKE_CONTRACT_ADDRESSES()[chainId],
      LOCKED_STAKE_CONTRACT_ABI,
    ) as MulticallContract<LockedStake>
    const pendingSDLCalls = farms.map(({ rewardPids, isLocked }) =>
      isLocked
        ? lockedStakeContract.claimableRewards(account)
        : rewardsMulticallContract.pendingCross(
        rewardPids[chainId] as number,
        account,
      ),
    )
    try {
      // @ts-ignore
      const fetchedBalances = await ethcallProvider.all(pendingSDLCalls, {})
      setBalances(
        fetchedBalances.reduce((acc, balance, i) => {
          const { name } = farms[i]
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
          return Array.isArray(balance) && balance.length ? { ...acc, [name]: balance[0].amount } : balance != null && balance.gt(Zero)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            ? { ...acc, [name]: balance }
            : acc
        }, {} as { [poolName: string]: BigNumber }),
      )
    } catch (e) {
      console.error(e)
    }
  }, [library, chainId, account])
  useEffect(() => {
    void fetchBalances()
  }, [fetchBalances, lastStakeOrClaim])
  usePoller(() => void fetchBalances(), BLOCK_TIME * 3)
  return balances
}
