import "./SiteSettingsMenu.scss"

import { IS_L2_SUPPORTED, IS_SDL_LIVE } from "../constants"
import { AVTOCROSS_TOKEN } from "../constants/farms/crossStaking"
import { ChainId } from "../constants/pools/types/ChainId"
import React, { ReactElement, useContext, useEffect, useState } from "react"

import { ThemeContext } from "../providers/ThemeProvider"
import classnames from "classnames"
import tokenLogo from "../assets/img/logo-ellipse-transparent-260x260.svg"
import activeIcon from "../assets/img/active.svg"
import cronosLogo from "../assets/img/crypto-com.svg"
import polygonLogo from "../assets/img/polygon.svg"
import unitedKingdom from "../assets/img/united-kingdom.svg"
import { useActiveWeb3React } from "../hooks"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import { useTranslation } from "react-i18next"
import { isNetworkSupported, supportedNetworks } from "../utils/isNetworkSupported"
import { REACT_APP_CRONOS_RPC_URL } from "../connectors"

const iSun = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 14.5C8.80653 14.5 7.66193 14.0259 6.81802 13.182C5.97411 12.3381 5.5 11.1935 5.5 10C5.5 8.80653 5.97411 7.66193 6.81802 6.81802C7.66193 5.97411 8.80653 5.5 10 5.5C11.1935 5.5 12.3381 5.97411 13.182 6.81802C14.0259 7.66193 14.5 8.80653 14.5 10C14.5 11.1935 14.0259 12.3381 13.182 13.182C12.3381 14.0259 11.1935 14.5 10 14.5ZM10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13ZM9.25 1.75H10.75V4H9.25V1.75ZM9.25 16H10.75V18.25H9.25V16ZM3.63625 4.69675L4.69675 3.63625L6.2875 5.227L5.227 6.2875L3.63625 4.6975V4.69675ZM13.7125 14.773L14.773 13.7125L16.3638 15.3032L15.3032 16.3638L13.7125 14.773ZM15.3032 3.6355L16.3638 4.69675L14.773 6.2875L13.7125 5.227L15.3032 3.63625V3.6355ZM5.227 13.7125L6.2875 14.773L4.69675 16.3638L3.63625 15.3032L5.227 13.7125ZM18.25 9.25V10.75H16V9.25H18.25ZM4 9.25V10.75H1.75V9.25H4Z" fill="#ffffff"/>
</svg>`
const iCrescent = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 6.25C8.49985 7.29298 8.81035 8.31237 9.39192 9.17816C9.97348 10.0439 10.7997 10.7169 11.7653 11.1112C12.7309 11.5055 13.7921 11.6032 14.8134 11.3919C15.8348 11.1807 16.7701 10.67 17.5 9.925V10C17.5 14.1422 14.1422 17.5 10 17.5C5.85775 17.5 2.5 14.1422 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5H10.075C9.57553 2.98834 9.17886 3.57172 8.90836 4.21576C8.63786 4.8598 8.49902 5.55146 8.5 6.25V6.25ZM4 10C3.99945 11.3387 4.44665 12.6392 5.27042 13.6945C6.09419 14.7497 7.24723 15.4992 8.54606 15.8236C9.84489 16.148 11.2149 16.0287 12.4381 15.4847C13.6614 14.9407 14.6675 14.0033 15.2965 12.8215C14.1771 13.0852 13.0088 13.0586 11.9026 12.744C10.7964 12.4295 9.78888 11.8376 8.97566 11.0243C8.16244 10.2111 7.57048 9.20361 7.25596 8.09738C6.94144 6.99116 6.91477 5.82292 7.1785 4.7035C6.21818 5.2151 5.41509 5.97825 4.85519 6.91123C4.2953 7.84422 3.99968 8.91191 4 10V10Z" fill="#ffffff"/>
</svg>
`

export let externalSetIsDropdownOpen: any

export default function SiteSettingsMenu({
  isDropdownOpen,
  setIsDropdownOpen
}: {
  isDropdownOpen: boolean,
  setIsDropdownOpen: (value: boolean) => void
}): ReactElement {
  const { chainId } = useActiveWeb3React()
  const networkIsSupported = isNetworkSupported(chainId || 1);
  if (isDropdownOpen) {}

  useEffect(() => {
    externalSetIsDropdownOpen = setIsDropdownOpen
  }, [setIsDropdownOpen])

  return (
    <div className="siteSettingsMenu containerWrapper">
      <div className="container">
        <div className="header">
          <div className="title">Platform settings</div>
          <div className="close-btn">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsDropdownOpen(false)}
            >
              <path
                d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z"
                fill="#ffffff"
              />
            </svg>

          </div>
        </div>
        {IS_L2_SUPPORTED && <NetworkSection key="network" />}
        {IS_L2_SUPPORTED && <Divider />}
        <LanguageSection key="language" />
        <Divider />
        <ThemeSection key="theme" />
        {IS_SDL_LIVE &&  networkIsSupported && <AddTokenSection key="token" />}
      </div>
    </div>
  )
}

function Divider(): ReactElement {
  return <div className="divider"></div>
}

function AddTokenSection(): ReactElement | null {
  const { addToken, canAdd } = useAddTokenToMetamask({
    ...AVTOCROSS_TOKEN,
    icon: "https://avtocross.finance/logo-cross.png",
  })
  const { t } = useTranslation()

  return canAdd ? (
    <>
    <Divider />
    <div className="section add-token">
      <div className="sectionTitle" onClick={() => addToken()}>
        <span>{t("addAvtoCross")}</span>
        <img src={tokenLogo} className="logo" />
      </div>
    </div>
    </>
  ) : null
}

// refer to https://github.com/sushiswap/sushiswap-interface/blob/canary/src/modals/NetworkModal/index.tsx#L13
export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
    logo?: string
  }
} = {
  [ChainId.MAINNET]: {
    chainId: "0x1",
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.com"],
  },
  [ChainId.ARBITRUM]: {
    chainId: "0xA4B1",
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://mainnet-arb-explorer.netlify.app"],
  },
  [ChainId.CRONOS]: {
    chainId: "0x19",
    chainName: "Crypto.com",
    nativeCurrency: {
      name: "Cronos",
      symbol: "CRO",
      decimals: 18,
    },
    rpcUrls: [REACT_APP_CRONOS_RPC_URL],
    blockExplorerUrls: ["https://cronos.crypto.org/explorer/"],
    logo: cronosLogo,
  },
  [ChainId.POLYGON]: {
    chainId: "0x89",
    chainName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    logo: polygonLogo,
  },
}
function NetworkSection(): ReactElement {
  const { t } = useTranslation()
  const { chainId: activeChainId, library, account } = useActiveWeb3React()
  const [isNetworkVisible, setIsNetworkVisible] = useState(false)

  if (isNetworkVisible) {}

  return (
    <div className="section">
      <div
        className="sectionTitle"
        onClick={() => setIsNetworkVisible((state) => !state)}
      >
        <span>{t("network")}</span>
      </div>
      {supportedNetworks.map((chainId) => {
          const params = SUPPORTED_NETWORKS[chainId]

          return (
            <div
              className={classnames("sectionItem", {
                ["active"]: activeChainId === chainId,
              })}
              onClick={() => {
                if (account) {
                  void library?.send("wallet_addEthereumChain", [
                    params,
                    account,
                  ])
                }
                // void library?.send("wallet_addEthereumChain", [params, account])
              }}
              key={chainId}
            >
              <div className="logo">
                <img src={params?.logo} alt={params?.chainName}/>
              </div>
              <div className="text">
                <span>{params?.chainName}</span>
              </div>
              {activeChainId === chainId && (
                <div className="is-active">
                  <img src={activeIcon} alt="Active"/>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

function LanguageSection(): ReactElement {
  const { t, i18n } = useTranslation()

  const [isLanguageVisible, setIsLanguageVisible] = useState(false)
  if (isLanguageVisible) {}
  const languageOptions = [
    { displayText: "English", i18nKey: "en", logo: unitedKingdom },
    // { displayText: "简体中文", i18nKey: "zh" },
  ]
  const currentLanguage = i18n.language
  return (
    <div className="section">
      <div
        className="sectionTitle"
        onClick={() => setIsLanguageVisible((state) => !state)}
      >
        <span>{t("language")}</span>{" "}
      </div>
      {languageOptions.map(({ displayText, i18nKey, logo }) => (
          <div
            className={classnames("sectionItem", {
              ["active"]: currentLanguage === i18nKey,
            })}
            onClick={() => i18n.changeLanguage(i18nKey)}
            key={displayText}
          >
            <div className="logo">
              <img src={logo} alt={displayText}/>
            </div>
            <div className="text">
              <span>{displayText}</span>
            </div>
            {currentLanguage === i18nKey && (
              <div className="is-active">
                <img src={activeIcon} alt="Active"/>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

function ThemeSection(): ReactElement {
  const { t } = useTranslation()
  const { toggleTheme, userDarkMode } = useContext(ThemeContext)

  const themeOptions = [
    { displayText: "Light", id: 0, logo: iSun },
    { displayText: "Dark", id: 1, logo: iCrescent },
  ]
  const currentTheme = userDarkMode ? 1 : 0
  return (
    <div className="section">
      <div className="sectionTitle lowercase">
        <span>{t("theme")}</span>
      </div>
      {themeOptions.map(({ displayText, id, logo }) => (
          <div
            className={classnames("sectionItem", {
              ["active"]: (!userDarkMode && id === 0) || (userDarkMode && id === 1),
            })}
            onClick={() => {
              if ((userDarkMode && id === 0) || (!userDarkMode && id === 1)) toggleTheme()
            }}
            key={displayText}
          >
            <div className="logo transparent">
              <div dangerouslySetInnerHTML={{ __html: logo }} />
            </div>
            <div className="text">
              <span>{displayText}</span>
            </div>
            {currentTheme === id && (
              <div className="is-active">
                <img src={activeIcon} alt="Active"/>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}
