import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { externalSetIsDropdownOpen } from "./SiteSettingsMenu"

export function HideTopMenu(): null {
  const { pathname } = useLocation()
  const [ state, setState ] = useState(false)

  // Decorative animation for the top nav block (START)
  const hideTopMenu = () => {
    let lastScrollTop = 0
    let shift = 0
  
    const el = document.getElementById("headerNav")
    const elHeight = el?.clientHeight || 0
    const extremes = [elHeight * 0.875, elHeight * 0.125]
  
    window.onscroll = () => {
      externalSetIsDropdownOpen(false) // disabling of floating settings menu
  
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let toDown
  
      if (scrollTop > lastScrollTop){
        toDown = true
        shift += scrollTop - lastScrollTop
        if (shift > extremes[0]) shift = extremes[0]
      } else {
        toDown = false
        shift -= lastScrollTop - scrollTop
        if (shift < 0) shift = 0
      }
  
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
  
      if (toDown && shift >= extremes[0]) {
        el?.classList.add("hidden")
      } else if (!toDown && shift <= extremes[1]) {
        el?.classList.remove("hidden")
      }
    }
  }

  // TODO: implement onLoad listener
  if (!state) {
    setTimeout(() => {
      setState(true)
    }, 1000)
  }

  useEffect(() => {
    hideTopMenu()
  }, [pathname, state])

  return null
}
