import "./Homepage.scss"

import React, { ReactElement, useContext, useState } from "react"

import TopMenu from "../components/TopMenu"
import { useTranslation } from "react-i18next"
import { loadingIndicator } from "../utils/loadingIndicator"
import { Link } from "react-router-dom"

import DarrowIcon from "../assets/img/darrow.svg"
import CrossTokenLogo from "../assets/img/logo-ellipse-transparent-260x260.svg"
import CrossLogo from "../assets/img/logo2-vertical.svg"
import CrossLogoDark from "../assets/img/logo2-dark-vertical.svg"
import MarketingImage from "../assets/img/marketing-image-section4.png"

// Partners
import QiDao from "../assets/img/partnership/qidao.svg"
import MultiChain from "../assets/img/partnership/multichain.svg"
import MultiChainWhite from "../assets/img/partnership/multichain-white.svg"
import Minotaur from "../assets/img/partnership/minotaur.svg"
import MinotaurWhite from "../assets/img/partnership/minotaur-white.svg"
import DexPad from "../assets/img/partnership/dexpad.svg"
import DexPadWhite from "../assets/img/partnership/dexpad-white.svg"
import Crystl from "../assets/img/partnership/crystl.svg"
import CrystlWhite from "../assets/img/partnership/crystl-white.svg"
import CronaSwap from "../assets/img/partnership/cronaswap.svg"
import CronaSwapWhite from "../assets/img/partnership/cronaswap-white.svg"
import Autofarm from "../assets/img/partnership/autofarm.svg"
import AutofarmWhite from "../assets/img/partnership/autofarm-white.svg"
import { useWeb3React } from "@web3-react/core"
import ConnectWallet from "../components/ConnectWallet"
import Modal from "../components/Modal"
import { ThemeContext } from "../providers/ThemeProvider"
import { STABLECOIN_CROSS_POOL_NAME } from "../constants/pools/types/PoolName"
import { AppState } from "../state"
import { useSelector } from "react-redux"
import { AVTOCROSS_TOKEN } from "../constants/farms/crossStaking"
import { formatBNToString } from "../utils"
import { BigNumber as BigNumberJs } from "bignumber.js"
import { usePoolsData } from "../hooks/usePoolsData"
import { commify } from "@ethersproject/units"
import classNames from "classnames"
import { useMemoizePoolNameAsArray } from "../hooks/useMemoizePoolNameAsArray"


function Homepage(): ReactElement {
    // REMOVE LOADING INDICATOR
    loadingIndicator(false)

    const { t } = useTranslation()
    const { account } = useWeb3React()
    const [modalOpen, setModalOpen] = useState(false)
    const { userDarkMode } = useContext(ThemeContext)
    const crossPriceUSD = useSelector(
      (state: AppState) => {
        return state.application.tokenPricesUSD?.[AVTOCROSS_TOKEN.crossId]
      }
    )
    const emissionAlreadyMinted: string = useSelector(
      (state: AppState) => {
        return state.application.avtoCrossTokenStats?.emissionAlreadyMinted
      }
    )
    const stableCoinCrossPoolNameArray = useMemoizePoolNameAsArray(STABLECOIN_CROSS_POOL_NAME)
    const [usdPoolV3Data] = usePoolsData(stableCoinCrossPoolNameArray)[STABLECOIN_CROSS_POOL_NAME]
    const formattedXUSD3PoolTVLUSD = usdPoolV3Data?.reserve
        ? commify(formatBNToString(usdPoolV3Data.reserve, 18, 0))
        : "--"
    const dilutedMarketCapitalization = crossPriceUSD === undefined ? "--" : (new BigNumberJs(crossPriceUSD).multipliedBy(new BigNumberJs(10 ** 8))).toFormat(0).toString()
    const circulatingSupply = (new BigNumberJs(emissionAlreadyMinted).dividedBy(new BigNumberJs(10 ** 18)).plus(new BigNumberJs(3 * 10 ** 6)))
    const circulatedMarketCapitalization = circulatingSupply.multipliedBy(new BigNumberJs(crossPriceUSD === undefined ? 0 : crossPriceUSD))
    const formattedCirculatedMarketCapitalization = emissionAlreadyMinted === undefined || crossPriceUSD === undefined ? "--" : circulatedMarketCapitalization.toFormat(0).toString()

    const isFormattedXUSD3PoolTVLUSD = formattedXUSD3PoolTVLUSD === '--' || formattedXUSD3PoolTVLUSD === '0'
    const isFormattedCirculatedMarketCapitalization = formattedCirculatedMarketCapitalization === '--' || formattedCirculatedMarketCapitalization === '0'
    const isDilutedMarketCapitalization = dilutedMarketCapitalization === '--' || dilutedMarketCapitalization === '0'

    return (
        <div className="homepage">
            <TopMenu activeTab={t("risk")} />
            <div className="section1">
                <div className="container">
                    <div className="wrapper-top">
                        <div className="info">
                            <h1 className="title">{t("title_Section1_Homepage")}</h1>
                            <h3 className="desc">{t("desc_Section1_Homepage")}</h3>
                        </div>
                        <div className="action">
                            {account ? (
                                <Link to="/swap" className="btn">
                                    {t("goToApp")}
                                </Link>
                            ) : (
                                <button className="btn" onClick={(): void => setModalOpen(true)}>{t("connectWallet")}</button>
                            )}
                            <Modal isOpen={modalOpen} onClose={(): void => setModalOpen(false)}>
                                <ConnectWallet onClose={(): void => setModalOpen(false)} />
                            </Modal>
                        </div>
                    </div>
                    <div className="wrapper-bottom">
                        <div className="stats">
                            <div className="card">
                                <div className="card-internal">
                                    <div className="title">{t("totalValueLocked1")}</div>
                                    <div className={classNames("value", isFormattedXUSD3PoolTVLUSD && "blink")}>
                                        {`${isFormattedXUSD3PoolTVLUSD ? '' : '$'}${formattedXUSD3PoolTVLUSD}`}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-internal">
                                    <div className="title">{t("totalValueLocked2")}</div>
                                    <div className={classNames("value", isFormattedCirculatedMarketCapitalization && "blink")}>
                                        {`${isFormattedCirculatedMarketCapitalization ? '': '$'}${formattedCirculatedMarketCapitalization}`}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-internal">
                                    <div className="title">{t("totalValueLocked3")}</div>
                                    <div className={classNames("value", isDilutedMarketCapitalization && "blink")}>
                                        {`${isDilutedMarketCapitalization ? '' : '$'}${dilutedMarketCapitalization}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="arrow-b">
                            <img src={DarrowIcon} alt="arrow"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section2">
                <div className="container">
                    <h2 className="title">{t("title_Section2_Homepage1")} <span className="nowrap">{t("title_Section2_Homepage2")}</span></h2>
                    <div className="desc">
                        {t("desc_Section2_Homepage_p1")}<br/><br/>
                        {t("desc_Section2_Homepage_p2")}<br/><br/>
                        {t("desc_Section2_Homepage_p3")}<br/><br/>
                        {t("desc_Section2_Homepage_p4")}
                    </div>
                </div>
            </div>

            <div className="section3">
                <div className="container">
                    <div className="wrapper">
                        <div className="graphics">
                            <img src={MarketingImage} alt="graphics"/>
                        </div>
                        <div className="info">
                            <h2 className="title">{t("title_Section3_Homepage")}</h2>
                            <div className="list">
                                <div className="item">
                                    <div className="item-info">
                                        <h3 className="item-title">{t("subTitle1_Section3_Homepage")}</h3>
                                        <div className="item-desc">{t("subDesc1_Section3_Homepage")}</div>
                                    </div>
                                    <div className="item-action">
                                        <Link to="/swap" className="btn">{t("action1_Section3_Homepage")}</Link>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-info">
                                        <h3 className="item-title">{t("subTitle2_Section3_Homepage")}</h3>
                                        <div className="item-desc">{t("subDesc2_Section3_Homepage")}</div>
                                    </div>
                                    <div className="item-action">
                                        <Link to="/pools" className="btn">{t("action2_Section3_Homepage")}</Link>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-info">
                                        <h3 className="item-title">{t("subTitle3_Section3_Homepage")}</h3>
                                        <div className="item-desc">{t("subDesc3_Section3_Homepage")}</div>
                                    </div>
                                    <div className="item-action">
                                        <Link to="/farm" className="btn">{t("action3_Section3_Homepage")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section4">
                <div className="container">
                    <div className="wrapper">
                        <div className="info">
                            <div className="info-header">
                                <img src={CrossTokenLogo} alt="cross"/>
                                <h2 className="title">{t("title_Section4_Homepage")}</h2>
                            </div>
                            <div className="desc">{t("desc_Section4_Homepage")}</div>
                            <div className="action">
                                <a href="https://cronos-stable-swap.gitbook.io/cronos-stable-swap/fundamentals/tokenomics" target="_blank" rel="noreferrer" className="btn">
                                    {t("action_Section4_Homepage")}
                                </a>
                            </div>
                        </div>
                        <div className="graphics">
                            <img src={userDarkMode ? CrossLogo : CrossLogoDark} alt="graphics"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section5">
                <div className="container">
                    <div className="wrapper">
                        <div className="info">
                            <h2 className="title">{t("title_Section5_Homepage")}</h2>
                            <div className="desc">{t("desc_Section5_Homepage")}</div>
                        </div>
                        <div className="graphics">
                            <a href="https://autofarm.network/cronos/" target="_blank" rel="noreferrer" title="Autofarm">
                                <img src={userDarkMode ? AutofarmWhite : Autofarm} alt="Autofarm"/>
                            </a>
                            <a href="https://app.cronaswap.org/swap" target="_blank" rel="noreferrer" title="CronaSwap">
                                <img src={userDarkMode ? CronaSwapWhite : CronaSwap} alt="CronaSwap"/>
                            </a>
                            <a href="https://www.crystl.finance/" target="_blank" rel="noreferrer" title="CrystlFinance">
                                <img src={userDarkMode ? CrystlWhite : Crystl} alt="CrystlFinance"/>
                            </a>
                            <a href="https://dexpad.io/home" target="_blank" rel="noreferrer" title="DexPad">
                                <img src={userDarkMode ? DexPadWhite : DexPad} alt="DexPad"/>
                            </a>
                            <a href="https://minotaur.money/#/dashboard" target="_blank" rel="noreferrer" title="Docs">
                                <img src={userDarkMode ? MinotaurWhite : Minotaur} alt="Minotaur"/>
                            </a>
                            <a href="https://multichain.org/" target="_blank" rel="noreferrer" title="MultiChain">
                                <img src={userDarkMode ? MultiChainWhite : MultiChain} alt="MultiChain"/>
                            </a>
                            <a href="https://www.mai.finance/" target="_blank" rel="noreferrer" title="QiDao">
                                <img src={QiDao} alt="QiDao"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage
