import "./Pools.scss"

import {
  BUSD_METASWAP_POOL_NAME,
  MAI_METASWAP_POOL_NAME,
  // DNA_METASWAP_POOL_NAME,
  TUSD_METASWAP_POOL_NAME,
  PoolName,
  STABLECOIN_CROSS_POOL_NAME,
  WxUSD_METASWAP_POOL_NAME,
} from "../constants/pools/types/PoolName"

import { PoolTypes } from "../constants/pools/types/PoolTypes"
import { POOLS_MAP, allPoolNames as poolNames } from "../constants/pools/PoolsMap"
import { emptyPoolData, PoolDataType, UserShareType } from "../hooks/usePoolsData"

import React, { ReactElement, useState } from "react"

import ConfirmTransaction from "../components/ConfirmTransaction"
import Modal from "../components/Modal"
import PoolOverview from "../components/PoolOverview"
import TopMenu from "../components/TopMenu"
import { Zero } from "@ethersproject/constants"
import classNames from "classnames"
import { useActiveWeb3React } from "../hooks"
import { usePoolsData } from "../hooks/usePoolsData"
import { useTranslation } from "react-i18next"
import UnsupportedNetwork from "../components/UnsupportedNetwork"

function Pools(): ReactElement | null {
  const poolsListData = usePoolsData(poolNames)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [
    filter,
    // setFilter
  ] = useState<PoolTypes | "all" | "outdated">("all")

  const noData = (poolName: PoolName) => [{ ...emptyPoolData, name: poolName }, null] 
  const [usdPoolV3Data, usdV3UserShareData] = poolsListData?.[STABLECOIN_CROSS_POOL_NAME] ?? noData(STABLECOIN_CROSS_POOL_NAME)
  const [busdPoolData, busdUserShareData] = poolsListData?.[BUSD_METASWAP_POOL_NAME] ?? noData(BUSD_METASWAP_POOL_NAME)
  const [maiPoolData, maiUserShareData] = poolsListData?.[MAI_METASWAP_POOL_NAME] ?? noData(MAI_METASWAP_POOL_NAME)
  const [tusdPoolData, tusdUserShareData] = poolsListData?.[TUSD_METASWAP_POOL_NAME] ?? noData(TUSD_METASWAP_POOL_NAME)
  const [wxusdPoolData, wxusdUserShareData] = poolsListData?.[WxUSD_METASWAP_POOL_NAME] ?? noData(WxUSD_METASWAP_POOL_NAME)

  interface PropsForPool {
    name: PoolName
    poolData: PoolDataType
    poolRoute: string
    userShareData: UserShareType | null
  }

  function getPropsForPool(poolName: PoolName): PropsForPool {
    switch (poolName) {
      case STABLECOIN_CROSS_POOL_NAME:
        return {
          name: STABLECOIN_CROSS_POOL_NAME,
          poolData: usdPoolV3Data,
          userShareData: usdV3UserShareData,
          poolRoute: "/pools/usdv3",
        }
      case BUSD_METASWAP_POOL_NAME:
        return {
          name: BUSD_METASWAP_POOL_NAME,
          poolData: busdPoolData,
          userShareData: busdUserShareData,
          poolRoute: "/pools/busd",
        }
      case MAI_METASWAP_POOL_NAME:
        return {
          name: MAI_METASWAP_POOL_NAME,
          poolData: maiPoolData,
          userShareData: maiUserShareData,
          poolRoute: "/pools/mai"
        }
      // case DNA_METASWAP_POOL_NAME)
      //   return {
      //     name: DNA_METASWAP_POOL_NAME,
      //     poolData: dnaPoolData,
      //     userShareData: dnaUserShareData,
      //     poolRoute: "/pools/dna"
      //   }
      case TUSD_METASWAP_POOL_NAME:
        return {
          name: TUSD_METASWAP_POOL_NAME,
          poolData: tusdPoolData,
          userShareData: tusdUserShareData,
          poolRoute: "/pools/tusd"
        }
      case WxUSD_METASWAP_POOL_NAME:
        return {
          name: WxUSD_METASWAP_POOL_NAME,
          poolData: wxusdPoolData,
          userShareData: wxusdUserShareData,
          poolRoute: "/pools/wxusd"
        }
    }
  }

  const poolsList = Object.values(POOLS_MAP)
    .filter(
      ({ type, migration, isOutdated }) =>
        filter === "all" ||
        type === filter ||
        (filter === "outdated" && (migration || isOutdated)),
    )
    .filter(({ addresses }) => (chainId ? addresses[chainId] : false))
    .filter(({ isHidden }) => (!isHidden))
    .map(
      ({ name, migration, isOutdated }) =>
        [getPropsForPool(name), migration, isOutdated] as const,
    )
  .sort(([a, aMigration, aIsOutdated], [b, bMigration, bIsOutdated]) => {
    // 1. active pools
    // 2. user pools
    // 3. higher TVL pools
    if (aMigration || bMigration || aIsOutdated || bIsOutdated) {
      return aMigration || aIsOutdated ? 1 : -1
    } else if (
      (a?.userShareData?.usdBalance || Zero).gt(Zero) ||
      (b?.userShareData?.usdBalance || Zero).gt(Zero)
    ) {
      return (a?.userShareData?.usdBalance || Zero).gt(
        b?.userShareData?.usdBalance || Zero,
      )
        ? -1
        : 1
    } else {
      return (a?.poolData?.reserve || Zero).gt(b?.poolData?.reserve || Zero)
        ? -1
        : 1
    }
  })

  if (!poolsList.length) {
    return (
      <div className={classNames("poolsPage", "unsupportedNetwork")}>
        <TopMenu activeTab="pools" />
        <div className="container">
          <div className="contentInnerWrapper">
            <UnsupportedNetwork chainId={chainId} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="poolsPage">
      <TopMenu activeTab="pools" />
      {/* <ul className="filters">
        {[
          ["all", "ALL"] as const,
          [PoolTypes.BTC, "BTC"] as const,
          [PoolTypes.ETH, "ETH"] as const,
          [PoolTypes.USD, "USD"] as const,
          ["outdated", "OUTDATED"] as const,
        ].map(([filterKey, text]) => (
          <li
            key={filterKey}
            className={classNames("filterTab", {
              ["selected"]: filter === filterKey,
              ["outdated"]: filterKey === "outdated",
            })}
            onClick={(): void => setFilter(filterKey)}
          >
            {text}
          </li>
        ))}
      </ul> */}
      <div className="container">
        <div className="contentInnerWrapper">
        <UnsupportedNetwork chainId={chainId} />
          <h1 className="title">{t("pools")}</h1>
          <div className="desc-wrapper">
            <h3 className="desc">{t("poolsDesc")}</h3>
          </div>
          {poolsList.map(([poolProps]) => (
            <PoolOverview key={poolProps.name} {...poolProps} />
          ))}
        </div>
      </div>
      <Modal
        isOpen={!!currentModal}
        onClose={(): void => setCurrentModal(null)}
      >
        {currentModal === "confirm" ? <ConfirmTransaction /> : null}
      </Modal>
    </div>
  )
}

export default Pools
