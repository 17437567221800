import { buildPids } from "../pools/buildPids";
import { STABLECOIN_SWAP_V3_TOKEN } from "../pools/StableCoinCrossPool";
import { ChainId } from "../pools/types/ChainId";
import { Farm } from "./FarmsMap";

export const XUSD_FARM_TOKENS = [STABLECOIN_SWAP_V3_TOKEN]

export const xUsdStaking: Farm = {
  name: "xUSD Staking",
  chainId: ChainId.CRONOS,
  lpToken: STABLECOIN_SWAP_V3_TOKEN,
  rewardPids: buildPids({
    [ChainId.CRONOS]: 1,
  }),
  poolTokens: XUSD_FARM_TOKENS,
}