import "./SearchSelect.scss"

import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import Divider from "./Divider"
import { SWAP_TYPES } from "../constants"
import Tag from "./Tag"
import type { TokenOption } from "../pages/Swap"
import classnames from "classnames"
import { commify } from "../utils"
import { formatBNToString } from "../utils"
import { useTranslation } from "react-i18next"
import { getTokenName, getTokenSymbolToShow } from "./common/tokenUtils"

interface Props {
  tokensData: TokenOption[]
  onSelect: (symbol: string) => void
  value?: string
}

export default function SearchSelect({
  tokensData,
  onSelect,
}: Props): ReactElement {
  const [searchTerm, setSearchTerm] = useState("")
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const focusedItemRef = useRef<HTMLLIElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (inputRef?.current != null && window.innerWidth >= 576) {
      // for narrow mobile devices
      inputRef.current.focus()
    }
  }, [inputRef])
  useEffect(() => {
    // scroll active li into view if user got there via keyboard nav
    if (focusedItemRef?.current != null) {
      focusedItemRef.current.scrollIntoView()
    }
  }, [focusedItemRef, activeIndex])
  const filteredTokens = useMemo(() => {
    // filter tokens by user input
    return tokensData.filter(({ symbol, name }) => {
      const target = searchTerm.toLowerCase()
      return (
        symbol.toLowerCase().includes(target) ||
        name.toLowerCase().includes(target)
      )
    })
  }, [tokensData, searchTerm])
  const lastSelectableIndex = useMemo(() => {
    // find the last idx of isAvailable tokens
    const lastAvailableIndex =
      filteredTokens.findIndex(({ isAvailable }) => isAvailable === false) - 1
    return lastAvailableIndex < 0
      ? filteredTokens.length - 1
      : lastAvailableIndex
  }, [filteredTokens])

  return (
    <div className="searchSelect">
      <div className="inputContainer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5233 13.4628L17.7355 16.6743L16.6743 17.7355L13.4628 14.5233C12.2678 15.4812 10.7815 16.0022 9.25 16C5.524 16 2.5 12.976 2.5 9.25C2.5 5.524 5.524 2.5 9.25 2.5C12.976 2.5 16 5.524 16 9.25C16.0022 10.7815 15.4812 12.2678 14.5233 13.4628ZM13.0188 12.9063C13.9706 11.9274 14.5022 10.6153 14.5 9.25C14.5 6.349 12.1503 4 9.25 4C6.349 4 4 6.349 4 9.25C4 12.1503 6.349 14.5 9.25 14.5C10.6153 14.5022 11.9274 13.9706 12.9063 13.0188L13.0188 12.9063Z"
            fill="#43B446"
          />
        </svg>

        <input
          value={searchTerm}
          onChange={(e) => {
            const inputValue = e.target.value
            const nextActiveIndex = inputValue === "" ? null : 0
            activeIndex !== nextActiveIndex && setActiveIndex(nextActiveIndex)
            setSearchTerm(inputValue)
          }}
          ref={inputRef}
          onFocus={() => activeIndex != null && setActiveIndex(null)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && activeIndex != null) {
              const token = filteredTokens[activeIndex]
              token.isAvailable && onSelect(token.symbol)
            } else if (e.key === "ArrowUp") {
              setActiveIndex((prev) =>
                prev === null || prev === 0 ? null : Math.max(0, prev - 1),
              )
            } else if (e.key === "ArrowDown") {
              setActiveIndex((prev) =>
                prev === null ? 0 : Math.min(lastSelectableIndex, prev + 1),
              )
            }
          }}
          placeholder={t("searchPlaceholder")}
          // autoFocus={window.innerWidth < 576}
          // autoFocus={false}
        />
      </div>
      <ul className="listContainer">
        {filteredTokens.map((item, i) => {
          return (
            <li
              key={item.symbol}
              onClick={() => item.isAvailable && onSelect(item.symbol)}
              ref={i === activeIndex ? focusedItemRef : null}
            >
              <Divider />
              <ListItem {...item} isActive={i === activeIndex} />
            </li>
          )
        })}
        {filteredTokens.length === 0 ? (
          <li className="listItem">{t("noTokensFound")}</li>
        ) : null}
      </ul>
    </div>
  )
}

function ListItem({
  amount,
  valueUSD,
  name,
  icon,
  symbol,
  decimals,
  isActive,
  isAvailable,
  swapType,
}: TokenOption & { isActive: boolean }) {
  const { t } = useTranslation()
  const isVirtualSwap = ([
    SWAP_TYPES.SYNTH_TO_SYNTH,
    SWAP_TYPES.SYNTH_TO_TOKEN,
    SWAP_TYPES.TOKEN_TO_SYNTH,
    SWAP_TYPES.TOKEN_TO_TOKEN,
  ] as Array<SWAP_TYPES | null>).includes(swapType)
  return (
    <div
      className={classnames("listItem", {
        ["isActive"]: isActive,
        ["isUnavailable"]: !isAvailable,
        ["isAvailable"]: isAvailable,
      })}
    >
      <img className="asset-logo" src={icon} />
      <div className="tagInfo">
        <div className="tagWrapper">
          <span>{getTokenSymbolToShow(symbol)}</span> { /* alter token symbol for WxUSD https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485*/ }
          {!isAvailable && (
            <Tag position="none" size="small" kind="disabled">
              {t("unavailable")}
            </Tag>
          )}
          {isAvailable && isVirtualSwap && (
            <Tag position="none" size="small" kind="primary">
              {t("virtualSwap")}
            </Tag>
          )}
        </div>
        <div className="tagDesc">{getTokenName(name)}</div> { /* alter token name for WxUSD https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485*/ }
      </div>
      <div className="tagValue">
        <div className="sumMinor truncate">
          {commify(formatBNToString(amount, decimals))}
        </div>
        <div className="textMinor">
          ≈${commify(formatBNToString(valueUSD, 18, 2))}
        </div>
      </div>
    </div>
  )
}
