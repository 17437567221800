import "./Risk.scss"

import React, { ReactElement } from "react"

import TopMenu from "../components/TopMenu"
import { useTranslation } from "react-i18next"
import { loadingIndicator } from "../utils/loadingIndicator"

function Risk(): ReactElement {
  // REMOVE LOADING INDICATOR
  loadingIndicator(false)

  const { t } = useTranslation()

  return (
    <div className="riskpage">
      <TopMenu activeTab={t("risk")} />
      <div className="container">
        <div className="contentInnerWrapper">
          <h1 className="title">Risk</h1>
          <p data-testid="risk-risk">{t("riskRisk")}</p>
          <h3>{t("audits")}</h3>
          <p data-testid="risk-audits">
            {t("riskAudits")}{" "}
            <a href="https://saddle.finance/">{t("riskAudits2")}</a>{" "}
            {t("riskAudits3")}{" "}
            <a href="https://cronos.crypto.org/">{t("riskAudits4")}</a>.{" "}
            {t("riskAudits5")}{" "}
            <a href="https://github.com/saddle-finance/saddle-audits">
              {t("riskAudits6")}
            </a>
            .
          </p>
          <p data-testid="risk-audits-2">{t("riskAudits7")}</p>
          <h3>{t("otherConsiderations")}</h3>
          <p data-testid="risk-lossofpeg">
            <strong>{t("riskLossOfPeg")}</strong> - {t("riskLossOfPeg2")}
          </p>
          <p className="version">Version 1.0</p>
        </div>
      </div>
    </div>
  )
}

export default Risk
