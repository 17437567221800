import { Farm } from "./FarmsMap"
import { ChainId } from "../pools/types/ChainId"
import { buildPids } from "../pools/buildPids"
import { AVTOCROSS_TOKEN, CROSS_FARM_TOKENS } from "./crossStaking"

export const crossLockedStaking: Farm = {
  name: "CROSS Loyalty Staking",
  chainId: ChainId.CRONOS,
  lpToken: AVTOCROSS_TOKEN,
  rewardPids: buildPids({
    [ChainId.CRONOS]: 0,
  }),
  poolTokens: CROSS_FARM_TOKENS,
  isLocked: true,
  addLiquidityUrl: "https://cronos-stable-swap.gitbook.io/cronos-stable-swap/tutorials/how-to-earn-rewards-cross-loyalty-staking",
  addLiquidityLabel: "How to earn rewards?",
}
