import "./SwapPage.scss"

import Button from "./Button"
import React, { ReactElement, useMemo, useState } from "react"
import { SWAP_TYPES, getIsVirtualSwap, LIQUIDITY_PROVIDER_FEE } from "../constants"
import { BASE_POOL_TOKENS_SYMBOLS } from "../constants/pools/StableCoinCrossPool"
import { formatBNToPercentString, formatBNToString } from "../utils"

import AdvancedOptions from "./AdvancedOptions"
import { AppState } from "../state/index"
import { BigNumber } from "@ethersproject/bignumber"
import ConfirmTransaction from "./ConfirmTransaction"
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg"
import Modal from "./Modal"
import { PendingSwap } from "../hooks/usePendingSwapData"
import PendingSwapModal from "./PendingSwapModal"
import ReviewSwap from "./ReviewSwap"
import { Slippages } from "../state/user"
import SwapInput from "./SwapInput"
import type { TokenOption } from "../pages/Swap"
import TopMenu from "./TopMenu"
import { Zero } from "@ethersproject/constants"
import classNames from "classnames"
import { commify } from "../utils"
import { formatUnits } from "@ethersproject/units"
import { isHighPriceImpact } from "../utils/priceImpact"
import { logEvent } from "../utils/googleAnalytics"
import { useActiveWeb3React } from "../hooks"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { isNetworkSupported } from "../utils/isNetworkSupported"
import ToolTip from "./ToolTip"
import iInformation from "../assets/img/i-information.svg"
import { getTokenSymbolToShow } from "./common/tokenUtils"
import UnsupportedNetwork from "./UnsupportedNetwork"

interface Props {
  tokenOptions: {
    from: TokenOption[]
    to: TokenOption[]
  }
  exchangeRateInfo: {
    pair: string
    exchangeRate: BigNumber
    priceImpact: BigNumber
    route: string[]
  }
  txnGasCost: {
    amount: BigNumber
    valueUSD: BigNumber | null // amount * ethPriceUSD
  }
  error: string | null
  swapType: SWAP_TYPES
  fromState: { symbol: string; value: string; valueUSD: BigNumber }
  toState: { symbol: string; value: string; valueUSD: BigNumber }
  pendingSwaps: PendingSwap[]
  onChangeFromToken: (tokenSymbol: string) => void
  onChangeFromAmount: (amount: string) => void
  onChangeToToken: (tokenSymbol: string) => void
  onConfirmTransaction: () => Promise<void>
  onClickReverseExchangeDirection: () => void
}

const SwapPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const {
    tokenOptions,
    exchangeRateInfo,
    txnGasCost,
    error,
    fromState,
    toState,
    pendingSwaps,
    swapType,
    onChangeFromToken,
    onChangeFromAmount,
    onChangeToToken,
    onConfirmTransaction,
    onClickReverseExchangeDirection,
  } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [activePendingSwap, setActivePendingSwap] = useState<string | null>(
    null,
  )
  const slippageCustom = useSelector((state: AppState) => state.user.slippageCustom, shallowEqual)
  const slippageSelected = useSelector((state: AppState) => state.user.slippageSelected)

  const fromToken = useMemo(() => {
    return tokenOptions.from.find(({ symbol }) => symbol === fromState.symbol)
  }, [tokenOptions.from, fromState.symbol])

  const toToken = useMemo(() => {
    return tokenOptions.to.find(({ symbol }) => symbol === toState.symbol)
  }, [tokenOptions.to, toState.symbol])

  const formattedPriceImpact = commify(
    formatBNToPercentString(exchangeRateInfo.priceImpact, 18),
  )
  const formattedLiquidityProviderFee = commify(
    (parseFloat((
        (parseFloat(toState.value) * LIQUIDITY_PROVIDER_FEE) / (1 - LIQUIDITY_PROVIDER_FEE)
    ).toFixed(6))).toString()
  )

  const formattedFeeRate = (LIQUIDITY_PROVIDER_FEE * 100).toFixed(2).toString()

  const formattedExchangeRate = commify(
    formatBNToString(exchangeRateInfo.exchangeRate, 18, 6),
  )
  const alteredRoute  = exchangeRateInfo.route.map((symbol) => (getTokenSymbolToShow(symbol)))// alter WxUSD token symbol shown in route on Swap page
  const formattedRoute = alteredRoute.join(" > ")
  const formattedBalanceFrom = commify(
    formatBNToString(fromToken?.amount || Zero, fromToken?.decimals || 0, 6),
  )
  const formattedBalanceTo = commify(
    formatBNToString(toToken?.amount || Zero, toToken?.decimals || 0, 6),
  )
  const isVirtualSwap = getIsVirtualSwap(swapType)
  const isHighSlippage =
    slippageSelected === Slippages.OneTenth ||
    (slippageSelected === Slippages.Custom &&
      parseFloat(slippageCustom?.valueRaw || "0") < 0.5)
  const tooltipContent = (fromState.symbol?.length && !(BASE_POOL_TOKENS_SYMBOLS.includes(fromState.symbol))) || (toState.symbol?.length && 
    !(BASE_POOL_TOKENS_SYMBOLS.includes(toState.symbol))) ? "liquidityProviderFeeMetaPool" : "liquidityProviderFeeBasePool"

  return (
    <div className="swapPage">
      <TopMenu activeTab={"swap"} />
      <div className="container">
        <div className="contentInnerWrapper">
          <UnsupportedNetwork chainId={chainId} />
          <h1 className="title">{t("swap")}</h1>
          <h3 className="desc">{t("swapDesc")}</h3>
          <div className="swapForm">
            <div className="row">
              <h3 className="swapTitle">{t("from")}</h3>
              <div className="balanceContainer truncate">
                <span>{t("balance")}:</span>
                &nbsp;
                <a
                  className="balance"
                  onClick={() => {
                    if (fromToken == null) return
                    const amtStr = formatBNToString(
                      fromToken.amount,
                      fromToken.decimals || 0,
                    )
                    onChangeFromAmount(amtStr)
                  }}
                >
                  {formattedBalanceFrom}
                </a>
              </div>
            </div>
            <div className={classNames("row", chainId && !isNetworkSupported(chainId) && "disabledInput")}>
              <SwapInput
                tokens={tokenOptions.from.filter(
                  ({ symbol }) => symbol !== toState.symbol,
                )}
                onSelect={onChangeFromToken}
                onChangeAmount={onChangeFromAmount}
                selected={fromState.symbol}
                inputValue={fromState.value}
                inputValueUSD={fromState.valueUSD}
                isSwapFrom={true}
                isError={!!error}
              />
            </div>
            <div
              className="free-space"
              style={{ height: "63px", position: "relative" }}
            >
              <svg
                viewBox="0 0 12 22"
                className="arr-download"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="arr-1"
                  opacity="0"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 16.334L4.24264 20.5766L5.65685 21.9908L7.07107 20.5766L11.3137 16.334H8.48528L5.65685 19.1624L2.82843 16.334H0Z"
                  fill="#43B446"
                >
                  <animate
                    attributeName="opacity"
                    from="0"
                    to="1"
                    dur="0.6s"
                    repeatCount="indefinite"
                    calcMode="linear"
                    begin="0.3s"
                  />
                </path>
                <path
                  id="arr-2"
                  opacity="0"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 8.50684L4.24264 12.7495L5.65685 14.1637L7.07107 12.7495L11.3137 8.50684H8.48528L5.65685 11.3353L2.82843 8.50684H0Z"
                  fill="#43B446"
                >
                  <animate
                    attributeName="opacity"
                    from="0"
                    to="1"
                    dur="0.6s"
                    repeatCount="indefinite"
                    calcMode="linear"
                    begin="0.2s"
                  />
                </path>
                <path
                  id="arr-3"
                  opacity="0"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 0.679688L4.24264 4.92233L5.65685 6.33654L7.07107 4.92233L11.3137 0.679688H8.48528L5.65685 3.50811L2.82843 0.679688H0Z"
                  fill="#43B446"
                >
                  <animate
                    attributeName="opacity"
                    from="0"
                    to="1"
                    dur="0.6s"
                    repeatCount="indefinite"
                    calcMode="linear"
                    begin="0.1s"
                  />
                </path>
              </svg>
            </div>
            <div className="row">
              <h3 className="swapTitle">{t("to")}</h3>
              <div className="balanceContainer truncate">
                <span>{t("balance")}:</span>
                &nbsp;
                <a className="balance no-pointer">{formattedBalanceTo}</a>
              </div>
            </div>
            <div className={classNames("row", "to-swap-input", chainId && !isNetworkSupported(chainId) && "disabledInput")}>
              <SwapInput
                tokens={tokenOptions.to.filter(
                  ({ symbol }) => symbol !== fromState.symbol,
                )}
                onSelect={onChangeToToken}
                selected={toState.symbol}
                inputValue={toState.value}
                inputValueUSD={toState.valueUSD}
                isSwapFrom={false}
              />
            </div>
            {fromState.symbol && toState.symbol && (
              <div className="row">
                <div className="description">
                  <span className="label">{t("rate")}</span>
                  &nbsp;
                  <span className="label">{exchangeRateInfo.pair}</span>
                  <span
                    className="exchange"
                    onClick={onClickReverseExchangeDirection}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.47826 8.67081H21.227V10.1615H2L7.99034 4L9.05314 5.09317L5.47826 8.67081ZM2.77295 15.3292V13.8385H22L16.0097 20L14.9469 18.9068L18.5217 15.3292H2.77295Z"
                        fill="#43B446"
                      />
                    </svg>
                  </span>
                </div>
                <div className="description">
                  <span className="value">{formattedExchangeRate}</span>
                </div>
              </div>
            )}
            <div className="row">
              <div className="description">
                <span className="label">{t("priceImpact")}</span>
              </div>
              <div className="description">
                <span className="value">{formattedPriceImpact}</span>
              </div>
            </div>
            <div className="row">
              <div className="description">
                <ToolTip content={t(tooltipContent)}>
                  <span className="label">
                    {t("liquidityProviderFee")}
                    <img src={iInformation} alt="information" />
                  </span>
                </ToolTip>
              </div>
              <div className="description">
                {fromState.symbol?.length &&
                  BASE_POOL_TOKENS_SYMBOLS.includes(fromState.symbol) && BASE_POOL_TOKENS_SYMBOLS.includes(toState.symbol) ?
                    (
                      <span className="value">
                        {formattedLiquidityProviderFee}
                        {" "}
                        {toState.symbol}
                      </span>
                    ) : 
                    (
                      <span className="value">
                        {formattedFeeRate}
                        {" %"}
                      </span>
                    )
                }
              </div>
            </div>
            {formattedRoute && (
              <>
                <div className="row">
                  <div className="description">
                    <span className="label">{t("route")}</span>
                  </div>
                  <div className="description">
                    <span className="value">{formattedRoute}</span>
                  </div>
                </div>
                {isVirtualSwap && (
                  <div className="row">
                    <span></span>
                    <span className="virtual-swap-link">
                      {t("virtualSwap")}
                    </span>
                  </div>
                )}
                {isVirtualSwap && isHighSlippage && (
                  <div className="exchangeWarning">
                    {t("lowSlippageVirtualSwapWarning")}
                  </div>
                )}
              </>
            )}
          </div>
          {account && isHighPriceImpact(exchangeRateInfo.priceImpact) ? (
            <div className="exchangeWarning">
              {t("highPriceImpact", {
                rate: formattedPriceImpact,
              })}
            </div>
          ) : null}
          {isVirtualSwap && (
            <div className="virtualSwapInfoBubble">
              <InfoIcon />
              {t("crossAssetSwapsUseVirtualSwaps")}
              &nbsp;
              {/* <a
                href="https://docs.saddle.finance/saddle-faq#what-is-virtual-swap"
                target="_blank"
                className="virtual-swap-link"
                rel="noreferrer"
              > */}
                {t("learnMore")}
              {/* </a> */}
            </div>
          )}
          <AdvancedOptions />
          <div className="pendingSwaps">
            {pendingSwaps.map((pendingSwap) => {
              const formattedSynthBalance = commify(
                formatUnits(
                  pendingSwap.synthBalance,
                  pendingSwap.synthTokenFrom.decimals,
                ),
              )
              return (
                <div
                  className="pendingSwapItem"
                  key={pendingSwap.itemId?.toString()}
                  onClick={() => {
                    setActivePendingSwap(pendingSwap.itemId)
                    setCurrentModal("pendingSwap")
                  }}
                >
                  <span className="swapDetails">
                    {formattedSynthBalance} {pendingSwap.synthTokenFrom.symbol}{" "}
                    {"->"} {pendingSwap.tokenTo.symbol}
                  </span>
                  <div className="swapTimeContainer">
                    <svg
                      width="11"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.23467 1H5.5C7.98605 1 10 3.01525 10 5.49924C10 7.98311 7.98618 10 5.5 10C3.01388 10 1 7.98469 1 5.49924C1 4.30732 1.46423 3.22282 2.21973 2.41912L2.60641 2.78249C1.93974 3.49169 1.53066 4.4476 1.53066 5.49924C1.53066 7.69191 3.30721 9.46943 5.5 9.46943C7.69273 9.46943 9.46934 7.69046 9.46934 5.49924C9.46934 3.39724 7.83438 1.67581 5.76533 1.5393V2.96008H5.23467V1Z"
                        fill="black"
                        stroke="black"
                        strokeWidth="0.3"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M5.76204 5.52774L5.76861 5.53328L5.77577 5.53804C5.82206 5.5688 5.85082 5.61957 5.84998 5.67802L5.84997 5.67802V5.68017C5.84997 5.77327 5.77431 5.85 5.67911 5.85C5.62153 5.85 5.56861 5.81994 5.53676 5.77321L5.53241 5.76682L5.52742 5.76091L4.26017 4.26001L5.76204 5.52774Z"
                        fill="black"
                        stroke="black"
                        strokeWidth="0.3"
                      />
                    </svg>
                    <span className="swapTime">
                      {Math.ceil(pendingSwap.secondsRemaining / 60)} min.
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="mainActionBtn">
            <Button
              kind="primary"
              size="extra-large"
              onClick={(): void => {
                setCurrentModal("review")
              }}
              disabled={!!error || +toState.value <= 0}
            >
              <span>{t("swap")}</span>
            </Button>
          </div>
          <div className={classNames({ showError: !!error }, "error")}>
            {error}
          </div>
          <Modal
            isOpen={!!currentModal}
            onClose={(): void => setCurrentModal(null)}
          >
            {currentModal === "review" ? (
              <ReviewSwap
                onClose={(): void => setCurrentModal(null)}
                onConfirm={async (): Promise<void> => {
                  setCurrentModal("confirm")
                  logEvent("swap", {
                    from: fromState.symbol,
                    to: toState.symbol,
                  })
                  await onConfirmTransaction?.()
                  setCurrentModal(null)
                }}
                data={{
                  from: fromState,
                  to: toState,
                  exchangeRateInfo,
                  txnGasCost,
                  swapType,
                }}
              />
            ) : null}
            {currentModal === "confirm" ? <ConfirmTransaction /> : null}
            {currentModal === "pendingSwap" ? (
              <PendingSwapModal
                pendingSwap={
                  pendingSwaps.find(
                    (p) => p.itemId === activePendingSwap,
                  ) as PendingSwap
                }
                onClose={() => {
                  setCurrentModal(null)
                  setActivePendingSwap(null)
                }}
              />
            ) : null}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default SwapPage
