import { ChainId } from "./types/ChainId";
import { ENV } from "./ENV";
import { Pool } from "./types/Pool";
import { STABLECOIN_CROSS_POOL_NAME } from "./types/PoolName";
import { buildAddresses } from "./buildAddresses";
import { Token } from "./types/Token";
import daiLogo from "../../assets/icons/dai.svg"
import usdcLogo from "../../assets/icons/usdc.svg"
import usdtLogo from "../../assets/icons/usdt.svg"
import xUsdLPTokenLogo from "../../assets/icons/xusd-logo-ellipse-transparent-260x260.svg"
import { PoolTypes } from "./types/PoolTypes";
import { buildPids } from "./buildPids";

export const STABLECOIN_SWAP_V3_ADDRESSES = (): Record<ChainId, string> => {
  switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
        [ChainId.POLYGON]: "0x5F0C124F83602d32926aB75BD87C048fFb5f824c",
        [ChainId.CRONOS]: "0xcbA78dcC46fA7c90c8e7058e2109165d23f10BEb",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0x43F3671b099b78D26387CD75283b826FD9585B60",
      })
  }
}

export const STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES = (): Record<
  ChainId,
  string
> => {
  switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
        [ChainId.POLYGON]: "0xd005dc28e6b212Fff9e4b32F02cF26E1A5D5c05a",
        [ChainId.CRONOS]: "0x296a405716d30D68eAa80dC7d12bBc275da3354e",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139",
      })
  }
}

const USDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  [ChainId.ROPSTEN]: "0x0593d1b92e8Ba6bBC428923245891efF0311Fa15",
  [ChainId.HARDHAT]: "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
  [ChainId.ARBITRUM]: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
  [ChainId.POLYGON]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  [ChainId.CRONOS]: "0x66e428c3f67a68878562e79A0234c1F83c208770",
})
const USDT = new Token(
  USDT_CONTRACT_ADDRESSES,
  6,
  "USDT",
  "tether",
  "Tether",
  usdtLogo,
)

export const STABLECOIN_SWAP_V3_TOKEN = new Token(
  STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES(),
  18,
  "xUSD",
  "xusd",
  "Cross USD Stablecoin",
  xUsdLPTokenLogo,
  false,
  true,
  false,
  false
)

const DAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  [ChainId.ROPSTEN]: "0x8C924e41d0624Ae6E7DB09fc93BBfB324c31BE0C",
  [ChainId.HARDHAT]: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
  [ChainId.POLYGON]: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  [ChainId.CRONOS]: "0xF2001B145b43032AAF5Ee2884e456CCd805F677D",
})

const DAI = new Token(
  DAI_CONTRACT_ADDRESSES,
  18,
  "DAI",
  "dai",
  "Dai",
  daiLogo,
)

const USDC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [ChainId.ROPSTEN]: "0xA4fe4981f7550884E7E6224F0c78245DC145b2F2",
  [ChainId.HARDHAT]: "0x9A676e781A523b5d0C0e43731313A708CB607508",
  [ChainId.ARBITRUM]: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
  [ChainId.POLYGON]: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  [ChainId.CRONOS]: "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59",
})

export const USDC = new Token(
  USDC_CONTRACT_ADDRESSES,
  6,
  "USDC",
  "usd-coin",
  "USDC Coin",
  usdcLogo,
)

export const STABLECOIN_POOL_TOKENS = [DAI, USDC, USDT]

export const STABLECOIN_CROSS_POOL: Pool = {
    name: STABLECOIN_CROSS_POOL_NAME,
    addresses: STABLECOIN_SWAP_V3_ADDRESSES(),
    lpToken: STABLECOIN_SWAP_V3_TOKEN,
    poolTokens: STABLECOIN_POOL_TOKENS,
    isSynthetic: false,
    type: PoolTypes.USD,
    route: "usdv3",
    rewardPids: buildPids({
      [ChainId.MAINNET]: 3,
      [ChainId.HARDHAT]: 3,
      [ChainId.POLYGON]: 1,
      [ChainId.CRONOS]: 1,
    }),
  }

export const BASE_POOL_TOKENS = STABLECOIN_POOL_TOKENS
export const BASE_POOL_TOKENS_SYMBOLS = BASE_POOL_TOKENS.map((token) => token.symbol)
