import "./ReviewSwap.scss"

import React, { ReactElement, useState } from "react"
import { SWAP_TYPES, getIsVirtualSwap } from "../constants"
import { TOKENS_MAP } from "../constants/pools/TokensMap"
import {
  // commify,
  formatBNToString,
  formatDeadlineToNumber,
} from "../utils"

import { AppState } from "../state/index"
import { BigNumber } from "@ethersproject/bignumber"
import Button from "./Button"
import HighPriceImpactConfirmation from "./HighPriceImpactConfirmation"
import { ReactComponent as ThinArrowDown } from "../assets/icons/thinArrowDown.svg"
import classnames from "classnames"
// import { formatGasToString } from "../utils/gas"
import { formatSlippageToString } from "../utils/slippage"
import { isHighPriceImpact } from "../utils/priceImpact"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

interface Props {
  onClose: () => void
  onConfirm: () => void
  data: {
    from: { symbol: string; value: string }
    to: { symbol: string; value: string }
    exchangeRateInfo: {
      pair: string
      priceImpact: BigNumber
      exchangeRate: BigNumber
      route: string[]
    }
    swapType: SWAP_TYPES
    txnGasCost: {
      amount: BigNumber
      valueUSD: BigNumber | null // amount * ethPriceUSD
    }
  }
}

function ReviewSwap({ onClose, onConfirm, data }: Props): ReactElement {
  const { t } = useTranslation()
  const slippageCustom = useSelector((state: AppState) => state.user.slippageCustom, shallowEqual)
  const slippageSelected = useSelector((state: AppState) => state.user.slippageSelected)
  const transactionDeadlineSelected = useSelector((state: AppState) => state.user.transactionDeadlineSelected)
  const transactionDeadlineCustom = useSelector((state: AppState) => state.user.transactionDeadlineCustom)
  // const gasCustom = useSelector((state: AppState) => state.user.gasCustom, shallowEqual)
  // const gasPriceSelected = useSelector((state: AppState) => state.user.gasPriceSelected)

  // const gasStandard = useSelector((state: AppState) => state.application.gasStandard)
  // const gasFast = useSelector((state: AppState) => state.application.gasFast)
  // const gasInstant = useSelector((state: AppState) => state.application.gasInstant)
  const [
    hasConfirmedHighPriceImpact,
    setHasConfirmedHighPriceImpact,
  ] = useState(false)
  const isHighPriceImpactTxn = isHighPriceImpact(
    data.exchangeRateInfo.priceImpact,
  )
  const isVirtualSwap = getIsVirtualSwap(data.swapType)
  const deadline = formatDeadlineToNumber(
    transactionDeadlineSelected,
    transactionDeadlineCustom,
  )

  return (
    <div className="reviewSwap">
      <h3>{t("reviewSwap")}</h3>
      <div className="swapTable">
        {isVirtualSwap ? (
          <VirtualSwapTokens data={data} />
        ) : (
          <DirectSwapTokens data={data} />
        )}
        {data.swapType === SWAP_TYPES.SYNTH_TO_SYNTH && (
          <div className="row">
            <span className="aside">
              {t("virtualSwapSynthToSynthInfo")}{" "}
              <a href="https://blog.synthetix.io/how-fee-reclamation-rebates-work/">
                {t("learnMore")}
              </a>
            </span>
          </div>
        )}
        <div className="divider" style={{ height: "1px", width: "100%" }} />
        <div className="swapInfo">
          <div className="priceTable">
            <span className="label-s">
              <span className="title">{t("price")} </span>
              <span className="pair">{data.exchangeRateInfo.pair}</span>
              {/* <button className="exchange">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.47826 8.67081H21.227V10.1615H2L7.99034 4L9.05314 5.09317L5.47826 8.67081ZM2.77295 15.3292V13.8385H22L16.0097 20L14.9469 18.9068L18.5217 15.3292H2.77295Z"
                    fill="#43B446"
                  />
                </svg>
              </button> */}
            </span>
            <span className="value floatRight">
              {formatBNToString(data.exchangeRateInfo.exchangeRate, 18, 6)}
            </span>
          </div>
          {/* <div className="row">
            <span className="title">{t("gas")}</span>
            <span className="value floatRight">
              {formatGasToString(
                { gasStandard, gasFast, gasInstant },
                gasPriceSelected,
                gasCustom,
              )}{" "}
              GWEI
            </span>
          </div> */}
          {/* {data.txnGasCost?.valueUSD && (
            <div className="row">
              <span className="title">{t("estimatedTxCost")}</span>
              <span className="value floatRight">
                {`≈$${commify(
                  formatBNToString(data.txnGasCost.valueUSD, 2, 2),
                )}`}
              </span>
            </div>
          )} */}
          <div className="row">
            <span className="title">{t("maxSlippage")}</span>
            <span className="value floatRight">
              {formatSlippageToString(slippageSelected, slippageCustom)}%
            </span>
          </div>
          {!isVirtualSwap && (
            <div className="row">
              <span className="title">{t("deadline")}</span>
              <span className="value floatRight gap-4">
                <span>{deadline}</span>
                <span>{t("minutes")}</span>
              </span>
            </div>
          )}
          {isHighPriceImpactTxn && (
            <div className="row">
              <HighPriceImpactConfirmation
                checked={hasConfirmedHighPriceImpact}
                onCheck={(): void =>
                  setHasConfirmedHighPriceImpact((prevState) => !prevState)
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="bottom">
        <p>{t("estimatedOutput")}</p>
        <div className="buttonWrapper">
          <Button
            onClick={onConfirm}
            kind="primary"
            size="extra-large"
            disabled={isHighPriceImpactTxn && !hasConfirmedHighPriceImpact}
          >
            {t("confirmSwap")}
          </Button>
          <Button onClick={onClose} kind="cancel" size="extra-large">
            {t("cancel")}
          </Button>
        </div>
      </div>
    </div>
  )
}

function DirectSwapTokens({ data }: { data: Props["data"] }) {
  const fromToken = TOKENS_MAP[data.from.symbol]
  const toToken = TOKENS_MAP[data.to.symbol]
  return (
    <>
      <div className="row">
        <div className="tokenIconWrapper">
          <img className="tokenIcon" src={fromToken.icon} alt="icon" />
          <span className="tokenName">{data.from.symbol}</span>
        </div>
        <div className="tokenValueWrapper">
          <span>{data.from.value}</span>
        </div>
      </div>
      <div className="free-space">
        <svg
          viewBox="0 0 12 22"
          className="arr-download"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="arr-1"
            opacity="0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16.334L4.24264 20.5766L5.65685 21.9908L7.07107 20.5766L11.3137 16.334H8.48528L5.65685 19.1624L2.82843 16.334H0Z"
            fill="#43B446"
          >
            <animate
              attributeName="opacity"
              from="0"
              to="1"
              dur="0.6s"
              repeatCount="indefinite"
              calcMode="linear"
              begin="0.3s"
            />
          </path>
          <path
            id="arr-2"
            opacity="0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8.50684L4.24264 12.7495L5.65685 14.1637L7.07107 12.7495L11.3137 8.50684H8.48528L5.65685 11.3353L2.82843 8.50684H0Z"
            fill="#43B446"
          >
            <animate
              attributeName="opacity"
              from="0"
              to="1"
              dur="0.6s"
              repeatCount="indefinite"
              calcMode="linear"
              begin="0.2s"
            />
          </path>
          <path
            id="arr-3"
            opacity="0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.679688L4.24264 4.92233L5.65685 6.33654L7.07107 4.92233L11.3137 0.679688H8.48528L5.65685 3.50811L2.82843 0.679688H0Z"
            fill="#43B446"
          >
            <animate
              attributeName="opacity"
              from="0"
              to="1"
              dur="0.6s"
              repeatCount="indefinite"
              calcMode="linear"
              begin="0.1s"
            />
          </path>
        </svg>
      </div>
      <div className="row">
        <div className="tokenIconWrapper">
          <img className="tokenIcon" src={toToken.icon} alt="icon" />
          <span className="tokenName">{data.to.symbol}</span>
        </div>
        <div className="tokenValueWrapper">
          <span>{data.to.value}</span>
        </div>
      </div>
    </>
  )
}

function VirtualSwapTokens({ data }: { data: Props["data"] }) {
  const { t } = useTranslation()

  return (
    <>
      {data.exchangeRateInfo.route.map((symbol, i) => {
        const isFirst = i === 0
        const isLast = i === data.exchangeRateInfo.route.length - 1
        const token = TOKENS_MAP[symbol]
        return (
          <div className="row" key={symbol}>
            <div>
              {!isFirst && !isLast && <ThinArrowDown className="stepArrow" />}
              <img className="tokenIcon" src={token.icon} alt="icon" />
              <span className={classnames("tokenName", { grey: isLast })}>
                {token.symbol}
              </span>

              {(isFirst || isLast) && (
                <span className="aside">
                  {" "}
                  (
                  {t("stepN", {
                    step: isFirst ? 1 : 2,
                  })}
                  )
                </span>
              )}
            </div>
            <div>
              {isFirst && <span>{data.from.value}</span>}
              {isLast && <span className="grey">{data.to.value}</span>}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ReviewSwap
