import { buildAddresses } from "../pools/buildAddresses";
import { buildPids } from "../pools/buildPids";
import { ENV } from "../pools/ENV";
import { ChainId } from "../pools/types/ChainId";
import { Token } from "../pools/types/Token";
import { Farm } from "./FarmsMap";
import crossLPTokenLogo from "../../assets/icons/logo-ellipse-transparent-260x260.svg"


const AVTOCROSS_TOKEN_ADDRESSES = (): Record<
  ChainId,
  string
  > => {
  switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
        [ChainId.HARDHAT]: "0x04C89607413713Ec9775E14b954286519d836FEf",
        [ChainId.MAINNET]: "0xf1Dc500FdE233A4055e25e5BbF516372BC4F6871",
        [ChainId.ARBITRUM]: "0x75c9bc761d88f70156daf83aa010e84680baf131",
        [ChainId.POLYGON]: "0x6eFDde7924D105E7A6Fdc2B10948E462b3d7dcfb",
        [ChainId.CRONOS]: "0x9e5bDB9Cbd2789b87e3E676f1f414FA9B79df026",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0x6ef20ca7e493c52095e892dab78a7fd0e7e2a279",
      })
  }
}

export const AVTOCROSS_TOKEN = new Token(
  AVTOCROSS_TOKEN_ADDRESSES(),
  18,
  "CROSS",
  "cronos-stable-swap", // TODO change to actual
  "CROSS",
  crossLPTokenLogo,
  false,
  false,
  false,
  true
)

export const CROSS_FARM_TOKENS = [AVTOCROSS_TOKEN]

export const crossStaking: Farm = {
  name: "CROSS Staking",
  chainId: ChainId.CRONOS,
  lpToken: AVTOCROSS_TOKEN,
  rewardPids: buildPids({
    [ChainId.CRONOS]: 0,
  }),
  poolTokens: CROSS_FARM_TOKENS,
}
