import { BUSD_METASWAP_POOL } from "./BUSDMetaswapPool"
import { MAI_METASWAP_POOL } from "./MAIMetaswapPool"
// import { DNA_METASWAP_POOL } from "./DNAMetaswapPool"
import { TUSD_METASWAP_POOL } from "./TUSDMetaswapPool"
import { STABLECOIN_CROSS_POOL } from "./StableCoinCrossPool"
import { Pool } from "./types/Pool"
import {
  BUSD_METASWAP_POOL_NAME,
  MAI_METASWAP_POOL_NAME,
  //  DNA_METASWAP_POOL_NAME,
  TUSD_METASWAP_POOL_NAME,
  STABLECOIN_CROSS_POOL_NAME,
  WxUSD_METASWAP_POOL_NAME,
  PoolName
} from "./types/PoolName"
import { WxUSD_METASWAP_POOL } from "./WxUSDMetaswapPool"

export type PoolsMap = {
  [poolName in PoolName]: Pool
}

export const POOLS_MAP: PoolsMap = {
  [STABLECOIN_CROSS_POOL_NAME]: STABLECOIN_CROSS_POOL,
  [BUSD_METASWAP_POOL_NAME]: BUSD_METASWAP_POOL,
  [MAI_METASWAP_POOL_NAME]: MAI_METASWAP_POOL,
  //  [DNA_METASWAP_POOL_NAME]: DNA_METASWAP_POOL, 
  [WxUSD_METASWAP_POOL_NAME]: WxUSD_METASWAP_POOL,
  [TUSD_METASWAP_POOL_NAME]: TUSD_METASWAP_POOL,
}

export const allPoolNames = Object.keys(POOLS_MAP) as PoolName[]

export function isMetaPool(poolName = ""): boolean {
  return new Set([
    BUSD_METASWAP_POOL_NAME,
    MAI_METASWAP_POOL_NAME,
    // DNA_METASWAP_POOL_NAME,
    WxUSD_METASWAP_POOL_NAME,
    TUSD_METASWAP_POOL_NAME
  ]).has(poolName)
}