import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { SwapStatsReponse } from "../utils/getSwapStats"
import { AvtoCrossStatsResponse } from "../utils/getAvtoCrossStats"
import { shallowEqual } from "react-redux"

interface GasPrices {
  gasStandard?: number
  gasFast?: number
  gasInstant?: number
}
interface SwapStats {
  [swapAddress: string]: {
    oneDayVolume: string
    apy: string
    tvl: string
    utilization: string
  }
}
export interface TokenPricesUSD {
  [tokenSymbol: string]: number
}
interface LastTransactionTimes {
  [transactionType: string]: number
}

export interface AvtoCrossTokenStats {
  annualRewardAmount: string,
  chefAllocPoint: number,
  lockChefAllocPoint: number,
  totalAllocPoint: number,
  emissionAlreadyMinted: string,
}

type ApplicationState = GasPrices & { tokenPricesUSD: TokenPricesUSD } & {
  lastTransactionTimes: LastTransactionTimes
} & { swapStats?: SwapStats } & { avtoCrossTokenStats: AvtoCrossTokenStats}

const initialState: ApplicationState = {
  lastTransactionTimes: {},
  avtoCrossTokenStats: {
    annualRewardAmount: "0",
    chefAllocPoint: 0,
    lockChefAllocPoint: 0,
    totalAllocPoint: 0,
    emissionAlreadyMinted: "",
  },
  tokenPricesUSD: {}
}

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateGasPrices(state, action: PayloadAction<GasPrices>): void {
      const { gasStandard, gasFast, gasInstant } = action.payload
      state.gasStandard = gasStandard
      state.gasFast = gasFast
      state.gasInstant = gasInstant
    },
    updateTokensPricesUSD(state, action: PayloadAction<TokenPricesUSD>): void {
      const newUSDPrices = action.payload
      const prevUSDPrices = state.tokenPricesUSD
      const arePricesEqual = shallowEqual(prevUSDPrices, newUSDPrices)
      if (arePricesEqual === false) {
         state.tokenPricesUSD = newUSDPrices
      }
    },
    updateLastTransactionTimes(
      state,
      action: PayloadAction<LastTransactionTimes>,
    ): void {
      state.lastTransactionTimes = {
        ...state.lastTransactionTimes,
        ...action.payload,
      }
    },
    updateSwapStats(state, action: PayloadAction<SwapStatsReponse>): void {
      state.swapStats = Object.keys(action.payload).reduce(
        (acc, key) => {
          const { APY, TVL, oneDayVolume: ODV } = action.payload[key]
          if (isNaN(APY) || isNaN(TVL) || isNaN(ODV)) {
            return acc
          }
          const apy = APY.toFixed(18)
          const tvl = TVL.toFixed(18)
          const oneDayVolume = ODV.toFixed(18)
          const utilization = (TVL > 0 ? ODV / TVL : 0).toFixed(18)
          return {
            ...acc,
            [key]: {
              apy,
              tvl,
              oneDayVolume,
              utilization,
            },
          }
        },
        {},
      )
    },
    updateAvtoCrossTokenStats(state, action: PayloadAction<AvtoCrossStatsResponse>): void {
      state.avtoCrossTokenStats = {
        annualRewardAmount: action.payload.annualRewardAmount,
        chefAllocPoint: action.payload.chefAllocPoint,
        lockChefAllocPoint: action.payload.lockChefAllocPoint,
        totalAllocPoint: action.payload.chefAllocPoint + action.payload.lockChefAllocPoint,
        emissionAlreadyMinted: action.payload.emissionAlreadyMinted
      }
    },
  },
})

export const {
  updateGasPrices,
  updateTokensPricesUSD,
  updateLastTransactionTimes,
  updateSwapStats,
  updateAvtoCrossTokenStats
} = applicationSlice.actions

export default applicationSlice.reducer
