import "./AdvancedOptions.scss"

import { Deadlines, GasPrices, Slippages } from "../state/user"
import React, { ReactElement } from "react"
import {
  updateGasPriceCustom,
  updateGasPriceSelected,
  updateInfiniteApproval,
  updatePoolAdvancedMode,
  updateSlippageCustom,
  updateSlippageSelected,
  updateTransactionDeadlineCustom,
  updateTransactionDeadlineSelected,
} from "../state/user"
import { shallowEqual, useDispatch, useSelector } from "react-redux"

import { AppDispatch } from "../state"
import { AppState } from "../state/index"
import CheckboxInput from "./CheckboxInput"
import { PayloadAction } from "@reduxjs/toolkit"
import ToolTip from "./ToolTip"
import classNames from "classnames"
import iCarretDown from "../assets/img/chevron-down.svg"
import { useTranslation } from "react-i18next"
import iInformation from "../assets/img/i-information.svg"

export default function AdvancedOptions(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const infiniteApproval = useSelector((state: AppState) => state.user.infiniteApproval)
  const slippageCustom = useSelector((state: AppState) => state.user.slippageCustom, shallowEqual)
  const slippageSelected = useSelector((state: AppState) => state.user.slippageSelected)
  const transactionDeadlineSelected = useSelector((state: AppState) => state.user.transactionDeadlineSelected)
  const transactionDeadlineCustom = useSelector((state: AppState) => state.user.transactionDeadlineCustom)
  const gasCustom = useSelector((state: AppState) => state.user.gasCustom, shallowEqual)
  const gasPriceSelected = useSelector((state: AppState) => state.user.gasPriceSelected)
  const userPoolAdvancedMode = useSelector((state: AppState) => state.user.userPoolAdvancedMode)
  const advanced = userPoolAdvancedMode

  const gasStandard = useSelector((state: AppState) => state.application.gasStandard)
  const gasFast = useSelector((state: AppState) => state.application.gasFast)
  const gasInstant = useSelector((state: AppState) => state.application.gasInstant)

  return (
    <div className="advancedOptions">
      <div className="titleWrapper">
        <span
          className="title-b"
          onClick={(): PayloadAction<boolean> =>
            dispatch(updatePoolAdvancedMode(!advanced))
          }
        >
          {t("advancedOptions")}
          {advanced ? (
            <img style={{ transform: "rotateX(180deg)" }} src={iCarretDown} />
          ) : (
            <img src={iCarretDown} />
          )}
        </span>
      </div>
      <div
        className={classNames("tableContainer", {
          ["show"]: advanced,
        })}
      >
        <div className="parameterInfiniteApproval">
          <div className="infiniteApproval">
            <CheckboxInput
              checked={infiniteApproval}
              onChange={(): PayloadAction<boolean> =>
                dispatch(updateInfiniteApproval(!infiniteApproval))
              }
            />
            <ToolTip content={t("infiniteApprovalTooltip")}>
              <span className="label">
                {t("infiniteApproval")}
                <img src={iInformation} alt="information" />
              </span>
            </ToolTip>
          </div>
        </div>
        <div className={classNames("parameter", "underlined")}>
          <div className="inputGroup">
            <div className="options">
              <div className="label">{t("maxSlippage")}: </div>
              <button
                className={classNames({
                  ["selected"]: slippageSelected === Slippages.OneTenth,
                })}
                onClick={(): PayloadAction<Slippages> =>
                  dispatch(updateSlippageSelected(Slippages.OneTenth))
                }
              >
                <span>0.1 %</span>
              </button>
              <button
                className={classNames({
                  ["selected"]: slippageSelected === Slippages.One,
                })}
                onClick={(): PayloadAction<Slippages> =>
                  dispatch(updateSlippageSelected(Slippages.One))
                }
              >
                <span>1 %</span>
              </button>
              <div className="customValue">
                <input
                  value={slippageCustom?.valueRaw}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = e.target.value
                    if (value && !isNaN(+value)) {
                      dispatch(updateSlippageCustom(value))
                      if (slippageSelected !== Slippages.Custom) {
                        dispatch(updateSlippageSelected(Slippages.Custom))
                      }
                    } else {
                      dispatch(updateSlippageSelected(Slippages.OneTenth))
                    }
                  }}
                />
                &nbsp;<span>%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="parameter">
          <div className="inputGroup">
            <div className="options">
              <div className="label">{t("deadline")}: </div>
              <button
                className={classNames({
                  ["selected"]:
                    transactionDeadlineSelected === Deadlines.Twenty,
                })}
                onClick={(): void => {
                  dispatch(updateTransactionDeadlineSelected(Deadlines.Twenty))
                }}
              >
                <span>20 {t("minutes")}</span>
              </button>
              <button
                className={classNames({
                  ["selected"]:
                    transactionDeadlineSelected === Deadlines.Forty,
                })}
                onClick={(): void => {
                  dispatch(updateTransactionDeadlineSelected(Deadlines.Forty))
                }}
              >
                <span>40 {t("minutes")}</span>
              </button>
              <div className="customValue">
                <input
                  type="text"
                  className={classNames({
                    ["selected"]:
                      transactionDeadlineSelected === Deadlines.Custom,
                  })}
                  placeholder="20"
                  onClick={(): PayloadAction<Deadlines> =>
                    dispatch(
                      updateTransactionDeadlineSelected(Deadlines.Custom),
                    )
                  }
                  value={transactionDeadlineCustom}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = e.target.value
                    if (value && !isNaN(+value)) {
                      dispatch(updateTransactionDeadlineCustom(value))
                      if (transactionDeadlineSelected !== Deadlines.Custom) {
                        dispatch(
                          updateTransactionDeadlineSelected(Deadlines.Custom),
                        )
                      }
                    } else {
                      dispatch(
                        updateTransactionDeadlineSelected(Deadlines.Twenty),
                      )
                    }
                  }}
                />
                &nbsp;<span>{t("minutes")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="parameter" style={{ display: "none" }}>
          <div className="inputGroup">
            <div className="options">
              <div className="label">{t("gas")}:</div>
              {[GasPrices.Standard, GasPrices.Fast, GasPrices.Instant].map(
                (gasPriceConst) => {
                  let priceValue
                  let text
                  if (gasPriceConst === GasPrices.Standard) {
                    priceValue = gasStandard
                    text = t("standard")
                  } else if (gasPriceConst === GasPrices.Fast) {
                    priceValue = gasFast
                    text = t("fast")
                  } else {
                    priceValue = gasInstant
                    text = t("instant")
                  }

                  return (
                    <button
                      key={gasPriceConst}
                      className={classNames({
                        ["selected"]: gasPriceSelected === gasPriceConst,
                      })}
                      onClick={(): PayloadAction<GasPrices> =>
                        dispatch(updateGasPriceSelected(gasPriceConst))
                      }
                    >
                      <div>
                        <div>{priceValue}</div>
                        <div>{text}</div>
                      </div>
                    </button>
                  )
                },
              )}
              <div className="customValue">
                <input
                  type="text"
                  className={classNames({
                    ["selected"]: gasPriceSelected === GasPrices.Custom,
                  })}
                  value={gasCustom?.valueRaw}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = e.target.value
                    if (value && !isNaN(+value)) {
                      dispatch(updateGasPriceCustom(value))
                      if (gasPriceSelected !== GasPrices.Custom) {
                        dispatch(updateGasPriceSelected(GasPrices.Custom))
                      }
                    } else {
                      dispatch(updateGasPriceSelected(GasPrices.Fast))
                    }
                  }}
                />
                &nbsp;<span>Gwei</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
