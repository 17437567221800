import "./TopMenu.scss"

import React, { ReactElement, useCallback, useContext, useRef, useState } from "react"

import { IS_SDL_LIVE } from "../constants"
import { Link } from "react-router-dom"
import Modal from "./Modal"
import { RewardsBalancesContext } from "../providers/RewardsBalancesProvider"
import SiteSettingsMenu from "./SiteSettingsMenu"
import TokenClaimModal from "./TokenClaimModal"
import Web3Status from "./Web3Status"
import classNames from "classnames"
import { formatBNToShortString } from "../utils"
import tokenLogo from "../assets/img/logo-ellipse-transparent-260x260.svg"
import useDetectOutsideClick from "../hooks/useDetectOutsideClick"
import { useTranslation } from "react-i18next"

import settingsIcon from "../assets/img/settings.svg"
import menu from "../assets/img/menu.svg"
import close from "../assets/img/close.svg"
import larrow from "../assets/img/larrow.svg"

interface Props {
  activeTab: string
}

function TopMenu({ activeTab }: Props): ReactElement {
  const { t } = useTranslation()
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <>
    <header id="headerNav" className="top">
      <div className="container">
        <div className="nav-b">
          <div className="menu-btn">
            <img
              src={menu}
              alt="menu"
              onClick={() => switchHiddenMenu()}
            />
          </div>

          <div className="logoWrapper">
            <Link to="/">
              <div className="logo"></div>
            </Link>
          </div>

          <nav className="menu">
            <Link to="/swap" className={classNames({ active: activeTab === "swap" })}>
              {t("swap")}
            </Link>
            <Link
              to="/pools"
              className={classNames({
                active:
                  activeTab === "pools" ||
                  activeTab === "deposit" ||
                  activeTab === "withdraw",
              })}
            >
              {t("pools")}
            </Link>
            <Link
              to="/farm"
              className={classNames({
                active: activeTab === "farms",
              })}
            >
              {t("farms")}
            </Link>
          </nav>
          <div className="walletWrapper">
            <RewardsButton setCurrentModal={setCurrentModal} />
            <Web3Status />
            <IconButtonAndSettings />
          </div>
          <Modal
            isOpen={!!currentModal}
            onClose={(): void => setCurrentModal(null)}
          >
            {currentModal === "tokenClaim" && <TokenClaimModal />}
          </Modal>
        </div>
      </div>
    </header>
    <div id="hiddenMenu" className="hidden-menu">
      <div
        className="backdrop"
        onClick={() => switchHiddenMenu()}
      >
        <div
          className="menu-b"
          onClick={() => switchHiddenMenu()}
        >
          <div id="hiddenMenuList" className="menu-list">
            <Web3Status />
            <nav
              className="menu"
              onClick={() => switchHiddenMenu()}
            >
              <Link to="/swap" className={classNames({ active: activeTab === "swap" })}>
                {t("swap")}
              </Link>
              <Link
                to="/pools"
                className={classNames({
                  active:
                    activeTab === "pools" ||
                    activeTab === "deposit" ||
                    activeTab === "withdraw",
                })}
              >
                {t("pools")}
              </Link>
              <Link
                to="/farm"
                className={classNames({
                  active: activeTab === "farms",
                })}
              >
                {t("farms")}
              </Link>
            </nav>
            <div
              className="settings"
              onClick={() => switchHiddenPlatformSettings()}
            >
              Platform settings
            </div>
          </div>
          <div id="hiddenPlatformSettings" className="settings-list">
            <div className="header">
              <div className="larrow">
                <img
                  src={larrow}
                  alt="Go back"
                  onClick={() => switchHiddenPlatformSettings()}
                />
              </div>
              <div className="title">Platform settings</div>
            </div>
            <SiteSettingsMenu
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
              key="buttonSettings"
            />
          </div>
          <div
            className="close-menu-btn"
            onClick={() => {
              switchHiddenMenu()
              switchHiddenPlatformSettings(true)
            }}
          >
            <img src={close} alt="close"/>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

function RewardsButton({
  setCurrentModal,
}: {
  setCurrentModal: React.Dispatch<React.SetStateAction<string | null>>
}): ReactElement | null {
  const rewardBalances = useContext(RewardsBalancesContext)
  const formattedTotal = formatBNToShortString(rewardBalances.total, 18)
  return IS_SDL_LIVE ? (
    <div className="rewards">
			<div
        className="rewards-btn"
        onClick={() => setCurrentModal("tokenClaim")}
      >
				<img src={tokenLogo} alt="logo"/>
				<span className="value">{formattedTotal}</span>
			</div>
    </div>
  ) : null
}

function IconButtonAndSettings(): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const wrapperRef = useRef(null)
  const onDetectOutsideClick = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen])
  useDetectOutsideClick(
    wrapperRef,
    onDetectOutsideClick,
    isDropdownOpen,
  )

  return (
    <div className="settings" style={{ position: "relative" }} ref={wrapperRef}>
			<img
        src={settingsIcon}
        alt="settings"
        onClick={() => setIsDropdownOpen((state) => !state)}
      />
      {isDropdownOpen && (
        <div className="siteSettingsWrapper">
          <SiteSettingsMenu
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            key="buttonSettings"
          />
        </div>
      )}
    </div>
  )
}

function switchHiddenMenu() {
  const el = document.getElementById('hiddenMenu')
  if (el?.classList.contains('open')) {
    el.classList.remove('open')
    document.body.classList.remove('hiddenMenuIsOpen')
  } else {
    el?.classList.add('open')
    document.body.classList.add('hiddenMenuIsOpen')
  }
}

function switchHiddenPlatformSettings(clean = false) {
  const el1 = document.getElementById('hiddenMenuList')
  const el2 = document.getElementById('hiddenPlatformSettings')

  if (clean) {
    el1?.classList.remove('closed')
    el2?.classList.remove('open')
    return;
  }

  if (el2?.classList.contains('open')) {
    el1?.classList.remove('closed')
    el2.classList.remove('open')
  } else {
    el1?.classList.add('closed')
    el2?.classList.add('open')
  }
}

export default TopMenu
