import { useActiveWeb3React } from "./index"
import { useEffect, useMemo, useRef, useState } from "react"
import { BigNumber as BigNumberJs } from "bignumber.js"
import { BigNumber } from "@ethersproject/bignumber"

import { Farm } from "../constants/farms/FarmsMap"
import { Zero } from "@ethersproject/constants"
import { AvtoCrossTokenStats, TokenPricesUSD } from "../state/application"
import { MINICHEF_CONTRACT_ADDRESSES } from "../constants"
import { useMulticall, useRewardBalancesContracts, useRewardContract } from './useEthMultiCallContracts'
export interface PoolRewardStats {
  tvlInFarmToken?: BigNumber,
  poolId: number,
  crossPerDay: BigNumber,
  apr: string,
  tvl: BigNumber,
}

export default function useRewardsStats(
  farmsList: Farm[],
  avtoCrossTokenStats: AvtoCrossTokenStats,
  prices: TokenPricesUSD
): PoolRewardStats[] {
  const { annualRewardAmount, chefAllocPoint, totalAllocPoint: chefEmissionForkAllocPoint } = avtoCrossTokenStats;
  const { chainId } = useActiveWeb3React()
  const rewardsContractAddress = chainId ? MINICHEF_CONTRACT_ADDRESSES()[chainId] : undefined
  const [rewardsStats, setRewardsStats] = useState<PoolRewardStats[]>([])
  const multicall = useMulticall()
  const rewardsContract = useRewardContract()
  const rewardBalancesContracts = useRewardBalancesContracts(farmsList)

  const poolInfosReqs = useMemo(() => {
    if (!chainId || !rewardsContract) {
      return null
    }
    const poolInfosReqs = farmsList.map((farm, i) => {
      return {
        [i]: rewardsContract.methods.poolInfo(farm.rewardPids[chainId]),
      }
    })
    return poolInfosReqs
  }, [chainId, farmsList, rewardsContract])

  const rewardBalancesReqs = useMemo(() => {
    if (!rewardBalancesContracts) {
      return null
    }
    const rewardBalancesReqs = rewardBalancesContracts.map((rewardBalancesContract, index) => {
      return {
        [index]: rewardBalancesContract.methods.balanceOf(rewardsContractAddress),
      }
    })
    return rewardBalancesReqs
  }, [rewardBalancesContracts, rewardsContractAddress])

  const multicallId = useRef<number>(0)
  const multicallLastShownId = useRef<number>(-1)

  useEffect(() => {
    async function getRewardsStats(): Promise<void> {
      if (!chainId || !multicall || !rewardsContract || !rewardBalancesReqs || !poolInfosReqs) {
        return
      }
      if (!rewardsContract) {
        return
      }
      const mulCalls = [];
      mulCalls.push({
        crossPerSecond: rewardsContract.methods.crossPerSecond(),
        totalAllocPoint: rewardsContract.methods.totalAllocPoint(),
      });
      mulCalls.push(...rewardBalancesReqs)
      mulCalls.push(...poolInfosReqs)
      const id = multicallId.current
      multicallId.current += 1
      const [{ crossPerSecond, totalAllocPoint }, ...farmsInfo] = (await multicall.all([mulCalls]))[0]
      if (id < multicallLastShownId.current) {
        return
      } else {
        multicallLastShownId.current = id
      }
      const rewardContractBalances = farmsInfo.slice(0, farmsList.length)
      const poolInfos = farmsInfo.slice(farmsList.length)
      const oneDaySecs = BigNumber.from(24 * 60 * 60)
      const crossUSDPrice = prices['cronos-stable-swap']
      const tokensPriceUSD: { [tokenIndex: number]: number } = {
        0: crossUSDPrice || 0,
        1: prices['xusd'] || 0,
        2: prices['cross-xusd'] || 0,
        3: prices['cross-busd'] || 0,
        4: prices['cross-mimatic'] || 0,
        5: prices['cross-true-usd'] || 0
      } // TODO make it failsafe even if there is no any price
      const result: PoolRewardStats[] = poolInfos.map((poolInfo, index) => {
        const _accCrossPerShare = poolInfo[index][0]
        const _lastRewardTime = poolInfo[index][1]
        const  allocPoint = poolInfo[index][2]
        return {
          poolId: index,
          crossPerDay: (BigNumber.from(crossPerSecond))
            .mul(oneDaySecs)
            .mul(BigNumber.from(allocPoint))
            .div(BigNumber.from(totalAllocPoint)),
          apr: (BigNumber.from(rewardContractBalances[index][index])).eq(Zero) || chefEmissionForkAllocPoint === 0 || totalAllocPoint === '0' || tokensPriceUSD[index] === 0
            ? "0"
            : new BigNumberJs(annualRewardAmount)
              .multipliedBy(new BigNumberJs(chefAllocPoint))
              .dividedBy(new BigNumberJs(chefEmissionForkAllocPoint))
              .multipliedBy(new BigNumberJs(allocPoint.toString()))
              .dividedBy(new BigNumberJs(totalAllocPoint.toString()))
              .multipliedBy(crossUSDPrice || 0)
              .dividedBy(new BigNumberJs(rewardContractBalances[index][index].toString())
                .multipliedBy(new BigNumberJs(tokensPriceUSD[index])))
              .multipliedBy(100)
              .toFormat(2)
              .toString(),
          tvl: (BigNumber.from(rewardContractBalances[index][index])).eq(Zero)
            ? Zero
            : BigNumber.from(new BigNumberJs(rewardContractBalances[index][index].toString())
              .dividedBy(new BigNumberJs(10 ** 18))
              .multipliedBy(new BigNumberJs(tokensPriceUSD[index]))
              .integerValue()
              .toString())
        }
      })
      setRewardsStats(result);
    }

    void getRewardsStats().catch((error: any) => {
      console.error(error)
    })
  },
    [annualRewardAmount, chainId, chefAllocPoint, chefEmissionForkAllocPoint, farmsList.length, multicall, poolInfosReqs, prices, rewardBalancesReqs, rewardsContract])

  return rewardsStats
}
