import { buildAddresses } from "./buildAddresses"
import { ENV, isProduction } from "./ENV"
import { ChainId } from "./types/ChainId"
import { Token } from "./types/Token"
import { WxUSD_METASWAP_POOL_NAME, STABLECOIN_CROSS_POOL_NAME } from "./types/PoolName"
import { STABLECOIN_POOL_TOKENS, STABLECOIN_SWAP_V3_TOKEN, STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES } from "./StableCoinCrossPool"
import { PoolTypes } from "./types/PoolTypes"
import { buildPids } from "./buildPids"
import { Pool } from "./types/Pool"

import xUsdLPTokenLogo from "../../assets/icons/xusd-logo-ellipse-transparent-260x260.svg"
import xxusdLPTokenLogo from "../../assets/icons/xxusd-logo-ellipse-transparent-260x260.svg"
import WxUSDLogo from "../../assets/icons/wxusd-logo-ellipse-transparent-260x260.svg"
export { WxUSDLogo }

const WxUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x3cfDcb7D8fA9CEb7c2FB378F28B772E02f99Fab5",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x3cfDcb7D8fA9CEb7c2FB378F28B772E02f99Fab5",
        })
    }
  }
}
  
export const WxUSD_SWAP_TOKEN = new Token(
  WxUSD_SWAP_TOKEN_CONTRACT_ADDRESSES(),
  18,
  "xWxUSD",
  "crossWxUSD",
  "Cross WxUSD-DAI/USDC/USDT",
  xxusdLPTokenLogo,
  false,
  true,
  false,
  false
)

const WxUSD_META_SWAP_DEPOSIT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
      [ChainId.CRONOS]: "0x5c8Aa70621989A1FB69D53cFFAbe2f3846d2C258",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0x5c8Aa70621989A1FB69D53cFFAbe2f3846d2C258",
      })
    }
  }
}

export const ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES = (): Record<ChainId, string> => { //addresses to use in pool
  {
    switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
      [ChainId.CRONOS]: "0x533306328149Ae09DB02c970dda142b43D747fe9",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0x533306328149Ae09DB02c970dda142b43D747fe9",
      })
    }
  }
}

export const WxUSD_CONTRACT_ADDRESSES: Token["addresses"] = buildAddresses({  //address for wrapped xUsd, use only on Pools page, not in Swap
    [ChainId.CRONOS]: "0x5dC7341F13d38175c23CCE4e74619343c2d344e3",
})

const xxUSD_CONTRACT_ADDRESSES = STABLECOIN_SWAP_V3_TOKEN_CONTRACT_ADDRESSES //for Swap page

export const WxUSD = new Token(
  xxUSD_CONTRACT_ADDRESSES(),
  18,
  "WxUSD",
  "wxusd",
  "WxUSD",
  xUsdLPTokenLogo,// WxusdLogo,
  true,
  true,
  false,
  false
)

const WxUSD_POOL_TOKENS = [WxUSD, ...STABLECOIN_POOL_TOKENS]

const WxUSD_META_SWAP_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x6b5AAef2D79e82B296b62793ebB4707Ee9b33341",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x6b5AAef2D79e82B296b62793ebB4707Ee9b33341",
        })
    }
  }
}

const WxUSD_UNDERLYING_POOL_TOKENS = [WxUSD, STABLECOIN_SWAP_V3_TOKEN]

export const WxUSD_METASWAP_POOL: Pool = {
  name: WxUSD_METASWAP_POOL_NAME,
  addresses: WxUSD_META_SWAP_DEPOSIT_ADDRESSES(),
  lpToken: WxUSD_SWAP_TOKEN,
  poolTokens: WxUSD_POOL_TOKENS,
  isSynthetic: true,
  type: PoolTypes.USD,
  metaSwapAddresses: WxUSD_META_SWAP_ADDRESSES(),
  underlyingPoolTokens: WxUSD_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_CROSS_POOL_NAME,
  route: "wxusd",
  rewardPids: buildPids({
    [ChainId.CRONOS]: 6,
  }),
  isHidden: isProduction()
}
