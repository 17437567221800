import { useEffect, useMemo, useState } from 'react'

import { useActiveWeb3React } from "."
import { POOLS_MAP } from "../constants/pools/PoolsMap"
import { Pool } from "../constants/pools/types/Pool"
import { PoolName } from "../constants/pools/types/PoolName"
import { useMulticall } from "./useEthMultiCallContracts"
import { useWeb3EthMetaSwapContractsList, useWeb3EthSwapContractsList } from "./useEthMulticallContractsList"

export function usePoolsPaused(): { [poolName in PoolName]?: boolean } {
  const { chainId } = useActiveWeb3React()
  const poolNames: PoolName[] = useMemo(() => Object.keys(POOLS_MAP) as PoolName[], [])
  const swapContractsList = useWeb3EthSwapContractsList(poolNames)
  const metaSwapContractsList = useWeb3EthMetaSwapContractsList(poolNames)
  const multiCall = useMulticall()

  const [isPausedParameters, setIsPausedParameters] = useState<{ [poolName in PoolName]?: boolean }>({})

  useEffect(() => {
    async function fetchIsPausedParameters() {
      if (!chainId || !multiCall) {
        return {}
      }

      const multiCallRequests: Record<string, any>[] = []
      poolNames.forEach((poolName, index) => {
        const pool: Pool = POOLS_MAP[poolName]
        if (!pool || !pool.addresses[chainId]) {
          return {}
        }

        const swapContract = swapContractsList[index]
        const metaSwapContract = metaSwapContractsList[index]

        const effectiveSwapContract = metaSwapContract || swapContract
        if (effectiveSwapContract !== undefined && effectiveSwapContract !== null) {
          multiCallRequests.push(
            { [poolName]: effectiveSwapContract?.methods.paused() }
          )
        }
      })

      let multiCallResult: any[] = []
      try {
        const multiCallResponse = await multiCall.all([multiCallRequests])
        if (multiCallResponse) {
          multiCallResult = multiCallResponse[0]
        }
      } catch (error) {
        console.error(error)
      }
      if (multiCallResult.length === 0) {
        return
      }
      const fetchedParameters: { [poolName in PoolName]?: boolean } = {}
      let multiCallResultIndex = 0
      poolNames.forEach((poolName) => {
        const resultForIndex = multiCallResult[multiCallResultIndex]
        if (resultForIndex && resultForIndex[poolName] !== undefined) {
          fetchedParameters[poolName] = resultForIndex[poolName]
          multiCallResultIndex += 1
        } else {
          fetchedParameters[poolName] = false
        }
      })
      setIsPausedParameters(fetchedParameters)
    }
    fetchIsPausedParameters()
  }, [chainId, metaSwapContractsList, multiCall, poolNames, swapContractsList])
  if (Object.keys.length === 0) {
    const result: { [poolName in PoolName]?: boolean } = {}
    poolNames.forEach((poolName) => {
      result[poolName] = false
    })
    return result
  }
  return isPausedParameters
}