import "./TokenClaimModal.scss"

import { buyTokenUrls , TRANSACTION_TYPES} from "../constants"
import { AVTOCROSS_TOKEN } from "../constants/farms/crossStaking"
import { Farm, FARMS_MAP } from "../constants/farms/FarmsMap"
import React, { ReactElement, useCallback, useContext, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { commify, formatBNToString } from "../utils"
import { notifyCustomError, notifyHandler } from "../utils/notifyHandler"
import { useLockedStakeContract, useMiniChefContract } from "../hooks/useContract"

import { BigNumber } from "@ethersproject/bignumber"
import Button from "./Button"
import { RewardsBalancesContext } from "../providers/RewardsBalancesProvider"
import { Zero } from "@ethersproject/constants"
import logo from "../assets/img/logo-ellipse-transparent-260x260.svg"
import plusIcon from "../assets/icons/plus.svg"
import { useActiveWeb3React } from "../hooks"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { AppState } from "../state"
import { updateLastTransactionTimes } from "../state/application"

import iShare from "../assets/img/i-share.svg"

// TODO: update token launch link
export default function TokenClaimModal(): ReactElement {
  const avtoCrossTokenPrices = useSelector((state: AppState) => state.application.tokenPricesUSD, shallowEqual)

  const crossPriceUsd = avtoCrossTokenPrices?.[AVTOCROSS_TOKEN.crossId]?.toFixed(4)
  // @ts-ignore
  const buyTokenLink: string = buyTokenUrls[AVTOCROSS_TOKEN.crossId] as string

  const claimIsForbidden =
    process.env.REACT_APP_CLAIM_IS_FORBIDDEN === "true" &&
    Date.now() < parseInt(process.env.REACT_APP_CLAIM_WILL_BE_ACTIVE_AFTER || "0")
  const claimWillBeActiveAfterDate = new Date(parseInt(process.env.REACT_APP_CLAIM_WILL_BE_ACTIVE_AFTER || "0"))
  const claimWillBeActiveAfter =
    `${claimWillBeActiveAfterDate.toUTCString().split(" ")[2]}
     ${claimWillBeActiveAfterDate.getUTCDate()},
     ${claimWillBeActiveAfterDate.getUTCFullYear()}`

  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const rewardBalances = useContext(RewardsBalancesContext)
  const {
    claimsStatuses,
    claimFarmReward,
    claimLockedStakeReward
  } = useRewardClaims()
  const { addToken, canAdd } = useAddTokenToMetamask({
    ...AVTOCROSS_TOKEN,
    icon: "https://avtocross.finance/logo-cross.png",
  })

  const formattedUnclaimedTokenbalance = commify(
    formatBNToString(rewardBalances.total, 18, 0),
  )
  const [
    allFarmsWithRewards,
    // farmsWithUserRewards,
  ] = useMemo(() => {
    if (!chainId) return [[], []]
    const allFarmsWithRewards = Object.values(FARMS_MAP)
      .filter(({ rewardPids }) => {
        // remove pools not in this chain and without rewards
        return rewardPids[chainId] !== null
      })
      .sort(({ name: nameA }, { name: nameB }) => {
        const [rewardBalA, rewardBalB] = [
          rewardBalances[nameA],
          rewardBalances[nameB],
        ]
        return (rewardBalA || Zero).gte(rewardBalB || Zero) ? -1 : 1
      })
    const farmsWithUserRewards = allFarmsWithRewards.filter(({ name }) => {
      const hasUserRewards = rewardBalances[name]?.gt(Zero)
      return !!hasUserRewards
    })
    return [allFarmsWithRewards, farmsWithUserRewards]
  }, [chainId, rewardBalances])

  return (
    <div className="tokenClaimModal">
      <div className="gradient"></div>
      <div className="tcmLogoWrapper">
        <div className="logo">
          <img src={logo} />
        </div>
      </div>
      <div className="mainContent">
        <div className="tokenBalance">
          {formattedUnclaimedTokenbalance}

          {canAdd && (
            <img
              src={plusIcon}
              className="plus"
              onClick={() => addToken()}
            />
          )}
        </div>
        <div className="tokenBalanceHelpText">
          {t("totalClaimableCROSS")}
        </div>
        <div className="tokenInfo">
          {parseFloat(crossPriceUsd || "0") > 0 && (
            <div className="tokenPrice">
              <span>{AVTOCROSS_TOKEN.name} price:</span>
              <span className="tokenPriceValue">${crossPriceUsd}</span>
            </div>
          )}
          <div className="tokenBuyLink">
            <a href={buyTokenLink} target="_blank" rel="noreferrer">
              {t("buyToken")}
              <img src={iShare} alt={t("buyToken")} title={t("buyToken")} />
            </a>
          </div>
        </div>
        {claimIsForbidden && (
          <div className="claimIsForbidden">
            {t("claimIsForbidden")}
            {" "}
            <span className="activateDate">
              {claimWillBeActiveAfter}
            </span>
          </div>
        )}
        {!claimIsForbidden && (
          <>
            <ul className="claimsList">
              {allFarmsWithRewards.map((farm: Farm, i, arr) => (
                <>
                  <ClaimListItem
                    title={farm.name}
                    amount={rewardBalances[farm.name] || Zero}
                    claimCallback={() => farm.isLocked ? claimLockedStakeReward(farm) : claimFarmReward(farm)}
                    status={claimsStatuses["allPools"] || claimsStatuses[farm.name]}
                    keyInternal={i}
                    key={`keyExternal${i}`}
                  />
                  {i < arr.length - 1 && <Divider key={`d${i}`} />}
                </>
              ))}
            </ul>
            <div className="info">
              <span>
                <Trans i18nKey="saddleTokenInfo" t={t}>
                  CROSS token is launched by CROSS Finance.
                </Trans>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Divider = (): ReactElement => <div className="divider"></div>

function ClaimListItem({
  title,
  amount,
  claimCallback,
  status,
  keyInternal
}: {
  title: string
  amount: BigNumber
  claimCallback: () => void
  status?: STATUSES
  keyInternal: number
}): ReactElement {
  const { t } = useTranslation()
  const formattedAmount = commify(formatBNToString(amount, 18, 2))
  const disabled =
    status === STATUSES.PENDING ||
    status === STATUSES.SUCCESS ||
    amount.lt(BigNumber.from(10).pow(16)) // don't let anyone try to claim less than 0.01 token
  return (
    <li className="listItem" key={keyInternal}>
      <b className="listItemTitle">{title}</b>
      <span>{status === STATUSES.SUCCESS ? 0 : formattedAmount}</span>
      <Button
        onClick={claimCallback}
        size="extra-large"
        kind="primary"
        disabled={disabled}
      >
        {t("claim")}
      </Button>
    </li>
  )
}

export enum STATUSES {
  PENDING,
  SUCCESS,
  ERROR,
}
type PendingClaimsKeys = string | "allPools"
type PendingClaims = Record<PendingClaimsKeys, STATUSES>
export function useRewardClaims(): {
  claimFarmReward: (farm: Farm) => Promise<void>;
  claimLockedStakeReward: (farm: Farm) => Promise<void>;
  claimsStatuses: PendingClaims;
  claimAllFarmsRewards: (pools: Farm[]) => Promise<void> } {
  const { chainId, account } = useActiveWeb3React()
  const rewardsContract = useMiniChefContract()
  const lockedStakeContract = useLockedStakeContract()
  const [pendingClaims, setPendingClaims] = useState<PendingClaims>(
    {} as PendingClaims,
  )
  const dispatch = useDispatch()
  const updateClaimStatus = useCallback(
    (key: string, status: STATUSES) => {
      setPendingClaims((state) => ({
        ...state,
        [key]: status,
      }))
    },
    [setPendingClaims],
  )

  const claimFarmReward = useCallback(
    async (farm: Farm) => {
      if (!chainId || !account || !rewardsContract) return
      try {
        const pid = farm.rewardPids[chainId]
        if (pid === null) return
        updateClaimStatus(farm.name, STATUSES.PENDING)
        const txn = await rewardsContract.harvest(pid, account)
        notifyHandler(txn?.hash, "claim")
        await txn?.wait()
        updateClaimStatus(farm.name, STATUSES.SUCCESS)
        dispatch(
          updateLastTransactionTimes({
            [TRANSACTION_TYPES.STAKE_OR_CLAIM]: Date.now(),
          }),
        )
      } catch (e) {
        console.error(e)
        updateClaimStatus(farm.name, STATUSES.ERROR)
        notifyCustomError({
          ...(e as Error),
          message: "Unable to claim reward",
        })
      }
    },
    [chainId, account, rewardsContract, updateClaimStatus, dispatch],
  )

  const claimLockedStakeReward = useCallback(
    async (farm: Farm) => {
      if (!chainId || !account || !lockedStakeContract) return
      try {
        const pid = farm.rewardPids[chainId]
        if (pid === null) return
        updateClaimStatus(farm.name, STATUSES.PENDING)
        const txn = await lockedStakeContract.getReward()
        notifyHandler(txn?.hash, "claim")
        await txn?.wait()
        updateClaimStatus(farm.name, STATUSES.SUCCESS)
        dispatch(
          updateLastTransactionTimes({
            [TRANSACTION_TYPES.STAKE_OR_CLAIM]: Date.now(),
          }),
        )
      } catch (e) {
        console.error(e)
        updateClaimStatus(farm.name, STATUSES.ERROR)
        notifyCustomError({
          ...(e as Error),
          message: "Unable to claim reward",
        })
      }
    },
    [chainId, account, lockedStakeContract, updateClaimStatus, dispatch],
  )
  const claimAllFarmsRewards = useCallback(
    async (pools: Farm[]) => {
      if (!chainId || !account || !rewardsContract) return
      try {
        const calls = await Promise.all(
          pools.map((pool) => {
            const pid = pool.rewardPids[chainId] as number
            return rewardsContract.populateTransaction.harvest(pid, account)
          }),
        )
        updateClaimStatus("all", STATUSES.PENDING)
        // eslint-disable-next-line
        const txn = await rewardsContract.batch(
          calls.map(({ data }) => data as string),
          false,
        )
        // eslint-disable-next-line
        notifyHandler(txn?.hash, "claim")
        // eslint-disable-next-line
        await txn?.wait()
        updateClaimStatus("all", STATUSES.SUCCESS)
        dispatch(
          updateLastTransactionTimes({
            [TRANSACTION_TYPES.STAKE_OR_CLAIM]: Date.now(),
          }),
        )
      } catch (e) {
        console.error(e)
        updateClaimStatus("all", STATUSES.ERROR)
        notifyCustomError({
          ...(e as Error),
          message: "Unable to claim reward",
        })
      }
    },
    [chainId, account, rewardsContract, updateClaimStatus, dispatch],
  )
  return {
    claimsStatuses: pendingClaims,
    claimFarmReward,
    claimLockedStakeReward,
    claimAllFarmsRewards,
  }
}

