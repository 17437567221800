import "./SwapInput.scss"

import React, { ReactElement, useCallback, useRef, useState } from "react"

import { BigNumber } from "@ethersproject/bignumber"
import SearchSelect from "./SearchSelect"
import { TOKENS_MAP } from "../constants/pools/TokensMap"
import type { TokenOption } from "../pages/Swap"
import classnames from "classnames"
import { commify } from "../utils"
import { formatBNToString } from "../utils"
import iCarretDown from "../assets/img/chevron-down.svg"
import useDetectOutsideClick from "../hooks/useDetectOutsideClick"
import { useTranslation } from "react-i18next"
import { getTokenName, getTokenSymbolToShow } from "./common/tokenUtils"

interface Props {
  tokens: TokenOption[]
  selected: string
  inputValue: string
  inputValueUSD: BigNumber
  isSwapFrom: boolean
  onSelect?: (tokenSymbol: string) => void
  onChangeAmount?: (value: string) => void
  isError?: boolean
}
export default function SwapInput({
  tokens,
  selected,
  onSelect,
  inputValue,
  inputValueUSD,
  isSwapFrom,
  onChangeAmount,
  isError,
}: Props): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const wrapperRef = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const onDetectOutsideClick = useCallback(() => setIsDropdownOpen(false), [setIsDropdownOpen])
  useDetectOutsideClick(
    wrapperRef,
    onDetectOutsideClick,
    isDropdownOpen,
  )
  const handleSelect = useCallback(
    (value: string) => {
      onSelect?.(value)
      setIsDropdownOpen(false)
    },
    [onSelect],
  )
  const selectedToken = TOKENS_MAP[selected]
  return (
    <div className="swapInputContainer">
      <div
        className={classnames(
          "swapInputContainerInner",
          isError && "errorInput",
        )}
      >
        <div
          className={classnames(
            "selectGroup",
            onSelect && "hoverPointer",
          )}
          onClick={() => onSelect && setIsDropdownOpen((prev) => !prev)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (onSelect && (e.key === "Enter" || e.key === " ")) {
              setIsDropdownOpen((prev) => !prev)
            }
          }}
        >
          {selectedToken && <img className="asset-logo" src={selectedToken.icon} />}
          <div className="tokenNameContainer">
            <b className="textBoldAsset">
              {selectedToken ? getTokenSymbolToShow(selectedToken.symbol) : t("chooseToken")}
            </b>
            {selectedToken && (
              <div className="asset-desc">{getTokenName(selectedToken.name)}</div>
            )}
          </div>
          {isDropdownOpen ? (
            <img
              className="arrow"
              style={{ transform: "rotateX(180deg)" }}
              src={iCarretDown}
            />
          ) : (
            <img className="arrow" src={iCarretDown} />
          )}
        </div>
        <div
          className={classnames(
            { ["focusable"]: isSwapFrom },
            "inputGroup",
          )}
          onClick={() => {
            inputRef.current?.focus()
          }}
        >
          <input
            className={isError ? "errorInput" : ""}
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            type="text"
            maxLength={20}
            placeholder="0.0"
            spellCheck="false"
            value={isSwapFrom ? inputValue : commify(inputValue)}
            onChange={(e) => {
              // remove all chars that aren't a digit or a period
              const newValue = e.target.value.replace(/[^\d|.]/g, "")

              // limitation by decimals
              const decimals = selectedToken?.decimals || 18
              if (newValue.indexOf(".") > 0 && newValue.split(".")[1].length > decimals) return

              // disallow more than one period
              if (newValue.indexOf(".") !== newValue.lastIndexOf(".")) return
              onChangeAmount?.(newValue)
            }}
            onFocus={(e: React.ChangeEvent<HTMLInputElement>): void => {
              if (isSwapFrom) {
                e.target.select()
              }
            }}
            readOnly={!isSwapFrom}
            tabIndex={isSwapFrom ? 0 : -1}
          />
          <div className="textMinor">
            ≈${commify(formatBNToString(inputValueUSD, 18, 2))}
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <div className="dropdownContainer" ref={wrapperRef}>
          <SearchSelect tokensData={tokens} onSelect={handleSelect} />
        </div>
      )}
    </div>
  )
}
