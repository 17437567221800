import "./DepositPage.scss"

import { isMetaPool } from "../constants/pools/PoolsMap"
import { PoolDataType, UserShareType } from "../hooks/usePoolsData"
import React, { ReactElement, useState } from "react"
import { useTranslation } from "react-i18next"

import AdvancedOptions from "./AdvancedOptions"
import CheckboxInput from "./CheckboxInput"
import ConfirmTransaction from "./ConfirmTransaction"
import { DepositTransaction } from "../interfaces/transactions"
import Modal from "./Modal"
import MyShareCard from "./MyShareCard"
import PoolInfoCard from "./PoolInfoCard"
import ReviewDeposit from "./ReviewDeposit"
import TokenInput from "./TokenInput"
import TopMenu from "./TopMenu"
import { formatBNToPercentString } from "../utils"
import { logEvent } from "../utils/googleAnalytics"
import Button from "./Button"

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  title: string
  onConfirmTransaction: () => Promise<void>
  onChangeTokenInputValue: (tokenSymbol: string, value: string) => void
  onToggleDepositWrapped: () => void
  shouldDepositWrapped: boolean
  tokens: Array<{
    symbol: string
    name: string
    icon: string
    max: string
    inputValue: string
  }>
  exceedsWallet: boolean
  selected?: { [key: string]: any }
  poolData: PoolDataType | null
  myShareData: UserShareType | null
  transactionData: DepositTransaction
}

/* eslint-enable @typescript-eslint/no-explicit-any */
const DepositPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const {
    tokens,
    exceedsWallet,
    poolData,
    myShareData,
    transactionData,
    shouldDepositWrapped,
    onChangeTokenInputValue,
    onConfirmTransaction,
    onToggleDepositWrapped,
  } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const validDepositAmount = transactionData.to.totalAmount.gt(0)
  const shouldDisplayWrappedOption = isMetaPool(poolData?.name)

  return (
    <div className="deposit">
      <TopMenu activeTab={"deposit"} />
      <div className="container">
        <div className="contentInnerWrapper">
          <div className="left">
            <div className="form">
              <h3>{t("addLiquidity")}</h3>
              {exceedsWallet ? (
                <div className="error">{t("depositBalanceExceeded")}</div>
              ) : null}
              {tokens.map((token, index) => (
                <div key={index}>
                  <TokenInput
                    {...token}
                    disabled={poolData?.isPaused}
                    onChange={(value): void =>
                      onChangeTokenInputValue(token.symbol, value)
                    }
                    isError={exceedsWallet}
                  />
                  {index === tokens.length - 1 ? (
                    ""
                  ) : (
                    <div className="formSpace"></div>
                  )}
                </div>
              ))}
              {shouldDisplayWrappedOption && (
                <div className="wrappedDeposit">
                  <CheckboxInput
                    onChange={onToggleDepositWrapped}
                    checked={shouldDepositWrapped}
                  />
                  <span>{t("depositWrapped")}</span>
                </div>
              )}
              <div className={"transactionInfoContainer"}>
                <div className="transactionInfo">
                  <div className="transactionInfoItem">
                    {transactionData.priceImpact.gte(0) ? (
                      // <ToolTip content={t("bonusTooltip")}>
                      //   <span className="bonus">
                      //     {t("bonus")}
                      //     <img src={iInformation} alt="information" />:
                      //   </span>
                      // </ToolTip>
                      <span className="bonus">{t("bonus")}:</span>
                    ) : (
                      // <ToolTip content={t("priceImpactTooltip")}>
                      //   <span className="slippage">
                      //     {t("priceImpact")}
                      //     <img src={iInformation} alt="information" />:
                      //   </span>
                      // </ToolTip>
                      <span className="slippage">{t("priceImpact")}:</span>
                    )}
                    <span
                      className={
                        "value " +
                        (transactionData.priceImpact.gte(0)
                          ? "bonus"
                          : "slippage")
                      }
                    >
                      {" "}
                      {formatBNToPercentString(
                        transactionData.priceImpact,
                        18,
                        4,
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="advancedOptionsWrapper">
              <AdvancedOptions />
            </div>
            <div className="mainActionBtn">
              <Button
                kind="primary"
                size="extra-large"
                onClick={(): void => {
                  setCurrentModal("review")
                }}
                disabled={!validDepositAmount || poolData?.isPaused || exceedsWallet}
              >
                <span>{t("deposit")}</span>
              </Button>
            </div>
          </div>
          <div className="right">
{/*            {poolData && (
              <MyFarm
                lpToken={poolData.lpToken}
                lpWalletBalance={myShareData?.lpTokenBalance || Zero}
              />
            )}*/}
            <div className="infoPanels">
              <MyShareCard data={myShareData} />
              <div
                style={{
                  display: myShareData ? "block" : "none",
                }}
                className="divider"
              ></div>{" "}
              <PoolInfoCard data={poolData} />
            </div>
          </div>

          <Modal
            isOpen={!!currentModal}
            onClose={(): void => setCurrentModal(null)}
          >
            {currentModal === "review" ? (
              <ReviewDeposit
                transactionData={transactionData}
                onConfirm={async (): Promise<void> => {
                  setCurrentModal("confirm")
                  logEvent(
                    "deposit",
                    (poolData && { pool: poolData?.name }) || {},
                  )
                  await onConfirmTransaction?.()
                  setCurrentModal(null)
                }}
                onClose={(): void => setCurrentModal(null)}
              />
            ) : null}
            {currentModal === "confirm" ? <ConfirmTransaction /> : null}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default DepositPage
