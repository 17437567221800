import { Token } from "../../constants/pools/types/Token"
import { WxUSDLogo, WxUSD_CONTRACT_ADDRESSES } from "../../constants/pools/WxUSDMetaswapPool"

// alter symbol for WxUSD. WxUSD should be shown in Pool, in Swap it should be changed on xUSD https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
export function getTokenSymbolToShow(symbol: string): string {
  if (symbol === "WxUSD") {
    return "xUSD"
  }
  return symbol
}
 
// alter name for WxUSD ("WxUSD" by default, changes to xUSD name ("Cross USD Stablecoin") in Swap) https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
export function getTokenName(name: string): string {
   if (name === "WxUSD") {
     return "Cross USD Stablecoin"
   }
   return name
}
 
// alter WxUSDLogo (xUSD logo is used by default, should be altered on Pools, Deposit and Withdraw pages) https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
export function getTokenIcon(token: Token): string { 
  if(token.symbol === "WxUSD") {
    return WxUSDLogo
  }
  return token.icon
}

// alter WxUSDLogo (xUSD logo is used by default, should be altered on Pools, Deposit and Withdraw pages) https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
export function getTokenIconByTokenSymbol(symbol: string, icon: string): string {
  if(symbol === "WxUSD") {
    return WxUSDLogo
  }
  return icon
}

//alter addresses for WxUSD (xUSD addresses by default - used in Swap, WxUSD addresses only in Pool) https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
export function getTokenAddresses(token: Token, usingAltWxUSDAddress: boolean): Token["addresses"] { 
  if (token.name === "WxUSD" && usingAltWxUSDAddress) {
    return WxUSD_CONTRACT_ADDRESSES 
  }
  return token.addresses
}