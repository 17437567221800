import "./MyFarm.scss"

import { IS_SDL_LIVE, NATIVE_COIN_NAME } from "../constants"
import { Farm } from "../constants/farms/FarmsMap"
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import { commify, formatBNToShortString, formatBNToString, formatNumberStringToShortString } from "../utils"

import Button from "./Button"
import { useRewardsHelpers } from "../hooks/useRewardsHelpers"
import { useTranslation } from "react-i18next"
import { useLPTokenContract } from "../hooks/useContract"
import useUserBalance from "../hooks/useUserBalance"
import tokenLogo from "../assets/img/logo-ellipse-transparent-260x260.svg"
import { Zero } from "@ethersproject/constants"
import { PoolRewardStats } from "../hooks/useRewardsStats"
import { STATUSES, useRewardClaims } from "./TokenClaimModal"
import { RewardsBalancesContext } from "../providers/RewardsBalancesProvider"
import { BigNumber as BigNumberJs } from "bignumber.js"
import { BigNumber } from "@ethersproject/bignumber"
import classNames from "classnames"
import { useLockedRewardsHelpers } from "../hooks/useLockedRewadsHelpers"
import ToolTip from "./ToolTip"
import iInformation from "../assets/img/i-information.svg"

type Props = {
  farm: Farm,
  rewardStats: PoolRewardStats | undefined,
  prices: {[tokenSymbol: string]: number}| undefined,
}
export default function MyFarm({
  farm,
  rewardStats,
  prices,
}: Props): ReactElement | null {
  BigNumberJs.config({ EXPONENTIAL_AT: 19 }) // For correct display a small sums

  const [farmTokenPriceUSD, setFarmTokenPriceUSD] = useState(0);
  const [lpWalletBalanceInUSD, setLpWalletBalanceInUSD] = useState(0);
  const [amountStakedInUSD, setAmountStakedInUSD] = useState(0);

  const {
    userTokenBalance,
    lpWalletBalanceHandle,
    setLpWalletBalanceHandle,
    lpWalletBalanceHandleError,
    setLpWalletBalanceHandleError,
  } = useUserBalance(farm.lpToken)
  const lpWalletBalance = userTokenBalance
  const lpTokenContract = useLPTokenContract(farm.lpToken)

  useEffect(() => {
    if(!prices) {
      return
    }
    const priceUSD = prices[farm.lpToken.crossId];
    setFarmTokenPriceUSD(priceUSD);
  }, [prices, farm.lpToken.isLiquidityPairToken, farm.lpToken.crossId])

  useEffect(() => {
    if (farmTokenPriceUSD) {
      setLpWalletBalanceInUSD(
        new BigNumberJs(lpWalletBalance.toString())
          .div(new BigNumberJs(10**18))
          .multipliedBy(farmTokenPriceUSD).toNumber())
    }
  }, [farmTokenPriceUSD, lpWalletBalance])
  const rewardUtils = useRewardsHelpers(farm.rewardPids, lpTokenContract)
  const lockedRewardUtils = useLockedRewardsHelpers(farm.rewardPids, lpTokenContract)
  const {
    approveAndStake,
    unstake,
    amountStaked,
    amountStakedHandle,
    amountStakedLocked,
    amountStakedUnlocked,
    setAmountStakedHandle,
    lpAmountStakedHandleError,
    setLpAmountStakedHandleError,
    isPoolIncentivized,
  } = farm.isLocked ? lockedRewardUtils : rewardUtils;
  useEffect(() => {
    if (farmTokenPriceUSD) {
      setAmountStakedInUSD(
        new BigNumberJs(amountStaked.toString())
          .div(new BigNumberJs(10**18))
          .multipliedBy(farmTokenPriceUSD)
          .toNumber())
    }
  }, [amountStaked, farmTokenPriceUSD])
  const { t } = useTranslation()
  const formattedLpWalletBalance = commify(
    formatBNToString(lpWalletBalance, 18, 4),
  )
  const formattedLpWalletBalanceInUSD = commify(lpWalletBalanceInUSD.toFixed(2))
  const formattedLpStakedBalance = commify(
    formatBNToString(amountStaked, 18, 4),
  )
  const formattedLpStakedBalanceInUSD = commify(amountStakedInUSD.toFixed(2))

  const formattedStakedLockedBalance = commify(
    formatBNToString(amountStakedLocked, 18, 2),
  )
  const formattedStakedUnlockedBalance = commify(
    formatBNToString(amountStakedUnlocked, 18, 2),
  )
  const claimIsForbidden =
    process.env.REACT_APP_CLAIM_IS_FORBIDDEN === "true" &&
    Date.now() < parseInt(process.env.REACT_APP_CLAIM_WILL_BE_ACTIVE_AFTER || "0")

  const rewardBalances = useContext(RewardsBalancesContext)
  // const getRewardAmount = (farm: Farm): BigNumber => farm.isLocked ? rewardAmount : rewardBalances[farm.name] || Zero
  const getRewardAmount = (farm: Farm): BigNumber => rewardBalances[farm.name] || Zero
  const {
    claimsStatuses,
    claimFarmReward,
    claimLockedStakeReward,
  } = useRewardClaims()

  const claimReward = (farm: Farm) => {
    return farm.isLocked ? claimLockedStakeReward(farm) : claimFarmReward(farm)
  }
  const formattedAPR = rewardStats?.apr ? formatNumberStringToShortString(rewardStats.apr) : ""

  return isPoolIncentivized && IS_SDL_LIVE ? (
    <div className="myFarm">
      <div className={classNames("farm-title", farm.isLocked && "lock-fit")}>
        <h4>{farm.name}</h4>
        {farm.addLiquidityUrl && (
          <div className="addLiquidity-top-w">
            <a className="addLiquidity" href={farm.addLiquidityUrl} target="_blank" rel="noreferrer">{farm.addLiquidityLabel || t("addLiquidity")}</a>
          </div>
        )}
      </div>

      <div className={classNames("statInfo", farm.isLocked && "lock-fit")}>
        {rewardStats && rewardStats.crossPerDay != null && IS_SDL_LIVE && (
          <>
            <div className="margin">
              {/* <span className="label truncate"> */}
              <span className="label">
                {NATIVE_COIN_NAME}/24h
              </span>
              <span className="value">
                <img src={tokenLogo} className="tokenIcon" />
                {formatBNToShortString(rewardStats?.crossPerDay || Zero, 18)}
              </span>
            </div>
            <div className="margin">
              <span className="label truncate">{`${t("apr")}`}</span>
              <span className="value percents">
                {formattedAPR || "--" } %
              </span>
            </div>
            <div className="margin">
              <span className="label truncate">{`${t("tvl")}`}</span>
              <span className="value">
                ${rewardStats?.tvl ? formatBNToShortString(rewardStats.tvl || Zero, 0) : "--" }
              </span>
            </div>
            {farm.isLocked &&
            <div className="margin">
              <span className="label truncate">CROSS</span>
              <span className="value">
                {rewardStats?.tvlInFarmToken ? formatBNToShortString(rewardStats.tvlInFarmToken || Zero, 0) : "--" }
              </span>
            </div>}
          </>
        )}
      </div>

      <div className="decoration-divider"></div>

      <div className="tokens">
        {farm.poolTokens?.map(({ symbol, icon }) => (
          <div className="token" key={symbol}>
            <img alt="icon" className="tokenIcon" src={icon} />
            <span>{symbol}</span>
          </div>
        ))}
      </div>

      {farm.addLiquidityUrl && (
        <div className="addLiquidity-bottom-w">
          <a className="addLiquidity" href={farm.addLiquidityUrl} target="_blank" rel="noreferrer">{farm.addLiquidityLabel || t("addLiquidity")}</a>
        </div>
      )}

      <div className="list">
        <div className="item xs-wrap">
          <div className="value-w">
            <div className="value-info">
              <div className="item-label">{t("lpAvailable")}</div>
              <div className="balance">
                <div className="bold">{formattedLpWalletBalance}</div>
                <div className="bold usd">≈${formattedLpWalletBalanceInUSD}</div>
              </div>
            </div>
            <FarmInput
              value={lpWalletBalanceHandle}
              max={BigNumber.from(lpWalletBalance.toString())}
              readOnly={lpWalletBalance.isZero()}
              onChangeAmount={setLpWalletBalanceHandle}
              isError={lpWalletBalanceHandleError}
              onError={setLpWalletBalanceHandleError}
            />
          </div>
          <div className="btn-w">
            <Button
              className={farm.isLocked ? "lockedStakeButton" : ""}
              kind="outline"
              size="extra-large"
              disabled={!lpWalletBalanceHandle.length || parseFloat(lpWalletBalanceHandle) === 0 || lpWalletBalanceHandleError}
              onClick={() => approveAndStake(
                new BigNumberJs(lpWalletBalance.toString()).dividedBy(new BigNumberJs(10 ** 18).toString()).toString() === lpWalletBalanceHandle.toString()
                  ? lpWalletBalance
                  : BigNumber.from(
                    new BigNumberJs(parseFloat(lpWalletBalanceHandle).toString())
                      .multipliedBy(new BigNumberJs(10 ** 18))
                      .toString(10)
                  )
              )}
            >
              {t("stake")}
            </Button>
          </div>
        </div>

        <div className="item lock-b xs-wrap">
          <div className="value-w">
            <div className="value-info">
              <div className="item-label">{t("lpStaked")}</div>
              <div className="balance">
                <div className="bold">{formattedLpStakedBalance}</div>
                <div className="bold usd">≈${formattedLpStakedBalanceInUSD}</div>
              </div>
            </div>
            {!farm.isLocked && <FarmInput
              value={amountStakedHandle}
              max={BigNumber.from(amountStaked.toString())}
              readOnly={amountStaked.isZero()}
              onChangeAmount={setAmountStakedHandle}
              isError={lpAmountStakedHandleError}
              onError={setLpAmountStakedHandleError}
            />}
            {farm.isLocked && <div className="lock-info-w">
              <div className="value-info">
                <div className="item-label uppercase">{t("lockedStake")}</div>
                <div className="balance">
                  <div className="bold bold-small">{formattedStakedLockedBalance}</div>
                </div>
              </div>
              <div className="value-info">
                <div className="item-label uppercase">{t("unlockedStake")}</div>
                <div className="balance">
                  <div className="bold bold-small">{formattedStakedUnlockedBalance}</div>
                </div>
              </div>
            </div>}
          </div>
          {!farm.isLocked && <div className="btn-w">
            <Button
              kind="outline"
              size="extra-large"
              disabled={!amountStakedHandle.length || parseFloat(amountStakedHandle) === 0 || lpAmountStakedHandleError}
              onClick={() => unstake(
                new BigNumberJs(amountStaked.toString()).dividedBy(new BigNumberJs(10 ** 18).toString()).toString() === amountStakedHandle.toString()
                  ? amountStaked
                  : BigNumber.from(
                    new BigNumberJs(parseFloat(amountStakedHandle).toString())
                      .multipliedBy(new BigNumberJs(10 ** 18))
                      .toString(10)
                  )
              )}
            >
              {t("unstake")}
            </Button>
          </div>}
          {farm.isLocked &&
            <>
            <div className="btn-w btn-i">
              <Button
                size="extra-large"
                kind="outline"
                disabled={amountStakedUnlocked.eq(Zero)}
                onClick={() => unstake(Zero)}
              >
                {t("exit")}
              </Button>
              <div className="toolTipBox">
                <ToolTip content={t("lockedStakeExitInfo")}>
                  <img className="info" src={iInformation} alt="information" />
                </ToolTip>
              </div>
            </div>
            <div className="lockedStakeExitInfo">
                <img src={iInformation} alt="information" />
                <span>{t("lockedStakeExitInfo")}</span>
            </div>
            </>}
        </div>

        <div className={classNames("item", claimIsForbidden && "claimDisabled")}>
          <ClaimListItem
            amount={getRewardAmount(farm)}
            claimCallback={() => claimReward(farm)}
            status={claimsStatuses["allPools"] || claimsStatuses[farm.name]}
            key={farm.name}
          />
        </div>
      </div>

      {farm.partnership && (
        <div className="partnershipWith">
          <a href={farm.partnership.url} target="_blank" rel="noreferrer">
            <span className="slogan">{t(farm.partnership.i18n)}</span>
            <img src={farm.partnership.logo} alt="partnership" />
          </a>
        </div>
      )}
    </div>
  ) : null
}

function ClaimListItem({
  amount,
  claimCallback,
  status,
}: {
  amount: BigNumber
  claimCallback: () => void
  status?: STATUSES
}): ReactElement {
  const { t } = useTranslation()
  const formattedAmount = commify(formatBNToString(amount, 18, 2))
  const disabled =
    status === STATUSES.PENDING ||
    status === STATUSES.SUCCESS ||
    amount.lt(BigNumber.from(10).pow(16)) // don't let anyone try to claim less than 0.01 token
  return (
    <>
      <div className="value-w">
        <div className="value-info">
          <div className="item-label">{t("claimReward")}</div>
          <div className="bold">{formattedAmount}</div>
        </div>
      </div>
      <div className="btn-w">
        <Button
          onClick={claimCallback}
          kind="outline"
          size="extra-large"
          disabled={disabled}
        >
          {t("claim")}
        </Button>
      </div>
    </>
  )
}

function FarmInput({
  value,
  max,
  readOnly,
  onChangeAmount,
  isError,
  onError
}: {
  value: string
  max: BigNumber
  readOnly: boolean
  onChangeAmount: (value: string) => void
  isError: boolean
  onError: (value: boolean) => void
}): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const alreadyMax = value.length ? max.eq(BigNumber.from(new BigNumberJs(value.toString()).multipliedBy(new BigNumberJs(10 ** 18)).toString(10))) : false

  return (
    <div className={classNames(
      "farmInput",
      isError ? "errorSum" : ""
    )}>
      <input
        ref={inputRef}
        autoComplete="off"
        autoCorrect="off"
        type="text"
        maxLength={30}
        placeholder="0.0"
        spellCheck="false"
        value={value}
        onChange={(e) => {
          // remove all chars that aren't a digit or a period
          let newValue = e.target.value.replace(/[^\d|.]/g, "")

          if (newValue.length && /^0\d/.test(newValue)) {
            newValue = newValue.replace(/^0+(?=\d)/, "")
          }

          // limitation by decimals
          const decimals = 18
          if (newValue.indexOf(".") > 0 && newValue.split(".")[1].length > decimals) return

          // disallow more than one period
          if (newValue.indexOf(".") !== newValue.lastIndexOf(".")) return

          // Value is greater than max value
          onError?.(
            Boolean(newValue.length && BigNumber.from(new BigNumberJs(newValue.toString()).multipliedBy(new BigNumberJs(10 ** decimals)).toString(10)).gt(max))
          )

          onChangeAmount?.(newValue)
        }}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>): void => {
          e.target.select()
        }}
        readOnly={readOnly}
      />
      <button
        className="max-btn"
        onClick={() => {
          onError?.(false)
          onChangeAmount?.(new BigNumberJs(max.toString()).dividedBy(new BigNumberJs(10 ** 18).toString()).toString())
        }}
        disabled={alreadyMax}
      >
        {t("maxBtn")}
      </button>
    </div>
  )
}
