import "./Modal.scss"

import React, { ReactElement, ReactNode } from "react"

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  height?: string
}

function Modal({ isOpen, onClose, children }: Props): ReactElement | null {
  if (!isOpen) {
    return null
  } else {
    // document.body.classList.add("modal-is-open")

    return (
      // Modal container, provide the dark background
      <div
        className="modal"
        onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
          if (e.target === e.currentTarget) {
            // document.body.classList.remove("modal-is-open")
            onClose()
          }
        }}
      >
        {/* Modal content */}
        <div className="modalContent">
          <span
            className="close"
            onClick={(
              e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
            ): void => {
              // document.body.classList.remove("modal-is-open")
              e.stopPropagation()
              onClose()
            }}
          >
            &times;
          </span>
          {children}
        </div>
      </div>
    )
  }
}

export default Modal
