import { ChainId } from "../pools/types/ChainId"
import { RewardPids } from "../pools/types/RewardPids"
import { Token } from "../pools/types/Token"
import { crossStaking } from "./crossStaking"
import { crossXUSDStaking } from "./crossXUsdStaking"
import { xBusdStaking } from "./xBusdStaking"
import { xMaiStaking } from "./xMaiStaking"
import { xUsdStaking } from "./xUsdStaking"
import { crossLockedStaking } from "./crossLockedStaking"
import { xTusdStaking } from "./xTusdStakung"

export type Farm = {
  name: string,
  description?: string,
  chainId: ChainId
  lpToken: Token
  rewardPids: RewardPids
  poolTokens?: Token[]
  addLiquidityUrl?: string
  addLiquidityLabel?: string
  partnership?: {
    logo: string,
    url: string,
    i18n: string
  }
  isLocked?: boolean
}

export type FarmsMap = {
  [farmId: string]: Farm
}

export const FARMS_MAP: FarmsMap = {
  "locked-cross": crossLockedStaking,
  "cross": crossStaking,
  "x-usd": xUsdStaking,
  "cross-x-usd": crossXUSDStaking,
  "x-busd": xBusdStaking,
  "x-mai": xMaiStaking,
  "x-tusd": xTusdStaking,
}
