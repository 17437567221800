import "./ConfirmTransaction.scss"

import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"

function ConfirmTransaction(): ReactElement {
  const { t } = useTranslation()

  return (
    <div className="confirmTransaction">
      <svg
        width="240"
        height="240"
        viewBox="0 0 68.791669 68.791668">
        <g transform="translate(-86.074753,-26.007207)">
          <g transform="matrix(0.26631228,0,0,0.26631228,63.178757,69.531028)">
            <path
              fill="#43b446"
              fillOpacity="1"
              stroke="none"
              strokeWidth="17.31846237"
              strokeMiterlimit="4"
              strokeDasharray="none"
              strokeOpacity="1"
              d="M 130.00391 0.11523438 A 129.88846 129.88846 0 0 0 0.11523438 130.00195 A 129.88846 129.88846 0 0 0 130.00391 259.89062 A 129.88846 129.88846 0 0 0 259.89062 130.00195 A 129.88846 129.88846 0 0 0 130.00391 0.11523438 z M 130.00391 12.751953 A 117.2511 117.2511 0 0 1 247.25391 130.00195 A 117.2511 117.2511 0 0 1 130.00391 247.25391 A 117.2511 117.2511 0 0 1 12.751953 130.00195 A 117.2511 117.2511 0 0 1 130.00391 12.751953 z "
              transform="matrix(0.99350782,0,0,0.99350782,85.97424,-163.43152)" />
            <g transform="matrix(0.90270612,0,0,0.90270612,20.931145,-3.3345316)">
              <g transform="matrix(47.046828,0,0,47.046828,-3902.2906,-4440.5969)">
                <path
                  d="m 87.219404,94.640713 c -0.374722,-0.05258 -0.687042,-0.31115 -0.805458,-0.66684 -0.04088,-0.122801 -0.04787,-0.168243 -0.04801,-0.312351 -1.46e-4,-0.144595 0.0077,-0.196905 0.04754,-0.316566 0.122331,-0.367519 0.452629,-0.631182 0.840253,-0.670732 0.07581,-0.0078 0.45352,-0.0078 0.524472,1.6e-5 0.309718,0.03379 0.579237,0.202256 0.741531,0.463489 0.201441,0.32426 0.197143,0.73954 -0.01096,1.059921 -0.149138,0.2296 -0.381932,0.384085 -0.660172,0.43811 -0.06769,0.01318 -0.544077,0.01685 -0.62918,0.005 z"
                  fill="#43b446"
                  fillOpacity="1"
                  stroke="none"
                  strokeWidth="0.00564351"
                  strokeMiterlimit="4"
                  strokeDasharray="none"
                  strokeOpacity="1" />
                <g transform="matrix(0.26458333,0,0,0.26458333,49.360295,75.549949)">
                  <path
                    fill="#43b446"
                    fillOpacity="1"
                    d="m 143.31055,61 c -0.977,0 -1.94466,0.192486 -2.84766,0.566406 -0.902,0.37391 -1.72406,0.922381 -2.41406,1.613282 -0.691,0.690899 -1.23928,1.511352 -1.61328,2.414062 -0.24841,0.599553 -0.40895,1.22859 -0.49414,1.869141 h 1.96093 c 0.19822,-1.09474 0.72224,-2.114553 1.51954,-2.91211 1.031,-1.03133 2.42967,-1.609375 3.88867,-1.609375 l 1.01367,-0.0039 -0.008,-1.9375 z m -7.36914,8.417969 c 0.0852,0.640538 0.24573,1.269588 0.49414,1.86914 0.374,0.9027 0.92228,1.723163 1.61328,2.414063 0.69,0.6909 1.51206,1.239381 2.41406,1.613281 0.903,0.3739 1.87066,0.566406 2.84766,0.566406 l 1.00781,-0.0039 v -1.9375 h -1.00781 c -1.459,0 -2.85768,-0.580028 -3.88867,-1.611328 -0.7973,-0.797526 -1.32132,-1.815407 -1.51954,-2.910156 z" />
                  <path
                    opacity="0.61000001"
                    fill="#43b446"
                    fillOpacity="1"
                    d="m 144.27735,61 -0.004,1.9375 0.8457,0.0039 c 1.459,0 2.85767,0.578045 3.88867,1.609375 0.79735,0.797612 1.32135,1.817283 1.51953,2.91211 h 1.95899 c -0.0852,-0.640551 -0.24574,-1.269588 -0.49414,-1.869141 -0.374,-0.90271 -0.92033,-1.723163 -1.61133,-2.414062 -0.69,-0.690901 -1.51206,-1.239372 -2.41406,-1.613282 C 147.0638,61.192486 146.09614,61 145.11914,61 Z m 6.24999,8.417969 c -0.19818,1.094836 -0.72218,2.112575 -1.51953,2.910156 -1.031,1.0313 -2.42967,1.611328 -3.88867,1.611328 l -0.82031,-0.0039 0.008,1.941406 0.8125,0.0039 c 0.977,0 1.94466,-0.192506 2.84766,-0.566406 0.902,-0.3739 1.72406,-0.922381 2.41406,-1.613281 0.691,-0.6909 1.23733,-1.511363 1.61133,-2.414063 0.2484,-0.599552 0.40894,-1.228602 0.49414,-1.86914 z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <h3>{t("confirmTransaction")}</h3>
      <svg
        className="loader"
        width="60"
        height="60"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="32"
          strokeWidth="8"
          stroke="#43b446"
          strokeDasharray="50.26548245743669 50.26548245743669"
          fill="none"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="2s"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          />
        </circle>
      </svg>
    </div>
  )
}

export default ConfirmTransaction
