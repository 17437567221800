import { useEffect, useState } from "react"
import { Zero } from "@ethersproject/constants"
import { TRANSACTION_TYPES } from "../constants"
import { Token } from "../constants/pools/types/Token"
import { getContract } from "../utils"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import { LpTokenUnguarded } from "../../types/ethers-contracts"
import { useActiveWeb3React } from "./index"
import { BigNumber } from "@ethersproject/bignumber"
import { shallowEqual, useSelector } from "react-redux"
import { AppState } from "../state"
// import { BigNumber as BigNumberJs } from "bignumber.js"

export default function useUserBalance(lpToken: Token): {
  userTokenBalance: BigNumber,
  lpWalletBalanceHandle: string,
  setLpWalletBalanceHandle: (value: React.SetStateAction<string>) => void,
  lpWalletBalanceHandleError: boolean,
  setLpWalletBalanceHandleError: (value: React.SetStateAction<boolean>) => void,
} {
  const lastTransactionTimes = useSelector(
    (state: AppState) => state.application.lastTransactionTimes,
    shallowEqual
  )
  const lastStakeOrClaim = lastTransactionTimes[TRANSACTION_TYPES.STAKE_OR_CLAIM]

  const { account, library, chainId } = useActiveWeb3React()
  const [userTokenBalance, setUserTokenBalance] = useState(Zero)
  const [lpWalletBalanceHandle, setLpWalletBalanceHandle] = useState('0')
  const [lpWalletBalanceHandleError, setLpWalletBalanceHandleError] = useState(false)
  useEffect(() => {
    async function fetchUserBalance() {
      if (
        library == null ||
        chainId == null
      ) {
        return
      }
      const lpTokenContract = getContract(
        lpToken.addresses[chainId],
        LPTOKEN_UNGUARDED_ABI,
        library,
        account ?? undefined,
      ) as LpTokenUnguarded
      const userLpTokenBalance = account ? await lpTokenContract.balanceOf(account) : Zero
      if (!(userTokenBalance.eq(userLpTokenBalance))) {
        setUserTokenBalance(userLpTokenBalance)
      }

      // reset to default input-value
      setLpWalletBalanceHandle("0")
      
      // auto-setting of MAX value
      // setLpWalletBalanceHandle(new BigNumberJs(userLpTokenBalance.toString()).dividedBy(new BigNumberJs(10 ** 18).toString()).toString())
    }
    void fetchUserBalance()
  },[
    account,
    library,
    chainId,
    lpToken,
    lastStakeOrClaim,
    userTokenBalance
  ])
  return {
    userTokenBalance,
    lpWalletBalanceHandle,
    setLpWalletBalanceHandle,
    lpWalletBalanceHandleError,
    setLpWalletBalanceHandleError,
  }
}
