import {
  BRIDGE_CONTRACT_ADDRESSES,
  MINICHEF_CONTRACT_ADDRESSES,
  SYNTHETIX_CONTRACT_ADDRESSES,
  SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES,
  isLegacySwapABIPool, LOCKED_STAKE_CONTRACT_ADDRESSES,
} from "../constants"

import { TOKENS_MAP } from "../constants/pools/TokensMap"

import { POOLS_MAP, isMetaPool } from "../constants/pools/PoolsMap"
import { PoolName, WxUSD_METASWAP_POOL_NAME } from "../constants/pools/types/PoolName"
import { Token } from "../constants/pools/types/Token"
import BRIDGE_CONTRACT_ABI from "../constants/abis/bridge.json"
import { Bridge } from "../../types/ethers-contracts/Bridge"
import { Contract } from "@ethersproject/contracts"
import ERC20_ABI from "../constants/abis/erc20.json"
import { Erc20 } from "../../types/ethers-contracts/Erc20"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import { LpTokenGuarded } from "../../types/ethers-contracts/LpTokenGuarded"
import { LpTokenUnguarded } from "../../types/ethers-contracts/LpTokenUnguarded"
import META_SWAP_DEPOSIT_ABI from "../constants/abis/metaSwapDeposit.json"
import MINICHEF_CONTRACT_ABI from "../constants/abis/miniChef.json"
import LOCKED_STAKE_CONTRACT_ABI from "../constants/abis/lockedStake.json"
import { MetaSwapDeposit } from "../../types/ethers-contracts/MetaSwapDeposit"
import { MiniChef } from "../../types/ethers-contracts/MiniChef"
import SWAP_FLASH_LOAN_ABI from "../constants/abis/swapFlashLoan.json"
import SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI from "../constants/abis/swapFlashLoanNoWithdrawFee.json"
import SYNTHETIX_EXCHANGE_RATE_CONTRACT_ABI from "../constants/abis/synthetixExchangeRate.json"
import SYNTHETIX_NETWORK_TOKEN_CONTRACT_ABI from "../constants/abis/synthetixNetworkToken.json"
import { SwapFlashLoan } from "../../types/ethers-contracts/SwapFlashLoan"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { SwapGuarded } from "../../types/ethers-contracts/SwapGuarded"
import { SynthetixExchangeRate } from "../../types/ethers-contracts/SynthetixExchangeRate"
import { SynthetixNetworkToken } from "../../types/ethers-contracts/SynthetixNetworkToken"
import { getContract } from "../utils"
import { useActiveWeb3React } from "./index"
import { useMemo } from "react"
import { isNetworkSupported } from "../utils/isNetworkSupported"
import { getTokenAddresses } from "../components/common/tokenUtils"
import { ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES } from "../constants/pools/WxUSDMetaswapPool"
import { LockedStake } from "../../types/ethers-contracts/LockedStake"

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  withSignerIfPossible = true,
): Contract | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (
      !address ||
      !ABI ||
      !library ||
      (chainId && !isNetworkSupported(chainId))
    )
      return null

    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account, chainId])
}

export function useBridgeContract(): Bridge | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? BRIDGE_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(contractAddress, BRIDGE_CONTRACT_ABI) as Bridge
}

export function useMiniChefContract(): MiniChef | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? MINICHEF_CONTRACT_ADDRESSES()[chainId]
    : undefined
  return useContract(contractAddress, MINICHEF_CONTRACT_ABI) as MiniChef
}

export function useLockedStakeContract(): LockedStake | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? LOCKED_STAKE_CONTRACT_ADDRESSES()[chainId]
    : undefined
  return useContract(contractAddress, LOCKED_STAKE_CONTRACT_ABI) as LockedStake
}

export function useERC20Contract(contractAddress: string | undefined): Erc20 | null {
  return useContract(contractAddress, ERC20_ABI) as Erc20
}

export function useSynthetixContract(): SynthetixNetworkToken | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? SYNTHETIX_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(
    contractAddress,
    SYNTHETIX_NETWORK_TOKEN_CONTRACT_ABI,
  ) as SynthetixNetworkToken
}

export function useSynthetixExchangeRatesContract(): SynthetixExchangeRate | null {
  const { chainId } = useActiveWeb3React()
  const contractAddress = chainId
    ? SYNTHETIX_EXCHANGE_RATES_CONTRACT_ADDRESSES[chainId]
    : undefined
  return useContract(
    contractAddress,
    SYNTHETIX_EXCHANGE_RATE_CONTRACT_ABI,
  ) as SynthetixExchangeRate
}

export function useTokenContract(
  t: Token,
  withSignerIfPossible?: boolean,
): Contract | null {
  const { chainId } = useActiveWeb3React()
  const tokenAddress = chainId ? t.addresses[chainId] : undefined
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useSwapContract<T extends PoolName>(
  poolName?: T,
  usingAltWxUSDMetaswapDepositAddress?: boolean
): SwapFlashLoan | SwapFlashLoanNoWithdrawFee | MetaSwapDeposit | null
export function useSwapContract(
  poolName?: PoolName,
  usingAltWxUSDMetaswapDepositAddress = false
):
  | SwapGuarded
  | SwapFlashLoan
  | SwapFlashLoanNoWithdrawFee
  | MetaSwapDeposit
  | null {
  const { chainId, account, library } = useActiveWeb3React()
  return useMemo(() => {
    if (
      !poolName ||
      !library ||
      !chainId ||
      (chainId && !isNetworkSupported(chainId))
    )
      return null

    try {
      const pool = POOLS_MAP[poolName]
      if (!pool) return null
      let poolAddress = pool.addresses[chainId]
      if (poolName === WxUSD_METASWAP_POOL_NAME && usingAltWxUSDMetaswapDepositAddress) { //Alt addresses for WxUSD pool
        poolAddress = ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES()[chainId]
      }
      if (!poolAddress) return null
      if (isLegacySwapABIPool()) {
        return getContract(
          poolAddress,
          SWAP_FLASH_LOAN_ABI,
          library,
          account ?? undefined,
        ) as SwapFlashLoan
      } else if (isMetaPool(poolName)) {
        return getContract(
          poolAddress,
          META_SWAP_DEPOSIT_ABI,
          library,
          account ?? undefined,
        ) as MetaSwapDeposit
      } else if (pool) {
        return getContract(
          poolAddress,
          SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI,
          library,
          account ?? undefined,
        ) as SwapFlashLoanNoWithdrawFee
      } else {
        return null
      }
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [poolName, library, chainId, usingAltWxUSDMetaswapDepositAddress, account])
}

export function useLPTokenContract<T extends Token>(
  lpToken: T,
): LpTokenUnguarded | null
export function useLPTokenContract(
  lpToken: Token,
): LpTokenUnguarded | LpTokenGuarded | null {
  const { chainId, account, library } = useActiveWeb3React()
  return useMemo(() => {
    if (
      !account ||
      !lpToken ||
      !library ||
      !chainId ||
      (chainId && !isNetworkSupported(chainId))
    )
      return null

    try {
      if (!lpToken || !lpToken.addresses[chainId].length) return null

      return getContract(
        lpToken.addresses[chainId],
        LPTOKEN_UNGUARDED_ABI,
        library,
        account ?? undefined,
      ) as LpTokenUnguarded
    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [chainId, library, account, lpToken])
}

interface AllContractsObject {
  [x: string]: Erc20 | null
}
export function useAllContracts(usingAltWxUSDAddress = false): AllContractsObject | null {
  const { chainId, library, account } = useActiveWeb3React()
  return useMemo(() => {
    if (!library || !chainId || (chainId && !isNetworkSupported(chainId)))
      return {}

    const allTokensForChain = Object.values(TOKENS_MAP).filter(
      ({ addresses }) => addresses[chainId],
    )
    return allTokensForChain.reduce((acc, token) => {
      const tokenAddress = getTokenAddresses(token, usingAltWxUSDAddress)[chainId] //alter WxUSD addresses if there is a need to https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485

      if (tokenAddress) {
        let contract = null
        try {
          contract = getContract(
            tokenAddress,
            ERC20_ABI,
            library,
            account || undefined,
          ) as Erc20
        } catch (e) {
          console.error(`Couldn't create contract for token ${tokenAddress}`)
        }
        acc[token.symbol] = contract
      }
      return acc
    }, {} as AllContractsObject)
  }, [library, chainId, usingAltWxUSDAddress, account])
}
