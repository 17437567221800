import { SUPPORTED_NETWORKS } from "../components/SiteSettingsMenu"
import { supportedNetworks } from "./isNetworkSupported"

/**
 * Prompt the user to add a default service's chein as a network on Metamask,
 * or switch to default network if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
// eslint-disable-next-line
export const setupNetwork = async (index: string) => {
  if (index === "WALLET_CONNECT") {
    return true
  }

  const provider = (window as Window).ethereum

  if (supportedNetworks?.length && provider?.request) {
    const chainId = supportedNetworks[0]
    const chainParams = SUPPORTED_NETWORKS[chainId]

    if (!chainParams) return false

    if (!chainParams.chainId.length) {
      chainParams.chainId = `0x${chainId.toString(16)}`
    }

    const providerRequestParams = {
      chainId: chainParams.chainId,
      chainName: chainParams.chainName,
      nativeCurrency: chainParams.nativeCurrency,
      rpcUrls: chainParams.rpcUrls,
      blockExplorerUrls: chainParams.blockExplorerUrls
    }



    try {
      // eslint-disable-next-line
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [providerRequestParams],
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error(
      "Can't setup default network on Metamask because window.ethereum is undefined, or SUPPORTED_NETWORKS array is empty",
    )
    return false
  }
}

/**
 * Prompt the user to add a custom token to Metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
// eslint-disable-next-line
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const provider = (window as Window).ethereum

  if (provider?.request) {
    try {
      // eslint-disable-next-line
      const tokenAdded = await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      })

      // eslint-disable-next-line
      return tokenAdded
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error(
      "Can't add the token to Metamask because window.ethereum is undefined",
    )
    return false
  }
}
