import { buildAddresses } from "./buildAddresses"
import { ENV } from "./ENV"
import { ChainId } from "./types/ChainId"
import { Token } from "./types/Token"
import { BUSD_METASWAP_POOL_NAME, STABLECOIN_CROSS_POOL_NAME } from "./types/PoolName"
import { STABLECOIN_POOL_TOKENS, STABLECOIN_SWAP_V3_TOKEN } from "./StableCoinCrossPool"
import { PoolTypes } from "./types/PoolTypes"
import { buildPids } from "./buildPids"
import { Pool } from "./types/Pool"

import xBusdLPTokenLogo from "../../assets/icons/xbusd-logo-ellipse-transparent-260x260.svg"
import busdLogo from "../../assets/icons/busd.svg"

const BUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x0C0B4707991Bcb82907FF008386fD9B44F16502c",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0xE132D8B965FC0A97A2C7Bfa031302aCB1710d408",
        })
    }
  }
}
  
export const BUSD_SWAP_TOKEN = new Token(
  BUSD_SWAP_TOKEN_CONTRACT_ADDRESSES(),
  18,
  "xBUSD",
  "cross-busd",
  "Cross BUSD-DAI/USDC/USDT",
  xBusdLPTokenLogo,
  false,
  true,
  false,
  false
)

const BUSD_META_SWAP_DEPOSIT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
    default:
      return buildAddresses({
      [ChainId.CRONOS]: "0xd71ff8a5e47807BBAed8997DF4720807490943C0",
      })
    case ENV.PRODUCTION:
      return buildAddresses({
        [ChainId.CRONOS]: "0x4C8D1173C91efd5e510488B9309CDDA0Ac671feD",
      })
    }
  }
}

const BUSD_CONTRACT_ADDRESSES = buildAddresses({
    [ChainId.CRONOS]: "0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8",
})

export const BUSD = new Token(
  BUSD_CONTRACT_ADDRESSES,
  18,
  "BUSD",
  "busd",
  "BUSD",
  busdLogo, // todo busdLogo
  true,
)

const BUSD_POOL_TOKENS = [BUSD, ...STABLECOIN_POOL_TOKENS]

const BUSD_META_SWAP_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x26AE342d909689f1403b9Cf6268Dc03D7CB5d2cA",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x17534DE22edC27a9912024e7b93641C17D508274",
        })
    }
  }
}

const BUSD_UNDERLYING_POOL_TOKENS = [BUSD, STABLECOIN_SWAP_V3_TOKEN]

export const BUSD_METASWAP_POOL: Pool = {
  name: BUSD_METASWAP_POOL_NAME,
  addresses: BUSD_META_SWAP_DEPOSIT_ADDRESSES(),
  lpToken: BUSD_SWAP_TOKEN,
  poolTokens: BUSD_POOL_TOKENS,
  isSynthetic: true,
  type: PoolTypes.USD,
  metaSwapAddresses: BUSD_META_SWAP_ADDRESSES(),
  underlyingPoolTokens: BUSD_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_CROSS_POOL_NAME,
  route: "busd",
  rewardPids: buildPids({
    [ChainId.CRONOS]: 3,
  }),
}
