import { buildPids } from "../pools/buildPids";
import { MAI, MAI_SWAP_TOKEN } from "../pools/MAIMetaswapPool";
import { STABLECOIN_SWAP_V3_TOKEN } from "../pools/StableCoinCrossPool";
import { ChainId } from "../pools/types/ChainId";
import { Farm } from "./FarmsMap";
import qiDaoLogo from "../../assets/img/partnership/QiDao-red.svg"

export const MAI_FARM_TOKENS = [MAI, STABLECOIN_SWAP_V3_TOKEN]

export const xMaiStaking: Farm = {
  name: "MAI Metapool Staking",
  chainId: ChainId.CRONOS,
  lpToken: MAI_SWAP_TOKEN,
  rewardPids: buildPids({
    [ChainId.CRONOS]: 4,
  }),
  poolTokens: MAI_FARM_TOKENS,
  partnership: {
    logo: qiDaoLogo,
    url: "https://www.mai.finance/",
    i18n: "partnership"
  },
}