import { BaseProvider, getDefaultProvider } from "@ethersproject/providers"

import { InjectedConnector } from "@web3-react/injected-connector"
import { NetworkConnector } from "@web3-react/network-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { WalletLinkConnector } from "@web3-react/walletlink-connector"
import { AbstractConnector } from "@web3-react/abstract-connector"
import { IRPCMap } from "@walletconnect/types"

export const { REACT_APP_CRONOS_RPC_URL = '' } = process.env
export const NETWORK_RPC_URL_FULL: Partial<Record<string, string>> = {
  "1": "https://mainnet.infura.io/v3",
  "25": REACT_APP_CRONOS_RPC_URL,
  "137": "https://rpc-mainnet.maticvigil.com/",
}

const NETWORK_URL = process.env.REACT_APP_CRONOS_RPC_URL
export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "25",
)

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_CRONOS_RPC_URL must be a defined environment variable`,
  )
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  // defaultChainId: 25,
})

let networkLibrary: BaseProvider | undefined
export function getNetworkLibrary(): BaseProvider {
  const provider = getDefaultProvider(NETWORK_URL)
  return (networkLibrary = networkLibrary ?? provider)
}

export const supportedChainIds = (): number[] => {
  return process.env.REACT_APP_ENV === "production" ? [25] : [25, 137]
}

export const injected = new InjectedConnector({
  // mainnet, ropsten, rinkeby, goerli, kovan, local buidler
  // see: https://github.com/ethereum/EIPs/blob/master/EIPS/eip-155.md
  supportedChainIds: supportedChainIds(),
})

// eslint-disable-next-line
export const resetWalletConnector = (connector: AbstractConnector) => {
  if (
    connector &&
    connector instanceof WalletConnectConnector &&
    // eslint-disable-next-line
    connector.walletConnectProvider?.wc?.uri
  ) {
    connector.walletConnectProvider = undefined
  }
}

// eslint-disable-next-line
const getRpcList = (): IRPCMap => {
  const res: {[index: string]:string} = {}

  for(const chain of supportedChainIds()) {
    if (NETWORK_RPC_URL_FULL[chain]) {
      res[chain] = NETWORK_RPC_URL_FULL[chain] as string
    }
  }
  return res
}

export const walletconnect = new WalletConnectConnector({
  rpc: getRpcList(), // { [NETWORK_CHAIN_ID]: NETWORK_URL }
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL / 12000,
})

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: "CROSS",
  supportedChainIds: supportedChainIds(),
})
