export const STABLECOIN_CROSS_POOL_NAME = "xUSD 3Pool"
export const BUSD_METASWAP_POOL_NAME =
  "BUSD Metapool"
export const MAI_METASWAP_POOL_NAME = 
  "MAI Metapool"
export const WxUSD_METASWAP_POOL_NAME = 
  "WxUSD Metapool"
// export const DNA_METASWAP_POOL_NAME = 
//   "DNA Metapool"
export const TUSD_METASWAP_POOL_NAME = 
  "TUSD Metapool"

  export type PoolName = typeof STABLECOIN_CROSS_POOL_NAME 
    | typeof BUSD_METASWAP_POOL_NAME
    | typeof MAI_METASWAP_POOL_NAME
    // | typeof DNA_METASWAP_POOL_NAME
    | typeof WxUSD_METASWAP_POOL_NAME
    | typeof TUSD_METASWAP_POOL_NAME
  