import "./WithdrawPage.scss"

import { PoolDataType, UserShareType } from "../hooks/usePoolsData"
import React, { ReactElement, useState } from "react"

import AdvancedOptions from "./AdvancedOptions"
import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import ConfirmTransaction from "./ConfirmTransaction"
import Modal from "./Modal"
import MyShareCard from "./MyShareCard"
import PoolInfoCard from "./PoolInfoCard"
import RadioButton from "./RadioButton"
import ReviewWithdraw from "./ReviewWithdraw"
import TokenInput from "./TokenInput"
import TopMenu from "./TopMenu"
import { WithdrawFormState } from "../hooks/useWithdrawFormState"
import { Zero } from "@ethersproject/constants"
import classNames from "classnames"
import { formatBNToPercentString } from "../utils"
import { logEvent } from "../utils/googleAnalytics"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "./Button"
import iInformation from "../assets/img/i-information.svg"

export interface ReviewWithdrawData {
  withdraw: {
    name: string
    value: string
    icon: string
  }[]
  rates: {
    name: string
    value: string
    rate: string
  }[]
  slippage: string
  priceImpact: BigNumber
  txnGasCost: {
    amount: BigNumber
    valueUSD: BigNumber | null // amount * ethPriceUSD
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  title: string
  tokensData: Array<{
    symbol: string
    name: string
    icon: string
    inputValue: string
  }>
  reviewData: ReviewWithdrawData
  selected?: { [key: string]: any }
  poolData: PoolDataType | null
  myShareData: UserShareType | null
  formStateData: WithdrawFormState
  onFormChange: (action: any) => void
  onConfirmTransaction: () => Promise<void>
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const WithdrawPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const {
    tokensData,
    poolData,
    myShareData,
    onFormChange,
    formStateData,
    reviewData,
    onConfirmTransaction,
  } = props

  const gasPriceSelected = useSelector((state: AppState) => state.user.gasPriceSelected)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const onSubmit = (): void => {
    setCurrentModal("review")
  }
  const noShare = !myShareData || myShareData.lpTokenBalance.eq(Zero)

  return (
    <div className={"withdraw " + classNames({ noShare: noShare })}>
      <TopMenu activeTab={"withdraw"} />
      <div className="container">
        <div className="contentInnerWrapper">
          <div className="left">
            <div className="form">
              <h3>{t("withdraw")}</h3>
              <div className="percentage">
                <span>{`${t("withdrawPercentage")} (%):`}</span>
                <input
                  placeholder="100"
                  onChange={(e: React.FormEvent<HTMLInputElement>): void =>
                    onFormChange({
                      fieldName: "percentage",
                      value: e.currentTarget.value,
                    })
                  }
                  onFocus={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    e.target.select()
                  }
                  value={
                    formStateData.percentage ? formStateData.percentage : ""
                  }
                />
                {formStateData.error && (
                  <div className="error">{formStateData.error.message}</div>
                )}
              </div>
              <div className="horizontalDisplay">
                <RadioButton
                  checked={formStateData.withdrawType === "ALL"}
                  onChange={(): void =>
                    onFormChange({
                      fieldName: "withdrawType",
                      value: "ALL",
                    })
                  }
                  label="Combo"
                />
                {tokensData.map((t) => {
                  return (
                    <RadioButton
                      key={t.symbol}
                      checked={formStateData.withdrawType === t.symbol}
                      onChange={(): void =>
                        onFormChange({
                          fieldName: "withdrawType",
                          value: t.symbol,
                        })
                      }
                      disabled={poolData?.isPaused}
                      label={t.name}
                    />
                  )
                })}
              </div>
              {tokensData.map((token, index) => (
                <div key={index}>
                  <TokenInput
                    {...token}
                    // inputValue={parseFloat(token.inputValue).toFixed(5)}
                    onChange={(value): void =>
                      onFormChange({
                        fieldName: "tokenInputs",
                        value: value,
                        tokenSymbol: token.symbol,
                      })
                    }
                    disabled={poolData?.isPaused}
                    // disabled={ // SMART-DISABLING VERSION
                    //   poolData?.isPaused ||
                    //   !["ALL", "IMBALANCE", token.symbol].includes(formStateData.withdrawType)
                    // }
                    isError={!!formStateData.error}
                  />
                  {index === tokensData.length - 1 ? (
                    ""
                  ) : (
                    <div className="formSpace"></div>
                  )}
                </div>
              ))}
              <div className="transactionInfoContainer">
                <div className="transactionInfo">
                  <div className="transactionInfoItem">
                    {reviewData.priceImpact.gte(0) ? (
                      // <ToolTip content={t("bonusTooltip")}>
                      //   <span className="bonus">
                      //     {t("bonus")}
                      //     <img src={iInformation} alt="information" />:
                      //   </span>
                      // </ToolTip>
                      <span className="bonus">{t("bonus")}:</span>
                    ) : (
                      // <ToolTip content={t("priceImpactTooltip")}>
                      //   <span className="slippage">
                      //     {t("priceImpact")}
                      //     <img src={iInformation} alt="information" />:
                      //   </span>
                      // </ToolTip>
                      <span className="slippage">{t("priceImpact")}:</span>
                    )}
                    <span
                      className={
                        "value " +
                        (reviewData.priceImpact.gte(0) ? "bonus" : "slippage")
                      }
                    >
                      {formatBNToPercentString(reviewData.priceImpact, 18, 4)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="advancedOptionsWrapper">
              <AdvancedOptions />
            </div>
            <div className="mainActionBtn">
              <Button
                kind="primary"
                size="extra-large"
                disabled={
                  noShare ||
                  !!formStateData.error ||
                  formStateData.lpTokenAmountToSpend.isZero()
                }
                onClick={onSubmit}
              >
                <span>{t("withdraw")}</span>
              </Button>
            </div>
          </div>

          <div className="right">
            {/*            {poolData && (
              <MyFarm
                lpWalletBalance={myShareData?.lpTokenBalance || Zero}
                poolName={poolData.name}
              />
            )}*/}
            <div className="infoPanels mb-16 notice">
              <img src={iInformation} alt="information" />
              <p>{t("withdrawInfo")}</p>
            </div>
            <div className="infoPanels">
              <MyShareCard data={myShareData} />
              <div
                style={{
                  display: myShareData ? "block" : "none",
                }}
                className="divider"
              ></div>{" "}
              <PoolInfoCard data={poolData} />
            </div>
          </div>

          <Modal
            isOpen={!!currentModal}
            onClose={(): void => setCurrentModal(null)}
          >
            {currentModal === "review" ? (
              <ReviewWithdraw
                data={reviewData}
                gas={gasPriceSelected}
                onConfirm={async (): Promise<void> => {
                  setCurrentModal("confirm")
                  logEvent(
                    "withdraw",
                    (poolData && { pool: poolData?.name }) || {},
                  )
                  await onConfirmTransaction?.()
                  setCurrentModal(null)
                }}
                onClose={(): void => setCurrentModal(null)}
              />
            ) : null}
            {currentModal === "confirm" ? <ConfirmTransaction /> : null}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default WithdrawPage
