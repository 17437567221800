import { AppDispatch } from "../state"
import { updateAvtoCrossTokenStats } from "../state/application"
const { REACT_APP_CROSS_API_BASE_URL = '' } = process.env

export type AvtoCrossStatsResponse = {
  annualRewardAmount: string,
  chefAllocPoint: number,
  lockChefAllocPoint: number,
  emissionAlreadyMinted: string,
}
export const fetchAvtoCrossStats = async (dispatch: AppDispatch): Promise<void> => {
  let result: AvtoCrossStatsResponse = {
    annualRewardAmount: "0",
    chefAllocPoint: 0,
    lockChefAllocPoint: 0,
    emissionAlreadyMinted: "0",
  }
  if (!REACT_APP_CROSS_API_BASE_URL) {
    dispatch(updateAvtoCrossTokenStats(result))
    return
  }
  await fetch(`${REACT_APP_CROSS_API_BASE_URL}/stats`)
    .then((r) => r.json())
    .then((data: AvtoCrossStatsResponse) => {
      result = data
    })
    .catch((error) => {
      console.error('fetchAvtoCrossStats error', error)
    })
  dispatch(updateAvtoCrossTokenStats(result))
}
