import { useActiveWeb3React as UseActiveWeb3React } from "../hooks"
import { NETWORK_EXPLORER_URL } from "../constants/networks"

export function getEtherscanLink(
  data: string,
  type: "tx" | "token" | "address" | "block",
): string {
  const { chainId } = UseActiveWeb3React()
  const networExplorerUrl = chainId
    ? NETWORK_EXPLORER_URL[chainId]
    : "etherscan.io"

  return `https://${networExplorerUrl || "etherscan.io"}/${type}/${data}`
}
