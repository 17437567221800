import { TRANSACTION_TYPES } from "../constants"
import { ChainId } from "../constants/pools/types/ChainId"
import { notifyCustomError, notifyHandler } from "../utils/notifyHandler"
import { useCallback, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useMiniChefContract } from "./useContract"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { Zero } from "@ethersproject/constants"
import checkAndApproveTokenForTrade from "../utils/checkAndApproveTokenForTrade"
import { updateLastTransactionTimes } from "../state/application"
import { useActiveWeb3React } from "."
import { LpTokenUnguarded } from "../../types/ethers-contracts"

export function useRewardsHelpers(
  rewardPids: Record<ChainId, number | null> | null,
  lpTokenContract: LpTokenUnguarded | null
): { 
    amountStakedUnlocked: BigNumber;
    isPoolIncentivized: boolean;
    approveAndStake: (amount: BigNumber) => Promise<void>;
    lpAmountStakedHandleError: boolean;
    setLpAmountStakedHandleError: (value: (((prevState: boolean) => boolean) | boolean)) => void;
    setAmountStakedHandle: (value: (((prevState: string) => string) | string)) => void;
    amountStaked: BigNumber;
    unstake: (amount: BigNumber) => Promise<void>;
    amountStakedLocked: BigNumber;
    amountStakedHandle: string;
    rewardAmount: BigNumber
  } {
  const { account, chainId } = useActiveWeb3React()
  const dispatch = useDispatch()
  const infiniteApproval = useSelector((state: AppState) => state.user.infiniteApproval)
  const rewardsContract = useMiniChefContract()
  const poolPid = chainId && rewardPids ? rewardPids[chainId] : null
  const [amountStaked, setAmountStaked] = useState(Zero)
  const [amountStakedHandle, setAmountStakedHandle] = useState('0')
  const [lpAmountStakedHandleError, setLpAmountStakedHandleError] = useState(false)
  const lastTransactionTimes = useSelector(
    (state: AppState) => state.application.lastTransactionTimes,
    shallowEqual
  )
  const lastStakeOrClaim =
    lastTransactionTimes[TRANSACTION_TYPES.STAKE_OR_CLAIM]

  const approveAndStake = useCallback(
    async (amount: BigNumber) => {
      if (!lpTokenContract || !rewardsContract || !account || poolPid === null)
        return
      try {
        await checkAndApproveTokenForTrade(
          lpTokenContract,
          rewardsContract.address,
          account,
          amount,
          infiniteApproval,
          BigNumber.from(1),
        )
        const txn = await rewardsContract.deposit(poolPid, amount, account)
        notifyHandler(txn?.hash, "deposit")
        await txn.wait()
        dispatch(
          updateLastTransactionTimes({
            [TRANSACTION_TYPES.STAKE_OR_CLAIM]: Date.now(),
          }),
        )
      } catch (e) {
        console.error(e)
        notifyCustomError({ ...(e as Error), message: "Unable to Stake" })
      }
    },
    [
      lpTokenContract,
      rewardsContract,
      account,
      infiniteApproval,
      poolPid,
      dispatch,
    ],
  )

  const unstake = useCallback(
    async (amount: BigNumber) => {
      if (!lpTokenContract || !rewardsContract || !account || poolPid === null)
        return
      try {
        const txn = await rewardsContract.withdraw(poolPid, amount, account)
        notifyHandler(txn?.hash, "withdraw")
        await txn.wait()
        dispatch(
          updateLastTransactionTimes({
            [TRANSACTION_TYPES.STAKE_OR_CLAIM]: Date.now(),
          }),
        )
      } catch (e) {
        console.error(e)
        notifyCustomError({ ...(e as Error), message: "Unable to Unstake" })
      }
    },
    [lpTokenContract, rewardsContract, account, poolPid, dispatch],
  )

  useEffect(() => {
    async function fetchAmount() {
      if (!rewardsContract || !account || poolPid === null) {
        setAmountStaked(Zero)
        return
      }
      const userInfo = await rewardsContract
        .userInfo(poolPid, account)
        .catch(console.error)
      setAmountStaked(userInfo ? userInfo.amount : Zero)

      // reset to default input-value
      setAmountStakedHandle("0")

      // auto-setting of MAX value
      // setAmountStakedHandle(userInfo ? new BigNumberJs(userInfo.amount.toString()).dividedBy(new BigNumberJs(10 ** 18).toString()).toString() : '0')
    }
    void fetchAmount()
  }, [account, poolPid, rewardsContract, lastStakeOrClaim])

  return {
    approveAndStake,
    unstake,
    amountStaked,
    amountStakedHandle,
    amountStakedLocked: Zero,
    amountStakedUnlocked: Zero,
    setAmountStakedHandle,
    lpAmountStakedHandleError,
    setLpAmountStakedHandleError,
    isPoolIncentivized: poolPid !== null,
    rewardAmount: Zero
  }
}
