import { ChainId } from "./pools/types/ChainId"

export const ethereumWallets = "https://ethereum.org/en/wallets/"
export const polygonWallets = "https://awesomepolygon.com/wallets/"
export const cronosWallets = "https://cronos.crypto.org/wallets"

export const NETWORK_LABEL: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: "Ethereum",
  [ChainId.ARBITRUM]: "Arbitrum",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.HARDHAT]: "Hardhat 👷🏼‍♂️",
  [ChainId.CRONOS]: "Crypto.com", // Cronos
  [ChainId.POLYGON]: "Polygon",
}

export const NETWORK_EXPLORER_URL: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: "etherscan.io",
  [ChainId.ARBITRUM]: "etherscan.io",
  [ChainId.ROPSTEN]: "etherscan.io",
  [ChainId.HARDHAT]: "etherscan.io",
  [ChainId.CRONOS]: "cronos.crypto.org/explorer",
  [ChainId.POLYGON]: "polygonscan.com",
}

export const NETWORK_SUPPORTED_WALLETS: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: ethereumWallets,
  [ChainId.ARBITRUM]: ethereumWallets,
  [ChainId.ROPSTEN]: ethereumWallets,
  [ChainId.HARDHAT]: ethereumWallets,
  [ChainId.CRONOS]: cronosWallets,
  [ChainId.POLYGON]: polygonWallets,
}
