import { buildPids } from "../pools/buildPids";
import { TUSD, TUSD_SWAP_TOKEN } from "../pools/TUSDMetaswapPool";
import { STABLECOIN_SWAP_V3_TOKEN } from "../pools/StableCoinCrossPool";
import { ChainId } from "../pools/types/ChainId";
import { Farm } from "./FarmsMap";
import tusdLogo from "../../assets/icons/tusd.png"

export const XTUSD_FARM_TOKENS = [TUSD, STABLECOIN_SWAP_V3_TOKEN]

export const xTusdStaking: Farm = {
  name: "TUSD Metapool Staking",
  chainId: ChainId.CRONOS, 
  lpToken: TUSD_SWAP_TOKEN, 
  rewardPids: buildPids({
    [ChainId.CRONOS]: 5,
  }),
  poolTokens: XTUSD_FARM_TOKENS,
  partnership: {
    logo: tusdLogo,
    url: "https://trueusd.com/",
    i18n: "collaboration"
  }
}