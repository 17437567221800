import "./PoolOverview.scss"

import { TOKENS_MAP, } from "../constants/pools/TokensMap"
import { POOLS_MAP, isMetaPool } from "../constants/pools/PoolsMap"
import { PoolTypes } from "../constants/pools/types/PoolTypes"
import { PoolDataType, UserShareType } from "../hooks/usePoolsData"
import React, { ReactElement } from "react"
import {
  formatBNToPercentString,
  formatBNToShortString,
  formatBNToString,
} from "../utils"

import Button from "./Button"
import { Link } from "react-router-dom"
import Tag from "./Tag"
import ToolTip from "./ToolTip"
import { Zero } from "@ethersproject/constants"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import iInformation from "../assets/img/i-information.svg"
import { getTokenIcon } from "./common/tokenUtils"

interface Props {
  poolRoute: string
  poolData: PoolDataType
  userShareData: UserShareType | null
  onClickMigrate?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function PoolOverview({
  poolData,
  poolRoute,
  userShareData,
  onClickMigrate,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  if (poolData.name === "") {
    return null;
  }
  // const { type: poolType, isOutdated, partnership } = POOLS_MAP[poolData.name]
  const { type: poolType, isOutdated } = POOLS_MAP[poolData.name]
  const formattedDecimals = poolType === PoolTypes.USD ? 2 : 4
  const shouldMigrate = !!onClickMigrate
  const formattedData = {
    name: poolData.name,
    reserve: poolData.reserve
      ? formatBNToShortString(poolData.reserve, 18)
      : "--",
    apy: poolData.apy
      ? `${formatBNToPercentString(poolData.apy, 18, 2)}`
      : "--",
    volume: poolData.volume
      ? `$${formatBNToShortString(poolData.volume, 18)}`
      : "--",
    userBalanceUSD: formatBNToShortString(
      userShareData?.usdBalance || Zero,
      18,
    ),
    tokens: poolData.tokens.map((coin) => {
      const token = TOKENS_MAP[coin.symbol]
      return {
        symbol: token.symbol,
        name: token.name,
        icon: getTokenIcon(token),
        value: formatBNToString(coin.value, token.decimals, formattedDecimals),
      }
    }),
  }
  const hasShare = !!userShareData?.usdBalance.gt("0")
  const isMetapool = isMetaPool(formattedData.name)

  return (
    <div
      className={classNames(
        "poolOverview",
        {
          tagged: isOutdated || shouldMigrate || poolData.isPaused,
        },
        {
          outdated: isOutdated || shouldMigrate,
        },
      )}
    >
      <div className="left">
        <div className="titleAndTag">
          {isMetapool ? (
            <ToolTip content={t("metapool")}>
              <h4 className="overview-title">
                {formattedData.name}
                <img src={iInformation} alt="information" />
              </h4>
            </ToolTip>
          ) : (
            <h4 className="overview-title">{formattedData.name}</h4>
          )}
          {(shouldMigrate || isOutdated) && (
            <Tag position="absoluteTop0Left0" kind="warning" size="small">
              OUTDATED
            </Tag>
          )}
          {poolData.isPaused && (
            <Tag position="absoluteTop0Left0" kind="error" size="small">
              PAUSED
            </Tag>
          )}
        </div>
        {hasShare && (
          <div className="balance">
            <span>{t("balance")}: </span>
            <span className="value">{`$${formattedData.userBalanceUSD}`}</span>
          </div>
        )}
        <div className="tokens">
          {formattedData.tokens.map(({ symbol, icon }) => (
            <div className="token" key={symbol}>
              <img alt="icon" className="tokenIcon" src={icon} />
              <span>{symbol}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="right">
        <div className="poolInfo">
          {formattedData.apy && (
            <div className="margin">
              <span className="label">{`${t("apy")}`}</span>
              <span className="value percents">{formattedData.apy}</span>
            </div>
          )}

          <div className="margin">
            <span className="label">TVL</span>
            <span className="value">{`$${formattedData.reserve}`}</span>
          </div>
          {formattedData.volume && (
            <div className="margin">
              <span className="label">{`${t("24HrVolume")}`}</span>
              <span className="value">{formattedData.volume}</span>
            </div>
          )}
        </div>
        <div className="buttons">
          <Link to={`${poolRoute}/withdraw`}>
            <Button kind="secondary" size="extra-large">{t("withdraw")}</Button>
          </Link>
          {shouldMigrate ? (
            <Button
              kind="temporary"
              size="extra-large"
              onClick={onClickMigrate}
              disabled={!hasShare}
            >
              {t("migrate")}
            </Button>
          ) : (
            <Link to={`${poolRoute}/deposit`}>
              <Button
                kind="secondary"
                size="extra-large"
                disabled={poolData?.isPaused || isOutdated}
              >
                {t("deposit")}
              </Button>
            </Link>
          )}
        </div>
      </div>
      {poolData.partnership && (
        <div className="partnershipWith">
          <a href={poolData.partnership.url} target="_blank" rel="noreferrer">
            <span className="slogan">{t(poolData.partnership.i18n)}</span>
            <img src={poolData.partnership.logo} alt="partnership" />
          </a>
        </div>
      )}
    </div>
  )
}
