import "./TokenInput.scss"

import { LPTOKEN_TO_POOL_MAP, TOKENS_MAP } from "../constants/pools/TokensMap"
import React, { ReactElement } from "react"
import { calculatePrice, commify } from "../utils"

import { AppState } from "../state/index"
import { BigNumber } from "ethers"
import { Zero } from "@ethersproject/constants"
import classnames from "classnames"
import { formatBNToString } from "../utils"
import { emptyPoolData, usePoolsData } from "../hooks/usePoolsData"
import { useMemoizePoolNameAsArray } from "../hooks/useMemoizePoolNameAsArray"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

interface Props {
  symbol: string
  icon: string
  max?: string
  inputValue: string
  onChange: (value: string) => void
  disabled?: boolean
  isError?: boolean | null
}

function TokenInput({
  symbol,
  icon,
  max,
  inputValue,
  onChange,
  disabled,
  isError,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { name, crossId } = TOKENS_MAP[symbol]

  let tokenUSDValue: number | BigNumber | undefined
  const poolName = LPTOKEN_TO_POOL_MAP[symbol]
  const poolNameInArray = useMemoizePoolNameAsArray(poolName)
  const poolsDataAndUserSharedData = usePoolsData(poolNameInArray)[poolName]
  const [poolData] = poolsDataAndUserSharedData ?? [emptyPoolData, null]
  const tokenPricesUSD = useSelector((state: AppState) => state.application.tokenPricesUSD, shallowEqual)

  if (poolData.lpTokenPriceUSD != Zero) {
    tokenUSDValue = parseFloat(
      formatBNToString(poolData.lpTokenPriceUSD, 18, 2),
    )
  } else {
    tokenUSDValue = tokenPricesUSD?.[crossId]
  }

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const { decimals } = TOKENS_MAP[symbol]
    const parsedValue = parseFloat("0" + e.target.value)
    const periodIndex = e.target.value.indexOf(".")
    const isValidInput = e.target.value === "" || !isNaN(parsedValue)
    const isValidPrecision =
      periodIndex === -1 || e.target.value.length - 1 - periodIndex <= decimals
    if (isValidInput && isValidPrecision) {
      // don't allow input longer than the token allows
      onChange(e.target.value)
    }
  }

  return (
    <div>
      {max != null && (
        <div className={classNames("balanceContainer", "truncate")}>
          <span>{t("balance")}:</span>
          &nbsp;
          <a onClick={() => onChange(String(max))}>{max}</a>
        </div>
      )}

      <div
        className={classnames(
          "tokenInputContainer",
          {
            ["disabled"]: disabled,
          },
          {
            ["error"]: isError,
          },
        )}
        id="tokenInput"
      >
        <img alt="icon" src={icon} />
        <div className="tokenSymbolAndName">
          <div className="boldText">{symbol}</div>
          <div className="smallText">{name}</div>
        </div>
        <div className="inputGroup">
          <input
            autoComplete="off"
            autoCorrect="off"
            type="text"
            placeholder="0.0"
            spellCheck="false"
            disabled={disabled ? true : false}
            value={inputValue}
            onChange={onChangeInput}
            onFocus={(e: React.ChangeEvent<HTMLInputElement>): void =>
              e.target.select()
            }
          />
          <div className="smallText">
            ≈$
            {commify(
              formatBNToString(
                calculatePrice(inputValue, tokenUSDValue),
                18,
                2,
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenInput
