import { BLOCK_TIME } from "../../constants"
import { TOKENS_MAP } from "../../constants/pools/TokensMap"

import { BigNumber } from "@ethersproject/bignumber"
import { Contract } from "ethcall"
import ERC20_ABI from "../../constants/abis/erc20.json"
import { Erc20 } from "../../../types/ethers-contracts/Erc20"
import { MulticallContract } from "../../types/ethcall"
import { getMulticallProvider } from "../../utils"
import { useActiveWeb3React } from "../../hooks"
import usePoller from "../../hooks/usePoller"
import { useState } from "react"
import { isNetworkSupported } from "../../utils/isNetworkSupported"
import { loadingIndicator } from "../../utils/loadingIndicator"
import { getTokenAddresses } from "../../components/common/tokenUtils"

export function usePoolTokenBalances(usingAltWxUSDAddress = false): { [token: string]: BigNumber } | null {
  const { account, chainId, library } = useActiveWeb3React()
  const [balances, setBalances] = useState<{ [token: string]: BigNumber }>({})

  usePoller((): void => {
    async function pollBalances(): Promise<void> {
      if (!library || !chainId || !account || !isNetworkSupported(chainId))
        return

      // ADD LOADING INDICATOR
      loadingIndicator(true, true)

      const ethcallProvider = await getMulticallProvider(library, chainId)
      const tokens = Object.values(TOKENS_MAP).filter(
        ({ addresses }) => addresses[chainId],
      )
      const balanceCalls = tokens
        .map((t) => {
          const tokenAddresses = getTokenAddresses(t, usingAltWxUSDAddress) // alter WxUSD addresses if there is a need to https://arkuda.myjetbrains.com/youtrack/issue/AMATIC-485
          return new Contract(
            tokenAddresses[chainId],
            ERC20_ABI,
          ) as MulticallContract<Erc20>
        })
        .map((c) => c.balanceOf(account))
      const balances = await ethcallProvider.all(balanceCalls, {})

      // REMOVE LOADING INDICATOR
      loadingIndicator(false)

      const ethBalance = await library.getBalance(account)
      setBalances(
        tokens.reduce(
          (acc, t, i) => ({
            ...acc,
            [t.symbol]: balances[i],
          }),
          { ETH: ethBalance },
        ),
      )
    }
    if (account) {
      void pollBalances()
    } else {
      setBalances({})
    }
  }, BLOCK_TIME)

  return balances
}
