// export {}
import { buildPids } from "./buildPids"
import { ChainId } from "./types/ChainId"
import { TUSD_METASWAP_POOL_NAME, STABLECOIN_CROSS_POOL_NAME } from "./types/PoolName"
import { PoolTypes } from "./types/PoolTypes"
import tusdLogo from "../../assets/icons/tusd.png"
import xTusdLPTokenLogo from "../../assets/icons/xtusd-logo-ellipse-transparent-260x260.svg"
import { buildAddresses } from "./buildAddresses"
import { Token } from "./types/Token"
import { ENV } from "./ENV"
import { STABLECOIN_POOL_TOKENS, STABLECOIN_SWAP_V3_TOKEN } from "./StableCoinCrossPool"
import { Pool } from "./types/Pool"


export const TUSD_META_SWAP_DEPOSIT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x6435Ac5E30ee74e0C65A070C4293bc632374EA76",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x6435Ac5E30ee74e0C65A070C4293bc632374EA76",
        })
    }
  }
}

export const TUSD_META_SWAP_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0xA17736f317DA2D3d5853b2794f2D0c114C43688A",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0xA17736f317DA2D3d5853b2794f2D0c114C43688A",
        })
    }
  }
}

export const TUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = (): Record<ChainId, string> => {
  {
    switch (process.env.REACT_APP_ENV) {
      case ENV.DEVELOPMENT:
      case ENV.STAGING:
      default:
        return buildAddresses({
          [ChainId.CRONOS]: "0x7eace452eD5c04E91C676BBf11eC92AA9602f728",
        })
      case ENV.PRODUCTION:
        return buildAddresses({
          [ChainId.CRONOS]: "0x7eace452eD5c04E91C676BBf11eC92AA9602f728",
        })
    }
  }
}

export const TUSD_SWAP_TOKEN = new Token(
  TUSD_SWAP_TOKEN_CONTRACT_ADDRESSES(),
  18,
  "xTUSD",
  "cross-true-usd",
  "Cross TUSD-DAI/USDC/USDT",
  xTusdLPTokenLogo,
  false,
  true,
  false,
  false
)

const TUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.CRONOS]: "0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e",
})

export const TUSD = new Token(
  TUSD_CONTRACT_ADDRESSES,
  18,
  "TUSD",
  "true-usd",
  "TrueUSD",
  tusdLogo,
  true,
)

export const TUSD_POOL_TOKENS = [TUSD, ...STABLECOIN_POOL_TOKENS]
export const TUSD_UNDERLYING_POOL_TOKENS = [TUSD, STABLECOIN_SWAP_V3_TOKEN]

export const TUSD_METASWAP_POOL: Pool = {
    name: TUSD_METASWAP_POOL_NAME,
    addresses: TUSD_META_SWAP_DEPOSIT_ADDRESSES(),
    lpToken: TUSD_SWAP_TOKEN,
    poolTokens: TUSD_POOL_TOKENS,
    isSynthetic: true,
    type: PoolTypes.USD,
    metaSwapAddresses: TUSD_META_SWAP_ADDRESSES(),
    underlyingPoolTokens: TUSD_UNDERLYING_POOL_TOKENS,
    underlyingPool: STABLECOIN_CROSS_POOL_NAME,
    route: "tusd",
    rewardPids: buildPids({
        [ChainId.CRONOS]: 5,
    }),
    partnership: {
      logo: tusdLogo,
      url: "https://trueusd.com/",
      i18n: "collaboration"
    },
  }
