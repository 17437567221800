import { AbiItem } from 'web3-utils'
import { MultiCall } from "eth-multicall"
import { useMemo } from "react"
import { Contract as Web3EthContract } from 'web3-eth-contract'
import Web3 from 'web3'

import { useActiveWeb3React } from "."
import { NETWORK_RPC_URL_FULL } from "../connectors"
import { MINICHEF_CONTRACT_ADDRESSES, LOCKED_STAKE_CONTRACT_ADDRESSES, isLegacySwapABIPool } from "../constants"
import { Farm } from "../constants/farms/FarmsMap"
import { POOLS_MAP, isMetaPool } from "../constants/pools/PoolsMap"
import { PoolName, WxUSD_METASWAP_POOL_NAME } from "../constants/pools/types/PoolName"
import { ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES } from "../constants/pools/WxUSDMetaswapPool"
import ERC20_ABI from "../constants/abis/erc20.json"
import LOCKED_STAKE_CONTRACT_ABI from "../constants/abis/lockedStake.json"
import LPTOKEN_UNGUARDED_ABI from "../constants/abis/lpTokenUnguarded.json"
import META_SWAP_DEPOSIT_ABI from "../constants/abis/metaSwapDeposit.json"
import MINICHEF_CONTRACT_ABI from "../constants/abis/miniChef.json"
import SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI from "../constants/abis/swapFlashLoanNoWithdrawFee.json"
import SWAP_FLASH_LOAN_ABI from "../constants/abis/swapFlashLoan.json"

export function useMulticall(): MultiCall | null {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!chainId) {
      return null
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return null
    }
    const web3 = new Web3(provider)
    const multicall = new MultiCall(web3, '0x1f3a7C91710C5d1Cc8ECBAC88c7F73A23ff90599')
    // https://cronoscan.com/address/0x1f3a7C91710C5d1Cc8ECBAC88c7F73A23ff90599#code

    return multicall

  }, [chainId])
}

export function useRewardContract(): Web3EthContract | undefined {
  const { chainId } = useActiveWeb3React()
  const rewardsContractAddress = chainId ? MINICHEF_CONTRACT_ADDRESSES()[chainId] : undefined

  return useMemo(() => {
    if (!chainId) {
      return
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return
    }
    const web3 = new Web3(provider)
    const contract = new web3.eth.Contract(MINICHEF_CONTRACT_ABI as AbiItem[], rewardsContractAddress)
    return contract
  }, [chainId, rewardsContractAddress])
}

export function useLockedStakeRewardContract(): Web3EthContract | undefined {
  const { chainId } = useActiveWeb3React()
  const lockedStakeContractAddress = chainId ? LOCKED_STAKE_CONTRACT_ADDRESSES()[chainId] : undefined

  return useMemo(() => {
    if (!chainId) {
      return
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return
    }
    const web3 = new Web3(provider)
    const contract = new web3.eth.Contract(LOCKED_STAKE_CONTRACT_ABI as AbiItem[], lockedStakeContractAddress)
    return contract
  }, [chainId, lockedStakeContractAddress])
}

export function useRewardBalancesContracts(farmsList: Farm[]): Web3EthContract[] | null {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!chainId) {
      return null
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return null
    }
    const web3 = new Web3(provider)
    const rewardBalancesContracts: Web3EthContract[] = []
    for (let i = 0; i < farmsList.length; i++) {
      const farm = farmsList[i]
      const ERC20Contract = new web3.eth.Contract(ERC20_ABI as AbiItem[], farm.lpToken.addresses[chainId])
      rewardBalancesContracts.push(ERC20Contract)
    }
    return rewardBalancesContracts
  }, [chainId, farmsList])
}

export function useWeb3EthMetaSwapContract(poolName: PoolName): Web3EthContract | null {
  const { chainId } = useActiveWeb3React()
  const POOL = POOLS_MAP[poolName]

  return useMemo(() => {
    if (!chainId || !POOL) {
      return null
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return null
    }
    const web3 = new Web3(provider)
    let metaSwapAddress: string | null = null
    if (POOL.metaSwapAddresses && POOL.metaSwapAddresses[chainId]) {
      metaSwapAddress = POOL.metaSwapAddresses[chainId]
    }
    const isMetaSwap = metaSwapAddress ? true : false
    const metaSwapContract = isMetaSwap && metaSwapAddress ? new web3.eth.Contract(SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI as AbiItem[], metaSwapAddress) : null
    return metaSwapContract
  }, [POOL, chainId])
}

export function useWeb3EthSwapContract(
  poolName?: PoolName,
  usingAltWxUSDMetaswapDepositAddress = false
): Web3EthContract
  | null {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!poolName) {
      return null
    }
    const pool = POOLS_MAP[poolName]

    if (!chainId || !pool) {
      return null
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {

      return null
    }
    const web3 = new Web3(provider)

    try {
      let poolAddress = pool.addresses[chainId]
      if (poolName === WxUSD_METASWAP_POOL_NAME && usingAltWxUSDMetaswapDepositAddress) { //Alt addresses for WxUSD pool
        poolAddress = ALT_WxUSD_META_SWAP_DEPOSIT_ADDRESSES()[chainId]
      }

      if (!poolAddress) {
        return null
      }

      let contract: Web3EthContract | null = null
      if (isLegacySwapABIPool()) {
        contract = new web3.eth.Contract(SWAP_FLASH_LOAN_ABI as AbiItem[], poolAddress)
      } else if (isMetaPool(poolName)) {
        contract = new web3.eth.Contract(META_SWAP_DEPOSIT_ABI as AbiItem[], poolAddress)
      } else if (pool) {
        contract = new web3.eth.Contract(SWAP_FLASH_LOAN_NO_WITHDRAW_FEE_ABI as AbiItem[], poolAddress)
      }
      return contract

    } catch (error) {
      console.error("Failed to get contract", error)
      return null
    }
  }, [chainId, poolName, usingAltWxUSDMetaswapDepositAddress])
}

export function useWeb3EthLPTokenContract(
  lpTokenAddress: string 
): Web3EthContract | null {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    if (!chainId || !lpTokenAddress) {
      return null
    }
    const provider = NETWORK_RPC_URL_FULL[chainId]
    if (!provider) {
      return null
    }
    const web3 = new Web3(provider)
    const contract = new web3.eth.Contract(LPTOKEN_UNGUARDED_ABI as AbiItem[], lpTokenAddress)
    return contract
  },[chainId, lpTokenAddress])
}
